import {
  ActionButtonComponent,
  CustomComponentData,
  KustomPage,
  KustomPageComponent,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  MediaComponent,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Grid, GridItem, Heading } from '@chakra-ui/react';

import ActionButton from '@/components/atomics/ActionButton';
import AnimatePresence from '../atomics/AnimatePresence';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import ContainerSection from '../ContainerSection';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import { useKustomSelector } from '@/lib/kustomcms-sdk/lib/admin/hooks/useKustomSelector';
import useResponsiveMediasDevice, {
  ResponsiveMediaDeviceKeys,
} from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import { useContext } from 'react';
import MediasViewerContext from '@/contexts/MediasViewerContext';
import Section from '../Section';
import { SvgShape } from '../SvgShape';
import { ParallaxEl } from '../ParallaxEl';
import Slideshow from '../atomics/Slideshow';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';

const Section7 = ({
  component,
  page,
}: KustomPageComponentViewProps<TextCarouselComponentData>) => {
  const [text1Index, text2Index] = component.data.atomicComponents
    .map((cmp, index) => (cmp.type === 'TEXT' ? index : undefined))
    .filter((i) => i !== undefined) as number[];

  const buttonIndex = component.data.atomicComponents.findIndex(
    (cmp) => cmp.type === 'ACTION_BUTTON',
  );

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${text1Index}.text`,
  );

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${text2Index}.text`,
  );

  const buttonBind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${buttonIndex}`,
  );

  const mediasIndex = component.data.atomicComponents
    // .splice(3)
    .map((cmp, index) =>
      cmp.type === 'MEDIA'
        ? { index, id: cmp.id, media: (cmp as MediaComponent).media }
        : undefined,
    )
    .filter((i) => i) as {
    index: number;
    id: string;
    media: KustomResponsiveMedia;
  }[];

  const mediaBind = useKustomData<KustomResponsiveMedia>(
    page,
    component,
    `data.atomicComponents.${mediasIndex?.[0]?.index}.media`,
  );

  const currentDevice = useResponsiveMediasDevice();

  const mediasViewer = useContext(MediasViewerContext);

  const openMediaViewer = (index: number) => {
    if (mediasIndex.length) {
      mediasViewer.setMedias(mediasIndex.map((m) => m.media));
      mediasViewer.setIndex(index);
    }
  };

  return (
    <Section className={'section-7'}>
      <ContainerSection>
        <Box
          width={['22%', null, null, '16%']}
          right="0"
          bottom={'0'}
          position="absolute"
          zIndex="2"
          pointerEvents={'none'}
        >
          <ParallaxEl speed={12}>
            <Box
              transform={[
                'translate(20%, -180%) rotate(130deg)',
                null,
                null,
                'translate(20%, -150%) rotate(130deg)',
              ]}
            >
              <SvgShape shape={2} />
            </Box>
          </ParallaxEl>
        </Box>
        <Box
          display={['block', null, null, 'flex']}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            position="relative"
            zIndex={2}
            width={['100%', null, null, '37%']}
            // pt={['0', null, null, '12', '16']}
          >
            <AnimatePresence opacity>
              <Heading as="h2">
                <RichText {...titleBind} color="brand.500" />
              </Heading>
              <RichText {...textBind} />
              <Box mt="clamp(2rem, 2.5vw, 3rem)">
                {buttonBind && (
                  <ActionButton variant="button" {...buttonBind} />
                )}
              </Box>
            </AnimatePresence>
          </Box>

          {mediasIndex.length === 3 ? (
            <Box
              width={['100%', null, null, '57%']}
              mt={['3rem', null, null, '0']}
              aspectRatio={8 / 9}
            >
              <ParallaxEl speed={5}>
                <Grid
                  flex={[null, null, null, 3]}
                  templateRows="repeat(10, 1fr)"
                  templateColumns="repeat(2, 1fr)"
                  gap={[3, null, null, 4]}
                  height={'100%'}
                >
                  {mediasIndex.map(({ id, index: mediaIndex }, index) => (
                    <Section5GridItem
                      page={page}
                      component={component}
                      index={index}
                      mediaIndex={mediaIndex}
                      key={id}
                      currentDevice={currentDevice}
                      onClick={() => openMediaViewer(index)}
                    />
                  ))}
                </Grid>
              </ParallaxEl>
            </Box>
          ) : (
            <Box
              width={['100%', null, null, '57%']}
              mt={['3rem', null, null, '0']}
              aspectRatio={4 / 3}
            >
              <ParallaxEl speed={5}>
                {mediaBind && (
                  <ResponsiveMedias
                    {...mediaBind}
                    disableCSSAdminWysiwyg
                    currentDevice={currentDevice}
                    style={{
                      objectFit: 'cover',
                      borderRadius: 4,
                      background: 'var(--chakra-colors-brand-200)',
                    }}
                    fill
                    width={undefined}
                    height={undefined}
                    sizes={chakraResponsiveToImageSizes([
                      '95vw',
                      null,
                      null,
                      '45vw',
                      null,
                      '920px',
                    ])}
                    skeletonColor={{
                      base: '#efd9c9',
                      highlight: '#F4E7DC',
                    }}
                  />
                )}
              </ParallaxEl>
            </Box>
          )}
        </Box>
      </ContainerSection>
    </Section>
  );
};

export default Section7;

interface Section5GridItemProps {
  page: KustomPage;
  component: KustomPageComponent<CustomComponentData>;
  index: number;
  mediaIndex: number;
  currentDevice: ResponsiveMediaDeviceKeys;
  onClick?: () => void;
}

const Section5GridItem: React.FC<Section5GridItemProps> = ({
  page,
  component,
  mediaIndex,
  index,
  currentDevice,
  onClick,
}) => {
  const mediaBind = useKustomData<KustomResponsiveMedia>(
    page,
    component,
    `data.atomicComponents.${mediaIndex}.media`,
  );

  return (
    <GridItem
      rowSpan={[7, 3, 3][index]}
      colSpan={[2, 1, 1][index]}
      position="relative"
    >
      <Box onClick={onClick} h="100%">
        <AnimatePresence
          opacity
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ResponsiveMedias
            {...mediaBind}
            disableCSSAdminWysiwyg
            currentDevice={currentDevice}
            style={{
              objectFit: 'cover',
              borderRadius: 4,
              background: 'var(--chakra-colors-brand-200)',
            }}
            fill
            width={undefined}
            height={undefined}
            sizes={
              index === 0
                ? '(max-width: 959px) 95vw, (max-width: 1919px) 45vw, 920px'
                : '(max-width: 959px) 50vw, (max-width: 1919px) 25vw, 460px'
            }
            skeletonColor={{
              base: '#efd9c9',
              highlight: '#F4E7DC',
            }}
          />
        </AnimatePresence>
      </Box>
    </GridItem>
  );
};
