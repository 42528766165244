import { Box, Heading } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import ContainerSection from '../ContainerSection';
import Section from '../Section';
import { ParallaxEl } from '../ParallaxEl';
import { SvgShape } from '../SvgShape';
import AnimatePresence from '../atomics/AnimatePresence';

const RoomsDescription: React.FC<
  KustomPageComponentViewProps<TextComponentData>
> = ({ page, component }) => {
  // const title = component.data.text;
  const descriptionId = component.data.atomicComponents.findIndex(
    (cmp) => cmp.type === 'TEXT',
  );

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const descriptionBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${descriptionId}.text`,
  );

  const bpIndex = useBreakpointIndex();

  return (
    <Section className={'rooms-description'}>
      <ContainerSection>
        <Box
          width={['75%', null, null, '50%']}
          right="0"
          bottom="0"
          position="absolute"
          pointerEvents={'none'}
        >
          <ParallaxEl speed={-6}>
            <Box
              transform={[
                'translate(50%, 0)',
                null,
                null,
                'translate(30%, -10%)',
              ]}
            >
              <SvgShape shape={3} />
            </Box>
          </ParallaxEl>
        </Box>
        <Box
          width={['30%', null, null, '35%']}
          left="0"
          top="0"
          position="absolute"
          pointerEvents={'none'}
        >
          <ParallaxEl speed={-3}>
            <Box transform={'translate(-60%, 10%)'}>
              <SvgShape shape={4} />
            </Box>
          </ParallaxEl>
        </Box>
        <AnimatePresence opacity>
          <Heading
            as="h2"
            pl={['0', null, null, 'var(--grid-s-2)']}
            position="relative"
            zIndex={2}
          >
            <RichText {...titleBind} />
          </Heading>
        </AnimatePresence>
        <AnimatePresence opacity>
          <RichText
            {...descriptionBind}
            position="relative"
            zIndex={2}
            width={['100%', null, null, null, '50%']}
            ml={['0', null, null, 'var(--grid-s-2)']}
            mr={['0', null, null, 'var(--grid-s-2)', '0']}
          />
        </AnimatePresence>
      </ContainerSection>
    </Section>
  );
};

export default RoomsDescription;
