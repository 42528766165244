import {
  AccordionComponentData,
  CodeComponent,
  KustomPage,
  KustomPageComponent,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import IgPosts from './components/IgPosts';
import TextMediasSection, {
  TextMediasSectionProps,
} from './components/TextMediasSection';

// Home
import Hero from './components/home/Hero';
import Section1 from './components/home/Section1';
import Section2 from './components/home/Section2';
import Section3 from './components/home/Section3';
import Section4 from './components/home/Section4';
import Section5 from './components/home/Section5';
import Section6 from './components/home/Section6';
import Section7 from './components/home/Section7';
import Section8 from './components/home/Section8';
import AccordionComponent from './components/AccordionComponent';
import ContactFormSection from './components/contact/ContactFormSection';
import ContactMap from './components/contact/ContactMap';
import DisableAutomaticComponentRendering from '@/lib/kustomcms-sdk/lib/components/DisableAutomaticComponentRendering';
import FAQ from './components/home/FAQ';
import FAQAccordionSection from './components/FAQ/FAQAccordionSection';
import GallerySection from './components/gallery/GallerySection';
import ItemsGrid from './components/ItemsGrid';
import PageHeader from './components/PageHeader';
import RoomContent from './components/rooms/RoomContent';
import RoomsBreakfast from './components/rooms/RoomsBreakfast';
import RoomsDescription from './components/rooms/RoomsDescription';
import RoomsGrid from './components/rooms/RoomsGrid';
import Title from './components/Title';
import RoomsService from './components/rooms/RoomsService';
import SiteMapSection from './components/sitemap/SiteMapSection';
import TextMediaBackground from './components/TextMediaBackground';
import { registerKustomComponents } from '@/lib/kustomcms-sdk/lib/components/KustomComponent';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import TextSection from './components/TextSection';
import Content404 from './components/404/Content404';
import RgpdFormSection from './components/rgpd/RgpdFormSection';
import MaisonArtist from './components/rooms/MaisonArtist';
import ModalText from './components/TextModal';
import RoomTextSection from './components/rooms/RoomTextSection';
import EventsFormSection from './components/events/EventsFormSection';
import OffersAndNewsPage from './components/offersAndNews/OffersAndNewsPage';

// automatic generics
registerKustomComponents([
  {
    key: 'textImage',
    Component: PageHeader,
  },
  {
    key: 'text',
    Component: Title,
  },
  {
    key: 'textCarousel',
    Component: TextMediasSection,
  },
  {
    key: 'testimony',
    Component: IgPosts,
    extraProps: {
      lightWidgetId: '2f0790d9c6da5b62b71bbbf1101a423c',
    },
  },
]);

// footer
registerKustomComponents(
  [
    {
      id: '16904561362299605',
      Component: DisableAutomaticComponentRendering,
    },
  ],
  { code: 'hds5' },
);

// menu
registerKustomComponents(
  [
    { id: '1690313260958793', Component: DisableAutomaticComponentRendering },
    { id: '16903663926835705', Component: DisableAutomaticComponentRendering },
    { id: '16903703402474730', Component: DisableAutomaticComponentRendering },
  ],
  { code: 'kfpqg8' },
);

// home
registerKustomComponents(
  [
    {
      id: '16846967627952491',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16881297908833395',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '17102531070101115',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '17102526656067306',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16881300315284190',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '17103230706873767',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16848708487829065',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '1684873683606493',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '17103489577397080',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16849301404486423',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16849349373114904',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16849321631032688',
      Component: DisableAutomaticComponentRendering,
      extraProps: {
        lightWidgetId: '2f0790d9c6da5b62b71bbbf1101a423c',
      },
    },
    {
      id: '17102526656067306',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '17103230706873767',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16849333171951256',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16846967627952491',
      Component: Hero,
    },
    {
      id: '16881297908833395',
      Component: Section1,
    },
    {
      id: '17102531070101115',
      Component: Section2,
    },
    {
      id: '16881300315284190',
      Component: Section3,
    },
    {
      id: '16848708487829065',
      Component: Section4,
    },
    {
      id: '1684873683606493',
      Component: Section5,
    },
    {
      id: '17103489577397080',
      Component: Section6,
    },
    {
      id: '16849301404486423',
      Component: Section7,
    },
    {
      id: '16849321631032688',
      Component: IgPosts,
      extraProps: {
        lightWidgetId: '2f0790d9c6da5b62b71bbbf1101a423c',
      },
    },
    {
      id: '17104234808744365',
      Component: FAQ,
    },
    {
      id: '16849349373114904',
      Component: Section8,
    },
    {
      id: '16921823951555462',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16881300733583978',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16848773672091390',
      Component: DisableAutomaticComponentRendering,
    },
  ],
  { code: '4onpu' },
);

// La maison
registerKustomComponents(
  [
    {
      id: '17096566157547939',
      Component: PageHeader,
    },
    {
      key: 'textCarousel',
      Component: ({
        component,
        page,
      }: {
        component: KustomPageComponent<TextCarouselComponentData>;
        page: KustomPage;
      }) => {
        const codeCpm = component?.data?.atomicComponents.filter(
          (c) => c.type === 'CODE',
        ) as CodeComponent[];
        return codeCpm?.find((c) => c.code.includes('artist-')) ? (
          <MaisonArtist component={component} page={page} />
        ) : (
          <TextMediasSection component={component} page={page} />
        );
      },
    },
    {
      key: 'text',
      Component: ({
        component,
        page,
      }: {
        component: KustomPageComponent<TextCarouselComponentData>;
        page: KustomPage;
      }) => {
        const codeCpm = component?.data?.atomicComponents.filter(
          (c) => c.type === 'CODE',
        ) as CodeComponent[];
        const modalId = codeCpm
          ?.find((c) => c.code.includes('modal-text-'))
          ?.code.match(/modal-text-[^ ]+/)?.[0];
        if (modalId) {
          return (
            <ModalText
              page={page}
              component={component}
              id={modalId}
              containerProps={{
                maxW: '600px',
                maxH: '300px',
              }}
              shape={true}
            />
          );
        } else {
          return null;
        }
      },
    },
  ],
  { code: 'zqcl1w' },
);

// spa
registerKustomComponents(
  [
    {
      id: '1688728572994595',
      Component: PageHeader,
    },
    {
      id: '16887280364773045',
      Component: TextMediasSection,
    },
    {
      id: '16887284891502036',
      Component: TextMediasSection,
    },
    {
      id: '16887286259811288',
      Component: TextMediasSection,
    },
    {
      id: '16887287311877813',
      Component: TextMediasSection,
    },
    {
      id: '16887288700931745',
      Component: TextMediasSection,
    },
    {
      id: '16887290565805527',
      Component: TextMediaBackground,
    },
    {
      id: '16887291491413827',
      Component: TextMediasSection,
    },
    {
      id: '1688729333996603',
      Component: TextMediasSection,
      extraProps: {
        SideExtraElement: ({ page }) => {
          const cmp = usePageComponent<TextCarouselComponentData>(
            page,
            null,
            '16887309289554663',
          );
          return cmp && <ItemsGrid component={cmp} page={page} index={0} />;
        },
      } as Partial<TextMediasSectionProps>,
    },
    {
      id: '16887309289554663',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16887298806754130',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16887297636845916',
      Component: TextMediasSection,
    },
    {
      id: '16887299810396704',
      Component: TextMediasSection,
    },
  ],
  { code: 'lqwtun' },
);

// rooms
registerKustomComponents(
  [
    {
      id: '16870199399413217',
      Component: PageHeader,
    },
    {
      id: '16870200843135204',
      Component: RoomsDescription,
    },
    {
      id: '17122406601971594',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16872728296671485',
      Component: RoomsService,
    },
    {
      id: '16899421193335740',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16871134819639319',
      Component: Title,
    },
    {
      id: '1687025539835268',
      Component: RoomsGrid,
      extraProps: {
        grid: 1,
      },
    },
    {
      id: '168711348980887',
      Component: Title,
      extraProps: {
        containerProps: {
          pt: '0 !important',
        },
      },
    },
    {
      id: '16870255520869428',
      Component: RoomsGrid,
      extraProps: {
        grid: 2,
      },
    },
    {
      id: '16871049335625577',
      Component: RoomsBreakfast,
    },
  ],
  { code: '23a8ac' },
);

// room
registerKustomComponents(
  [
    {
      key: 'medias',
      Component: DisableAutomaticComponentRendering,
    },
    {
      key: 'roomSpecs',
      Component: RoomContent,
    },
    {
      key: 'roomBooking',
      Component: DisableAutomaticComponentRendering,
    },
    {
      key: 'roomStuff',
      Component: DisableAutomaticComponentRendering,
    },
    {
      key: 'textCarousel',
      Component: ({
        component,
        page,
      }: {
        component: KustomPageComponent<TextCarouselComponentData>;
        page: KustomPage;
      }) => {
        const codeCpm = component?.data?.atomicComponents.filter(
          (c) => c.type === 'CODE',
        ) as CodeComponent[];
        const introId = codeCpm?.find((c) => c.code.includes('intro-text-'));
        if (introId) {
          return <RoomTextSection page={page} component={component} />;
        } else {
          return (
            <TextMediasSection
              component={component}
              page={page}
              containerProps={{
                pt: '0 !important',
              }}
              containerTextProps={{
                pt: '0 !important',
              }}
            />
          );
        }
      },
    },
    {
      key: 'text',
      Component: ({
        component,
        page,
      }: {
        component: KustomPageComponent<TextCarouselComponentData>;
        page: KustomPage;
      }) => {
        const codeCpm = component?.data?.atomicComponents.filter(
          (c) => c.type === 'CODE',
        ) as CodeComponent[];
        const modalId = codeCpm
          ?.find((c) => c.code.includes('modal-text-'))
          ?.code.match(/modal-text-[^ ]+/)?.[0];
        if (modalId) {
          return (
            <ModalText
              page={page}
              component={component}
              id={modalId}
              containerProps={{
                maxW: '600px',
                maxH: '300px',
              }}
              shape={true}
            />
          );
        }
        const introId = codeCpm?.find((c) => c.code.includes('intro-text-'));
        if (introId) {
          return <RoomTextSection page={page} component={component} />;
        } else {
          return null;
        }
      },
    },
  ],
  { model: 'room' },
);

// Offers and news
registerKustomComponents(
  [
    {
      id: '1722354445784629',
      Component: PageHeader,
    },
    {
      key: 'text',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '17223546486251881',
      Component: OffersAndNewsPage,
    },
  ],
  { code: 'qq3en4' },
);

// FAQ
registerKustomComponents(
  [
    {
      id: '16901322762879036',
      Component: PageHeader,
    },
    {
      key: 'text',
      Component: Title,
    },
    {
      key: 'accordion',
      Component: FAQAccordionSection,
    },
  ],
  { code: '1z3m2h' },
);

// contact
registerKustomComponents(
  [
    {
      id: '16897792334268430',
      Component: PageHeader,
    },
    {
      id: '16897790288415528',
      Component: ContactMap,
    },
    {
      id: '16897877259346332',
      Component: TextMediasSection,
      extraProps: {
        CentralExtraElement: ({ page }) => {
          const cmp = usePageComponent<AccordionComponentData>(
            page,
            null,
            '16897862177952151',
          );
          return (
            cmp && <AccordionComponent page={page} component={cmp} index={0} />
          );
        },
      } as Partial<TextMediasSectionProps>,
    },
    {
      id: '16897862177952151',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '16897885498478578',
      Component: ContactFormSection,
    },
  ],
  { code: 'pdwuzi' },
);

// gallery
registerKustomComponents(
  [
    {
      id: '16900512524711816',
      Component: PageHeader,
    },
    {
      // first component of page for anchor
      id: '16900330061271038',
      Component: GallerySection,
    },
    {
      key: 'text',
      Component: DisableAutomaticComponentRendering,
    },
    {
      key: 'medias',
      Component: DisableAutomaticComponentRendering,
    },
  ],
  { code: 'dw973c' },
);

// CGU
registerKustomComponents(
  [
    {
      key: 'text',
      Component: TextSection,
    },
  ],
  { code: 'g53y76' },
);

// policy
registerKustomComponents(
  [
    {
      key: 'text',
      Component: TextSection,
    },
  ],
  { code: 'dkj4o8' },
);

// Formulaire RGPD
registerKustomComponents(
  [
    {
      id: '16908108891853288',
      Component: PageHeader,
    },
    {
      key: 'text',
      Component: TextSection,
    },
    {
      id: '17133496606889808',
      Component: RgpdFormSection,
    },
  ],
  { code: 'dfxwtq' },
);

// Formulaire Event
registerKustomComponents(
  [
    {
      id: '16908108891853288',
      Component: PageHeader,
    },
    {
      key: 'text',
      Component: TextSection,
    },
    {
      id: '16897885498478578',
      Component: EventsFormSection,
    },
  ],
  { code: 'dshfoh' },
);

// sitemap
registerKustomComponents(
  [
    {
      id: '16907964855005439',
      Component: PageHeader,
    },
    {
      id: '16907966803222191',
      Component: SiteMapSection,
      extraProps: {
        firstSection: true,
        filterPages: (page: KustomPage) =>
          page.model !== 'room' &&
          page.category !== 'seo' &&
          page.pageCode !== '3h549n' && // 404
          page.objectCode !== '3h549n', // 404
      },
    },
    {
      id: '16907966869093663',
      Component: SiteMapSection,
      extraProps: {
        filterPages: (page: KustomPage) => page.model === 'room',
      },
    },
    {
      id: '17132523941132371',
      Component: SiteMapSection,
      extraProps: {
        filterPages: (page: KustomPage) =>
          page.model !== 'room' && page.category === 'seo',
      },
    },
  ],
  { code: 'cjduk7' },
);

// 404
registerKustomComponents(
  [
    {
      id: '17126720021309138',
      Component: PageHeader,
    },
    {
      id: '17133394204513641',
      Component: Content404,
    },
  ],
  { code: '3h549n' },
);
