import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, BoxProps } from '@chakra-ui/react';

import React, { useContext } from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

import PersistantModal from './atomics/PersistantModal';
import ModalContext from '@/contexts/ModalContext';
import { SvgShape } from './SvgShape';

export type ModalTextType = {
  id: string;
  containerProps?: BoxProps;
  shape?: boolean;
};

type ModalTextProps = KustomPageComponentViewProps<TextCarouselComponentData> &
  ModalTextType;

const ModalText: React.FC<ModalTextProps> = ({
  component,
  page,
  id,
  containerProps,
  shape = false,
}) => {
  const { modalRoute, setModalRoute } = useContext(ModalContext);

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  return (
    <PersistantModal
      isOpen={id === `modal-text-${modalRoute}`}
      isLoading={false}
      onClose={() => setModalRoute('')}
      modalStyle={{
        maxW: '900px',
        maxH: '700px',
        w: 'calc(100% - 1rem)',
        ...containerProps,
      }}
    >
      <Box
        p="8"
        pt="0"
        overflow="clip"
        position="relative"
        w="100%"
        h="100%"
        sx={{
          a: { color: 'brand.500' },
          h1: {
            mb: '0.5rem',
            fontSize: '1.125rem',
          },
          h2: {
            mb: '0.5rem',
            fontSize: '1.125rem',
          },
          h3: {
            mb: '0.5rem',
            fontSize: '1.125rem',
          },
          h4: {
            mb: '0.5rem',
            fontSize: '1.125rem',
          },
        }}
      >
        {shape && (
          <Box
            w="60%"
            position="absolute"
            right="0"
            bottom="0"
            userSelect="none"
          >
            <SvgShape
              shape={3}
              style={{
                transform: 'translate(7%, 30%) rotate(4deg)',
              }}
            />
          </Box>
        )}
        <RichText {...textBind} />
      </Box>
    </PersistantModal>
  );
};

export default ModalText;
