import { Box, Fade, Heading, Text, useTheme } from '@chakra-ui/react';
import {
  KustomPageComponent,
  KustomPageComponentViewProps,
  KustomTranslatedString,
  MediasComponentData,
  RoomBookingComponentData,
  RoomSpecsComponentData,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import useKustomData, {
  useKustomDataReturn,
  useKustomTranslation,
} from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

import KustomString from '@/lib/kustomcms-sdk/lib/components/KustomString';
import React from 'react';
import RoomBooking from './RoomBooking';
import RoomDescription from './RoomDescription';
// import SketchFish2 from '@/../public/assets/img/illu-poisson-2.svg';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import ContainerSection from '../ContainerSection';
import RoomMedias from './RoomMedias';

const RoomContent: React.FC<
  KustomPageComponentViewProps<RoomSpecsComponentData>
> = ({ component, page }) => {
  const roomDescription = usePageComponent<TextComponentData>(page, 'text');
  const roomBooking = usePageComponent<RoomBookingComponentData>(
    page,
    'roomBooking',
  );

  const roomMedia = usePageComponent<MediasComponentData>(page, 'medias');

  const titleBind = useKustomData(page, component, 'data.title');

  const peoplesLabelBind = useKustomTranslation('room_label_peoples');

  const peoplesNumberBind = useKustomData<string>(
    page,
    component,
    `data.peoplesNumber`,
  );

  const areaBind = useKustomData<string>(page, component, `data.area`);
  const bedBind = useKustomData<string | KustomTranslatedString>(
    page,
    component,
    `data.bed`,
  );
  const bedBindString = (
    typeof bedBind.value === 'string' ? bedBind : null
  ) as useKustomDataReturn<string> | null;
  const bedBindObject = (
    typeof bedBind.value === 'object' ? bedBind : null
  ) as useKustomDataReturn<KustomTranslatedString> | null;

  return (
    <ContainerSection
      mt="20"
      mb="20"
      py={'clamp(1rem, 6vw, 7rem) !important'}
      display="grid"
      gridTemplateColumns={['1fr', null, null, '3fr 1fr']}
      gap={['clamp(1rem, 2vw, 2.25rem)']}
      overflow="visible !important"
    >
      <Box>
        {roomMedia && <RoomMedias component={roomMedia} page={page} />}
        <Box w="100%">
          <Box pt={'clamp(1rem, 6vw, 7rem) !important'}>
            <Heading as="h2" mb="2" ml={[null, null, 'var(--grid-s-1)']}>
              <TranslatedString {...titleBind} color="brand.500" />
            </Heading>
            <Text
              display="flex"
              flexWrap="wrap"
              whiteSpace="nowrap"
              fontWeight="700"
              ml={[null, null, 'var(--grid-s-1)']}
            >
              <KustomString as="span" {...peoplesNumberBind} />
              &nbsp;
              <TranslatedString as="span" {...peoplesLabelBind} />
              <Box as="span" color="brand.500" px="4">
                |
              </Box>
              <KustomString as="span" {...areaBind} />
              <Box as="span" color="brand.500" px="4">
                |
              </Box>
              {bedBindObject && (
                <TranslatedString as="span" {...bedBindObject} />
              )}
              {bedBindString && <KustomString as="span" {...bedBindString} />}
            </Text>
            {roomDescription && (
              <RoomDescription component={roomDescription} page={page} />
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        {roomBooking && <RoomBooking component={roomBooking} page={page} />}
      </Box>
    </ContainerSection>
  );
};

export default RoomContent;
