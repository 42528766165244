import {
  Box,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Portal,
  SimpleGrid,
  Spinner,
  useTheme,
} from '@chakra-ui/react';
import {
  KustomPage,
  KustomPageComponentViewProps,
  RoomListComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import RoomCard from './RoomCard';
import { useRouter } from 'next/router';
import Section from '../Section';
import ContainerSection from '../ContainerSection';
import { ParallaxEl } from '../ParallaxEl';
import { SvgShape } from '../SvgShape';

const shapes: { [key: string]: 2 | 4 } = {
  '1687025539835268': 2,
  '16870255520869428': 4,
};

const RoomsGrid: React.FC<
  KustomPageComponentViewProps<RoomListComponentData> & { grid: number }
> = ({ page, component, grid }) => {
  const router = useRouter();

  const shape = shapes[component.id] || 1;

  const [isRoomsOpen, setIsRoomsOpen] = useState(false);

  const pagesContext = useContext(PagesContext);
  const roomsId = component.data.rooms;

  const rooms = useMemo(() => {
    return roomsId
      .map((roomId) => pagesContext.rooms.find((room) => room._id === roomId))
      .filter(
        (room) => !!room && component.data.activeRoomsId.includes(room._id),
      );
  }, [roomsId, pagesContext.rooms]);

  const { currentLang } = useContext(LanguageContext);

  const goToRoom = (room: KustomPage) => {
    const bt = document.querySelector(
      `.diadao-rooms-sticky-opened #diadao-hdp-bar-mobile .hsc-sticky-close-enabled 
      .diadao-button-hdp-mobile.hsc-button-sticky-rooms-exit`,
    ) as HTMLButtonElement;
    if (bt) {
      bt.click();
    }
    const href = room.prettyUrl[currentLang || 'fr'] || room.prettyUrl.fr;
    if (!href) {
      return;
    }
    setIsRoomsOpen(true);
    // setTimeout(() => {
    router.push(
      room.prettyUrl[currentLang || 'fr'] || room.prettyUrl.fr,
      undefined,
      // { scroll: false },
    );
    // }, 250);
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const containerRefIsVisible = useRef(false);

  function handleScroll() {
    const { top } = cardRef.current?.getBoundingClientRect() || {};
    if (!top) return;

    if (!containerRefIsVisible.current && top < window.innerHeight * 0.75) {
      containerRefIsVisible.current = true;
      document.body.style.setProperty('--custom-hdp-bar-opacity', '1');
      document.body.style.setProperty('--custom-hdp-bar-translate-y', '0');
    } else if (
      containerRefIsVisible.current &&
      top > window.innerHeight * 0.75
    ) {
      containerRefIsVisible.current = false;
      document.body.style.setProperty('--custom-hdp-bar-opacity', '0');
      document.body.style.setProperty('--custom-hdp-bar-translate-y', '100%');
    }
  }

  useEffect(() => {
    if (grid === 1) {
      if (!document.getElementById('style-diadao-hdp-bar')) {
        const style = document.createElement('style');
        style.id = 'style-diadao-hdp-bar';
        style.innerHTML = `
      #diadao-hdp-bar {
        transform: translateY(var(--custom-hdp-bar-translate-y, 100%)) !important;
        opacity: var(--custom-hdp-bar-opacity, 0) !important;
        transition: transform 0.15s easeInOut !important, opacity 0.15s easeInOut !important;
        }
      .container-hdp-bar-rooms {
        transform: translateY(var(--custom-hdp-bar-translate-y, 100%)) !important;
        opacity: var(--custom-hdp-bar-opacity, 0) !important;
        transition: transform 0.15s easeInOut !important, opacity 0.15s easeInOut !important;
        }
      `;
        document.head.appendChild(style);
      }
      document.addEventListener('scroll', handleScroll);
    }
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Section className={'rooms-grid'} ref={containerRef}>
      <ContainerSection pt="0 !important">
        {shape === 2 && (
          <Box
            width={['30%', null, null, '18%']}
            right="0"
            top={'0'}
            position="absolute"
            zIndex="2"
            pointerEvents={'none'}
          >
            <ParallaxEl speed={-6}>
              <Box transform="translate(5%, -10%) rotate(300deg)">
                <SvgShape shape={shape} />
              </Box>
            </ParallaxEl>
          </Box>
        )}
        {shape === 4 && (
          <Box
            width={['70%', null, null, '50%', '30%']}
            left="0"
            bottom={'0'}
            position="absolute"
            zIndex="2"
            pointerEvents={'none'}
          >
            <ParallaxEl speed={-7}>
              <Box
                transform={[
                  'translate(-25%, -10%) rotate(210deg)',
                  null,
                  null,
                  'translate(-15%, -10%) rotate(210deg)',
                ]}
              >
                <SvgShape shape={shape} />
              </Box>
            </ParallaxEl>
          </Box>
        )}

        <SimpleGrid
          columns={[1, null, null, 2]}
          spacing={['clamp(1rem, 2vw, 2.25rem)']}
          mx={['0', null, null, null, 'var(--grid-s-1)']}
          position="relative"
          zIndex={2}
        >
          {rooms.map((room, index) =>
            room ? (
              <Box key={index} ref={index === 0 ? cardRef : null}>
                <RoomCard
                  key={index}
                  room={room}
                  index={index}
                  onClick={() => goToRoom(room)}
                />
              </Box>
            ) : null,
          )}
        </SimpleGrid>
        <Portal>
          <Drawer isOpen={isRoomsOpen} placement="right" onClose={() => {}}>
            <DrawerOverlay />
            <DrawerContent w="100vw" maxW="100vw" bgColor="brand.100">
              <DrawerBody>
                <Spinner
                  size="lg"
                  color="brand.500"
                  ml={5}
                  position={'fixed'}
                  zIndex={1000}
                  top={'50%'}
                  left={'50%'}
                  marginLeft={'-1rem'}
                  marginTop={'-1rem'}
                />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Portal>
      </ContainerSection>
    </Section>
  );
};

export default RoomsGrid;
