import { useEffect, useState } from 'react';

interface InstagramPost {
  mediaUrl: string;
  likes: number;
}

const useLightWidgetData = (token: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [posts, setPosts] = useState<InstagramPost[]>([]);

  const extractData = (doc: Document) => {
    const items = doc.querySelectorAll('.lightwidget__photo');

    if (!items) {
      return;
    }

    const posts = Array.from(items).map((item) => {
      const mediaUrl = item
        .querySelector<HTMLImageElement>('.lightwidget__image')
        ?.attributes?.getNamedItem('data-src')?.value;

      const likes = +(
        item.querySelector('.lightwidget__likes')?.textContent || '0'
      );

      return { mediaUrl, likes } as InstagramPost;
    });

    setIsLoading(false);
    setPosts(posts);
  };

  useEffect(() => {
    setIsLoading(true);
    fetch('/api/fetch-lightwidget?token=' + token, {})
      .then((res) => {
        return res.json();
      })
      .then(({ html }) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        extractData(doc);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [token]);

  return {
    posts,
    isLoading,
  };
};

export default useLightWidgetData;
