import { Box, BoxProps, ResponsiveValue } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';

import {
  CarouselComponentSlide,
  KustomResponsiveMedia,
} from '@/lib/kustomcms-sdk/lib/types';
import MediasViewerContext from '@/contexts/MediasViewerContext';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import dynamic from 'next/dynamic';
import getCSSAdminWysiwygItem from '@/lib/kustomcms-sdk/lib/admin/helpers/getCSSAdminWysiwygItem';
import { useKustomSelector } from '@/lib/kustomcms-sdk/lib/admin/hooks/useKustomSelector';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import kustomDataMediasToSlidesTransformOptions from '@/lib/kustomcms-sdk/lib/helpers/kustomDataMediasToSlidesTransformOptions';

const DynamicEditMediasModal = dynamic(
  () => import('@/lib/kustomcms-sdk/lib/admin/components/EditMediasModal'),
);

interface RoomArtistSlidesProps {
  value?: CarouselComponentSlide[];
  duration?: number;
  height?: ResponsiveValue<number | string>;
  delay?: number;
  transitionDuration?: number;
  hideIndicators?: boolean;
  hideArrows?: boolean;
  hideCounter?: boolean;
  canSwipe?: boolean;
  onClickItem?: (index: number) => void;
  style?: React.CSSProperties;
  usingMediaViewer?: boolean;
  containerStyle?: BoxProps;
  isHovered?: boolean;
  sizes?: string;
  onChange?: (medias: CarouselComponentSlide[]) => void;
}

const RoomArtistSlides: React.FC<RoomArtistSlidesProps> = ({
  value: medias,
  style,
  onClickItem,
  usingMediaViewer,
  containerStyle,
  sizes,
  onChange,
}) => {
  const currentDevice = useResponsiveMediasDevice();
  const [currentIndex, setCurrentIndex] = useState(0);

  const { user } = useKustomSelector((state) => state.app);

  const hasWysiwygEdit = !!(user && onChange);

  const [isWysiwygEditOpen, setIsWysiwygEditOpen] = useState(false);

  const closeWysiwygEdit = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setIsWysiwygEditOpen(false);
  };

  const mediasViewer = useContext(MediasViewerContext);

  const mediasImg = medias?.map((slide) => slide.medias);

  const openMediaViewer = () => {
    if (usingMediaViewer && mediasImg) {
      mediasViewer.setMedias(mediasImg);
      mediasViewer.setIndex(currentIndex);
    }
  };

  const handleOnChange = (newMediasImg: KustomResponsiveMedia[]) => {
    const newMedias = kustomDataMediasToSlidesTransformOptions.transformInput(
      newMediasImg,
      medias,
    );
    if (newMedias) onChange?.(newMedias);
  };

  const { currentLang } = useContext(LanguageContext);

  if (!medias) return null;

  return (
    <Box
      position="relative"
      role={hasWysiwygEdit ? 'group' : undefined}
      sx={{
        '.kustomAdminUI': {
          position: 'absolute',
          top: ['40vw', null, '20vw'],
          left: '50%',
        },
      }}
    >
      <Box
        position="relative"
        height={'100%'}
        overflow={hasWysiwygEdit ? undefined : 'clip'}
        cursor={onClickItem || usingMediaViewer ? 'pointer' : 'default'}
        role="group"
        {...getCSSAdminWysiwygItem({
          hasWysiwygEdit,
          isWysiwygEditOpen,
          setIsOpen: setIsWysiwygEditOpen,
        })}
        {...containerStyle}
      >
        <Box
          {...(hasWysiwygEdit && {
            pointerEvents: 'none',
          })}
          height={'100%'}
          display={'flex'}
          flexDirection={'column'}
          gap={'8'}
        >
          <>
            {medias.map((mediaData, index) => {
              const media = mediaData.medias;
              const metadataW = media.default?.metadata.width;
              const metadataH = media.default?.metadata.height;
              const aspectRatio =
                metadataW && metadataH
                  ? `${metadataW} / ${metadataH}`
                  : '16 / 9';
              return (
                <Box w="100%" key={mediaData.id}>
                  <Box
                    w="100%"
                    overflow="clip"
                    position="relative"
                    onClick={openMediaViewer}
                    style={style}
                    role="group"
                    height="100%"
                    aspectRatio={aspectRatio}
                  >
                    {media && (
                      <ResponsiveMedias
                        key={index}
                        value={media}
                        currentDevice={currentDevice}
                        fill
                        width={undefined}
                        height={undefined}
                        sizes={sizes}
                        style={{
                          objectFit: 'cover',
                          background: 'var(--chakra-colors-brand-200)',
                        }}
                        skeletonColor={{
                          base: '#efd9c9',
                          highlight: '#F4E7DC',
                        }}
                      />
                    )}
                  </Box>
                  <Box mt="3" fontWeight="700">
                    {mediaData.title?.[currentLang]}
                  </Box>
                </Box>
              );
            })}
          </>
        </Box>
      </Box>
      {hasWysiwygEdit && mediasImg && (
        <DynamicEditMediasModal
          isOpen={isWysiwygEditOpen}
          onClose={closeWysiwygEdit}
          value={mediasImg}
          onChange={handleOnChange}
          triggerProps={{
            cursor: 'pointer',
            onClick: () => setIsWysiwygEditOpen((io) => !io),
          }}
        />
      )}
    </Box>
  );
};

export default RoomArtistSlides;
