import {
  ActionButtonComponent,
  CarouselComponentSlide,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Heading } from '@chakra-ui/react';

import ActionButton from '@/components/atomics/ActionButton';
import AnimatePresence from '../atomics/AnimatePresence';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import Slideshow from '../atomics/Slideshow';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useKustomDataMediasToSlidesTransformOptions from '@/lib/kustomcms-sdk/lib/helpers/kustomDataMediasToSlidesTransformOptions';
import ContainerSection from '../ContainerSection';
import Section from '../Section';
import { SvgShape } from '../SvgShape';
import { ParallaxEl } from '../ParallaxEl';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';

const Content404 = ({
  component,
  page,
}: KustomPageComponentViewProps<TextCarouselComponentData>) => {
  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const atomTextId = component.data.atomicComponents?.findIndex(
    (cmp) => cmp.type === 'TEXT',
  );
  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${atomTextId}.text`,
  );

  const atomActionButtonId = component.data.atomicComponents?.findIndex(
    (cmp) => cmp.type === 'ACTION_BUTTON',
  );
  const actionButtonBind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${atomActionButtonId}`,
  );

  const atomMediaId = component.data.atomicComponents?.findIndex(
    (cmp) => cmp.type === 'MEDIA',
  );
  const mediaBind = useKustomData<KustomResponsiveMedia>(
    page,
    component,
    `data.atomicComponents.${atomMediaId}.media`,
  );

  const device = useResponsiveMediasDevice();

  return (
    <Section className={'section-404'}>
      <ContainerSection>
        <Box
          width={['80%', null, null, '52%']}
          right="0"
          bottom="0"
          position="absolute"
          pointerEvents={'none'}
        >
          <ParallaxEl speed={-5}>
            <SvgShape
              shape={3}
              style={{
                transform: 'translate(20%, 0)',
              }}
            />
          </ParallaxEl>
        </Box>
        <Box
          display={['block', null, null, 'flex']}
          justifyContent="space-between"
          // alignItems="start"
          alignItems="center"
        >
          <Box
            position="relative"
            zIndex={2}
            width={['100%', null, null, '37%']}
            // pt={['0', null, null, '12', '16']}
          >
            <AnimatePresence opacity>
              <Heading as="h2">
                <RichText {...titleBind} />
              </Heading>
              <RichText {...textBind} />
              <Box mt="clamp(2rem, 2.5vw, 3rem)">
                {actionButtonBind && (
                  <ActionButton
                    textAlign="left"
                    variant="button"
                    {...actionButtonBind}
                  />
                )}
              </Box>
            </AnimatePresence>
          </Box>
          <Box
            position={'relative'}
            width={['100%', null, null, '57%']}
            mt={['3rem', null, null, '0']}
            aspectRatio={4 / 3}
          >
            <ParallaxEl speed={5}>
              <ResponsiveMedias
                {...mediaBind}
                disableCSSAdminWysiwyg
                currentDevice={device}
                style={{
                  objectFit: 'cover',
                  borderRadius: 4,
                  background: 'var(--chakra-colors-brand-200)',
                }}
                fill
                width={undefined}
                height={undefined}
                // sizes="(max-width: 959px) 95vw, (max-width: 1919px) 45vw, 920px"
                sizes={chakraResponsiveToImageSizes([
                  '95vw',
                  null,
                  null,
                  '45vw',
                  null,
                  '920px',
                ])}
                skeletonColor={{
                  base: '#efd9c9',
                  highlight: '#F4E7DC',
                }}
              />
            </ParallaxEl>
          </Box>
        </Box>
      </ContainerSection>
    </Section>
  );
};

export default Content404;
