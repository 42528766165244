import { theme as chakraTheme } from '@chakra-ui/theme';
import {
  createMultiStyleConfigHelpers,
  extendBaseTheme,
  extendTheme,
  textDecoration,
} from '@chakra-ui/react';
import { inputAnatomy } from '@chakra-ui/anatomy';

const { Button, Drawer, Container, Textarea, Tabs, Accordion, Select } =
  chakraTheme.components;

const activeLabelStyles = {
  transform: 'scale(0.55) translateY(-7px)',
};

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    fontFamily: 'commutersSansLight',
    backgroundColor: '#ffffff',
    outline: '1px solid transparent !important',
    border: 'none !important',
    borderRadius: '4px',
    boxShadow: '0 0 1rem rgba(0, 0, 0, 0.025)',
    _focus: {
      // outline: '2px solid #90A88D !important',
      outline: '1px solid #90A88D !important',
    },
    _invalid: {
      // outline: '2px solid #CD6917 !important',
      outline: '1px solid red !important',
    },
  },
});

const lg = definePartsStyle({
  field: {
    paddingX: 6,
    paddingBottom: 2.5,
    paddingTop: 6,
  },
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    lg,
  },
});

export const textareaTheme = {
  baseStyle: {
    ...Textarea.baseStyle,
    paddingX: 6,
    paddingBottom: 2.5,
    paddingTop: 8,
    backgroundColor: 'white',
    outline: '1px solid transparent !important',
    border: 'none !important',
    borderRadius: '4px',
    boxShadow: '0 0 1rem rgba(0, 0, 0, 0.025) !important',
    _focus: {
      // outline: '2px solid #90A88D !important',
      outline: '1px solid #90A88D !important',
    },
    _invalid: {
      // outline: '2px solid #CD6917 !important',
      outline: '1px solid red !important',
    },
  },
};

export const selectTheme = {
  baseStyle: {
    ...Select.baseStyle,
    border: 'none !important',
    field: {
      ...Select.baseStyle?.field,
      height: '4rem',
      paddingInlineStart: '1.5rem',
      backgroundColor: 'white',
      // color: '#CD6917',
      fontSize: '1.125rem',
      outline: '1px solid transparent !important',
      border: 'none !important',
      borderRadius: '4px',
      boxShadow: '0 0 1rem rgba(0, 0, 0, 0.025) !important',
      _focus: {
        // outline: '2px solid #90A88D !important',
        outline: '1px solid #90A88D !important',
      },
      _invalid: {
        // outline: '2px solid #CD6917 !important',
        outline: '1px solid red !important',
      },
    },
  },
};

const theme = extendBaseTheme({
  styles: {
    global: {
      body: {
        color: '#534B45',
        '--grid-w-0': '0%',
        '--grid-w-1': '6.098%',
        '--grid-w-2': '14.634%',
        '--grid-w-3': '23.171%',
        '--grid-w-4': '31.707%',
        '--grid-w-5': '40.244%',
        '--grid-w-6': '48.78%',
        '--grid-w-7': '57.317%',
        '--grid-w-8': '65.853%',
        '--grid-w-9': '74.39%',
        '--grid-w-10': '82.927%',
        '--grid-w-11': '91.463%',
        '--grid-w-12': '100%',
        '--grid-s': '2.439%',
        '--grid-s-1': '8.53656%',
        '--grid-s-2': '17.073%',
        '--grid-s-3': '25.609%',
        '--grid-s-4': '34.146%',
        '--grid-s-5': '42.682%',
        '--grid-s-6': '51.219%',
        '--grid-s-7': '59.756%',
        '--grid-s-8': '68.292%',
        '--grid-s-9': '76.829%',
        '--grid-s-10': '85.366%',
        '--grid-s-11': '93.902%',
        '--grid-s-12': '100%',
      },
    },
  },
  radii: {
    none: '0',
    sm: '0.125rem',
    base: '4px',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    full: '9999px',
  },
  breakpoints: {
    base: '0px',
    sm: '480px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1920px',
  },
  breakpointsMax: {
    base: '0px',
    sm: '479px',
    md: '767px',
    lg: '959px',
    xl: '1199px',
    '2xl': '1919px',
  },
  sizes: {
    container: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1580px',
      '3xl': '1840px',
    },
  },
  fonts: {
    commutersSansLight: `'CommutersSansLight', sans-serif`,
    commutersSansBold: `'CommutersSansBold', sans-serif`,
    basteleur: `'Basteleur', sans-serif`,
    body: `'CommutersSansLight', sans-serif`,
    heading: `'Basteleur', sans-serif`,
  },
  colors: {
    brand: {
      // 50: '#F9F5EC',
      50: '#fcf3f0',
      100: '#F4E7DC',
      200: '#efd9c9',
      300: '#eeceb7',
      400: '#e29c62',
      500: '#CD6917',
      600: '#8E4A12',
    },
    accent: {
      100: '#F4E7DC',
      500: '#CD6917',
    },
    gray: {
      400: '#746960',
      500: '#534B45',
      600: '#242322',
    },
    mint: {
      400: '#D1D8BD',
      500: '#D3E7D0',
      700: '#90A88D',
      900: '#232B22',
    },
  },
  components: {
    ...chakraTheme.components,
    Drawer: {
      ...Drawer,
    },
    Container: {
      ...Container,
      baseStyle: {
        ...Container.baseStyle,
      },
    },
    Input: {
      ...inputTheme,
    },
    Select: {
      ...selectTheme,
    },
    Button: {
      ...Button,
      baseStyle: {
        ...Button.baseStyle,
        transition: 'all 0.2s ease',
        fontWeight: '500',
        fontSize: '16px',
        borderRadius: '6px',
        maxWidth: '100%',
        lineHeight: 1,
      },
      variants: {
        ...Button.variants,
        whiteSpace: 'normal',
        fontFamily: 'commutersSansBold',
        link: {
          fontFamily: 'commutersSansBold',
          padding: 0,
          color: 'brand.500',
          _before: {
            content: '""',
            position: 'absolute',
            bottom: '0.4em',
            left: 0,
            width: '100%',
            height: '2px',
            bgColor: 'brand.500',
            transform: 'scaleX(0)',
            transition: 'transform 0.3s ease-in-out',
            transformOrigin: 'right',
          },
          _hover: {
            _before: {
              transform: 'scaleX(1)',
              transformOrigin: 'left',
            },
          },
        },
        button: {
          px: '6',
          py: '6',
          fontSize: '0.875rem',
          fontWeight: '700',
          bgColor: 'brand.500',
          color: 'white',

          _hover: {
            bgColor: 'brand.600',
          },
        },
        buttonLight: {
          px: '6',
          py: '6',
          fontSize: '0.875rem',
          fontWeight: '700',
          bgColor: 'brand.100',
          color: 'brand.500',
          _hover: {
            bgColor: 'brand.500',
            color: 'white',
          },
        },
      },
    },
    Textarea: {
      ...textareaTheme,
    },
    // Checkbox: {
    //   ...checkboxTheme,
    // },
    Tabs,
    Text: {
      baseStyle: {
        overflowWrap: 'break-word',
      },
    },
    FormLabel: {
      baseStyle: {
        color: 'brand.500',
        fontSize: '1.125rem',
      },
    },
    Heading: {
      baseStyle: {
        color: 'brand.500',
        fontFamily: 'basteleur',
        lineHeight: '1.3',
        textTransform: 'uppercase',
        marginBottom: 'clamp(2rem, 2.5vw, 3rem)',
        fontSize: 'clamp(1.75rem, 2.75vw, 3.25rem)',
        textWrap: 'balance',
      },
      variants: {
        h2b: {
          // fontSize: '1.5rem',
          fontSize: 'clamp(1.75rem, 2.25vw, 2.75rem)',
          color: 'brand.500',
          textTransform: 'none',
        },
        h3: {
          // fontSize: '1.5rem',
          fontSize: 'clamp(1.75rem, 1.6vw, 2rem)',
          color: 'gray.500',
          textTransform: 'none',
          lineHeight: '1.15',
        },
        h4: {
          // fontSize: '1.5rem',
          fontSize: 'clamp(1.5rem, 1.5vw, 1.75rem)',
          color: 'gray.500',
          textTransform: 'none',
          lineHeight: '1.2',
        },
        h5: {
          fontSize: '1.375rem',
          color: 'gray.500',
          textTransform: 'none',
          lineHeight: '1.3',
        },
      },
    },
    Form: {
      variants: {
        floating: {
          container: {
            position: 'relative',
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 1.5,
              left: 6,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'transparent',
              pointerEvents: 'none',
              my: 2,
              transformOrigin: 'left top',
              transition: 'all 0.2s ease',
            },
          },
        },
      },
    },
    Accordion: {
      ...Accordion,
      baseStyle: {
        button: {
          _hover: {},
        },
        icon: {
          fontSize: '0',
        },
      },
    },
  },
});

export default theme;
