import {
  Box,
  BoxProps,
  Fade,
  IconButton,
  Portal,
  Spinner,
  chakra,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { IconSvg } from '../IconSvg';

interface PersistantModalProps {
  modalStyle?: BoxProps;
  containerStyle?: BoxProps;
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const PersistantModal: React.FC<PersistantModalProps> = ({
  modalStyle,
  containerStyle,
  children,
  isOpen: isOpenProps,
  isLoading,
  onClose,
}) => {
  const [isDisplayed, setIsDisplayed] = useState(isOpenProps);
  const [isOpen, setIsOpen] = useState(isOpenProps);

  useEffect(() => {
    if (isOpen) {
      setIsDisplayed(true);
    } else {
      setTimeout(() => {
        setIsDisplayed(false);
        onClose();
      }, 500);
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(isOpenProps);
  }, [isOpenProps]);

  const close = () => {
    setIsOpen(false);
  };

  return (
    <Portal>
      <Box
        position="fixed"
        display={isDisplayed ? 'flex' : 'none'}
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={99999}
      >
        <Fade in={isOpen} unmountOnExit={false}>
          <Box
            className="modal-overlay"
            position="fixed"
            displ="true"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display={isDisplayed ? 'flex' : 'none'}
            bgColor="rgba(0,0,0,0.7)"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            onClick={(e) => {
              if (e.target === e.currentTarget) close();
            }}
            {...containerStyle}
          >
            <Box
              className="modal-container"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              position="relative"
              bgColor="white"
              shadow="base"
              borderRadius={[null, null, 'base']}
              overflow="hidden"
              w="100%"
              h="100%"
              m={[0, null, 4]}
              {...modalStyle}
            >
              <Box
                top={1}
                right={1}
                display="flex"
                w="100%"
                alignItems="center"
                // justifyContent={isLoading ? 'space-between' : 'flex-end'}
                justifyContent={'flex-end'}
              >
                {/* {isLoading && <Spinner size="sm" color="brand.500" ml={5} />} */}
                <IconButton
                  aria-label="Close"
                  fontSize={['12px']}
                  p={2}
                  py={4}
                  m={1}
                  bgColor="transparent"
                  color="brand.500"
                  _hover={{
                    bgColor: 'brand.100',
                  }}
                  icon={<IconSvg icon="cross" size="1.5rem" />}
                  onClick={close}
                />
              </Box>
              <Box position="relative" w="100%" overflowY="auto" flexGrow="1">
                {isLoading && (
                  <Spinner
                    size="lg"
                    color="brand.500"
                    ml={5}
                    position={'absolute'}
                    top={'50%'}
                    left={'50%'}
                    marginLeft={'-1rem'}
                    marginTop={'-1rem'}
                  />
                )}
                {children}
              </Box>
            </Box>
          </Box>
        </Fade>
      </Box>
    </Portal>
  );
};

export default PersistantModal;
