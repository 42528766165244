import {
  ActionButtonComponent,
  AtomicComponent,
  CustomComponentData,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  MediaComponent,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Heading } from '@chakra-ui/react';

import ActionButton from '@/components/atomics/ActionButton';
import AnimatePresence from '../atomics/AnimatePresence';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import Slideshow from '../atomics/Slideshow';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import { uniqID } from '@/lib/kustomcms-sdk/lib/admin/helpers/random';
import ContainerSection from '../ContainerSection';
import { useMemo } from 'react';
import Section from '../Section';
import { SvgShape } from '../SvgShape';
import { ParallaxEl } from '../ParallaxEl';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';

const Section8 = ({
  component,
  page,
}: KustomPageComponentViewProps<TextCarouselComponentData>) => {
  const [textIndex1, textIndex2] = component.data.atomicComponents
    .map((cmp, index) => (cmp.type === 'TEXT' ? index : undefined))
    .filter((i) => i !== undefined) as number[];

  const mediasCmp = component.data.atomicComponents.filter(
    (cmp) => cmp.type === 'MEDIA',
  ) as MediaComponent[];

  const buttonsIndex = component.data.atomicComponents
    .map((cmp, index) =>
      cmp.type === 'ACTION_BUTTON' ? { index, id: cmp.id } : undefined,
    )
    .filter((i) => i !== undefined) as { id: string; index: number }[];

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${textIndex1}.text`,
  );

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${textIndex2}.text`,
  );

  const atomsBind = useKustomData<AtomicComponent[]>(
    page,
    component,
    `data.atomicComponents`,
  );

  const medias = mediasCmp.map((cmp) => cmp.media);

  const onMediasChange = useMemo(
    () =>
      atomsBind.onChange
        ? (medias: KustomResponsiveMedia[]) => {
            const atomsNoMedias = atomsBind.value?.filter(
              (cmp) => cmp.type !== 'MEDIA',
            );

            const newAtoms = medias.map((media) => {
              const mediaAtomCmp = (atomsBind.value?.find(
                (cmp) =>
                  cmp.type === 'MEDIA' &&
                  (cmp as MediaComponent).media.id === media.id,
              ) || {
                id: uniqID(),
                type: 'MEDIA',
                media,
              }) as MediaComponent;

              mediaAtomCmp.media = media;

              return mediaAtomCmp;
            });

            atomsBind.onChange?.([...newAtoms, ...(atomsNoMedias || [])]);
          }
        : undefined,
    [atomsBind.onChange, atomsBind.value],
  );

  return (
    <Section className={'section-8'}>
      <ContainerSection>
        <Box
          width={['90%', null, null, '50%']}
          left="0"
          top={['auto', null, null, '0']}
          bottom={['0', null, null, 'auto']}
          position="absolute"
          zIndex="0"
          pointerEvents={'none'}
        >
          <ParallaxEl speed={-5}>
            <Box
              transform={[
                'translate(-16%, 6%) rotate(10deg)',
                null,
                null,
                'translate(-16%, 6%) rotate(345deg)',
              ]}
            >
              <SvgShape shape={5} />
            </Box>
          </ParallaxEl>
        </Box>
        <Box
          display="flex"
          flexDirection={['column', null, null, 'row']}
          justifyContent="space-between"
          // alignItems="start"
          alignItems="center"
        >
          <Box
            width={['100%', null, null, '57%']}
            mt={['3rem', null, null, '0']}
            aspectRatio={4 / 3}
          >
            <ParallaxEl speed={5}>
              {medias && (
                <Slideshow
                  usingMediaViewer
                  value={medias}
                  onChange={onMediasChange}
                  hideArrows
                  hideCounter
                  canSwipe={true}
                  height={'100%'}
                  containerStyle={{
                    borderRadius: 'base',
                  }}
                  // sizes="(max-width: 959px) 95vw, (max-width: 1919px) 45vw, 920px"
                  sizes={chakraResponsiveToImageSizes([
                    '95vw',
                    null,
                    null,
                    '45vw',
                    null,
                    '920px',
                  ])}
                />
              )}
            </ParallaxEl>
          </Box>
          <Box
            position="relative"
            zIndex={2}
            width={['100%', null, null, '35%']}
            order={['-1', null, null, '2']}
            // pt={['0', null, null, '12', '16']}
          >
            <AnimatePresence opacity>
              {titleBind && (
                <Heading
                  as="h2"
                  // marginLeft={[null, null, null, '-43%']}
                >
                  <RichText {...titleBind} color="brand.500" />
                </Heading>
              )}
              <RichText {...textBind} />
              <Box
                display="flex"
                flexDir="column"
                gap="0.5rem"
                alignItems="start"
                mt="clamp(2rem, 2.5vw, 3rem)"
              >
                {buttonsIndex.map(({ index: buttonIndex, id }, index) => (
                  <RestaurantsButton
                    component={component}
                    page={page}
                    key={id}
                    buttonIndex={buttonIndex}
                  />
                ))}
              </Box>
            </AnimatePresence>
          </Box>
        </Box>
      </ContainerSection>
    </Section>
  );
};

export default Section8;

interface RestaurantsButtonProps
  extends KustomPageComponentViewProps<CustomComponentData> {
  buttonIndex: number;
}

const RestaurantsButton: React.FC<RestaurantsButtonProps> = ({
  page,
  component,
  buttonIndex,
}) => {
  const buttonBind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${buttonIndex}`,
  );

  return <ActionButton variant="link" {...buttonBind} />;
};
