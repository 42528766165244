import { Box, BoxProps } from '@chakra-ui/react';
import Image, { ImageProps } from 'next/image';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import getMediaType, { MediaType } from '../helpers/getMediaType';

import { KustomResponsiveMedia } from '../types';
import LanguageContext from '../contexts/language';
import PagesContext from '../contexts/pages';
import dynamic from 'next/dynamic';
import getCSSAdminWysiwygItem from '../admin/helpers/getCSSAdminWysiwygItem';
import getKustomMediaUrl from '../admin/helpers/getKustomMediaUrl';
import getResponsiveMedia from '../helpers/getResponsiveMedia';
import { useKustomSelector } from '../admin/hooks/useKustomSelector';
import useLazyAutoplay from '../hooks/useLazyAutoplay';
import Skeleton from 'react-loading-skeleton';

const DynamicEditMediaModal = dynamic(
  () => import('../admin/components/EditMediaModal'),
);

const loadingBg =
  'linear-gradient(121deg, #F2EDE4 25%, #FEFEFE 52%, #F2EDE4 78%)';

interface ResponsiveMediasProps extends Partial<Omit<ImageProps, 'onChange'>> {
  value?: KustomResponsiveMedia;
  onChange?: (value?: KustomResponsiveMedia) => void;
  style?: any;
  currentDevice: Omit<keyof KustomResponsiveMedia, 'id'>;
  noBackground?: boolean;
  onLoaded?: () => void;
  containerProps?: BoxProps;
  disableCSSAdminWysiwyg?: boolean;
  quality?: number;
  skeleton?: boolean;
  controls?: boolean;
  skeletonColor?: {
    base: string;
    highlight: string;
  };
}

const ResponsiveMedias = (
  props: ResponsiveMediasProps,
  ref: React.ForwardedRef<HTMLVideoElement | HTMLImageElement>,
) => {
  const {
    value: medias,
    currentDevice,
    style,
    noBackground,
    skeleton = true,
    controls = false,
    skeletonColor = {
      base: '#f7eee0',
      highlight: '#fcf3f0',
    },
    onChange,
    onLoaded,
    containerProps,
    disableCSSAdminWysiwyg = false,
    quality = 80,
    ...imageProps
  } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const [isWysiwygEditOpen, setIsWysiwygEditOpen] = useState(false);

  const { kustomClient } = useContext(PagesContext);
  const { currentLang } = useContext(LanguageContext);

  const closeWysiwygEdit = useCallback(() => {
    setIsWysiwygEditOpen(false);
  }, []);

  const { user } = useKustomSelector((state) => state.app);

  const hasWysiwygEdit = !!(user && onChange);

  const media = medias && getResponsiveMedia(medias, currentDevice);

  const isVideo =
    media?.filename && getMediaType(media?.filename) === MediaType.VIDEO;

  const internalVideoRef = React.useRef<HTMLVideoElement | null>(null);

  React.useImperativeHandle<
    HTMLVideoElement | HTMLImageElement | null,
    HTMLVideoElement | null
  >(ref, () => internalVideoRef.current);

  useEffect(() => {
    // isLoaded video
    const listener = () => {
      onLoaded?.();
      setIsLoaded(true);
    };

    if (!internalVideoRef.current) {
      return;
    }

    const videoElement = internalVideoRef.current;
    const hasLoaded = videoElement.readyState === 4;

    if (hasLoaded && !isLoaded) {
      listener();
      return;
    }

    videoElement.addEventListener('loadeddata', listener);

    return () => {
      videoElement.removeEventListener('loadeddata', listener);
    };
  }, [internalVideoRef.current, onLoaded, isLoaded]);

  const lazyAutoplayBind = useLazyAutoplay();
  // typeof media?.metadata.autoplay === 'boolean'
  //   ? media?.metadata.autoplay
  //   : true,

  if (!media) {
    return null;
  }

  const { url, alt } = getKustomMediaUrl(media, currentLang);

  return (
    <Box
      position={'relative'}
      h="100%"
      {...(disableCSSAdminWysiwyg &&
        getCSSAdminWysiwygItem({
          hasWysiwygEdit,
          isWysiwygEditOpen,
          setIsOpen: setIsWysiwygEditOpen,
        }))}
      {...containerProps}
    >
      {isVideo ? (
        <>
          <video
            {...lazyAutoplayBind}
            ref={(node) => {
              internalVideoRef.current = node;
              if (typeof lazyAutoplayBind.ref === 'function') {
                lazyAutoplayBind.ref(node);
              }
            }}
            loop
            muted
            playsInline
            controls={controls}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              background: loadingBg,
              ...style,
            }}
          >
            <source src={url} type="video/mp4" />
          </video>
        </>
      ) : (
        <>
          {skeleton && (
            <Skeleton
              width="100%"
              height="100%"
              style={{
                zIndex: 0,
                position: 'absolute',
                right: 0,
                left: 0,
                top: 0,
                opacity: isLoaded ? 0 : 1,
              }}
              borderRadius={0}
              baseColor={skeletonColor.base}
              highlightColor={skeletonColor.highlight}
            />
          )}
          <Image
            loading="lazy"
            ref={ref as React.RefObject<HTMLImageElement>}
            alt={alt || ''}
            src={url}
            quality={quality}
            width={+(media.metadata.width || '')}
            height={+(media.metadata.height || '')}
            style={{
              background: noBackground || isLoaded ? 'none' : loadingBg,
              opacity: isLoaded ? 1 : 0,
              // opacity: 0,
              ...style,
            }}
            {...imageProps}
            onLoadingComplete={() => {
              setIsLoaded(true);
              onLoaded?.();
            }}
          />
        </>
      )}
      {onChange && (
        <DynamicEditMediaModal
          isOpen={isWysiwygEditOpen}
          onClose={closeWysiwygEdit}
          value={medias}
          onChange={onChange!}
        />
      )}
    </Box>
  );
};

export default React.forwardRef(ResponsiveMedias);
