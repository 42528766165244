import { ActionButtonComponent } from '@/lib/kustomcms-sdk/lib/types';
import { Box, Button, ButtonProps, chakra } from '@chakra-ui/react';
import { useContext, useState } from 'react';

import EditActionButtonModal from '@/lib/kustomcms-sdk/lib/admin/components/EditActionButtonModal';

import Link from 'next/link';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import WrapIf from '@/lib/kustomcms-sdk/lib/components/atomics/WrapIf';
import getCSSAdminWysiwygItem from '@/lib/kustomcms-sdk/lib/admin/helpers/getCSSAdminWysiwygItem';
import { useKustomSelector } from '@/lib/kustomcms-sdk/lib/admin/hooks/useKustomSelector';
import useNavigationKustomHref from '@/lib/kustomcms-sdk/lib/hooks/useNavigationKustomHref';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import { IconSvg } from '../IconSvg';

export function extractValues(
  inputString: string,
  markers: string[],
): Record<string, string> {
  const extractedValues: Record<string, string> = {};

  markers.forEach((marker) => {
    const regex = new RegExp(
      `@${marker}=(\\w+)|@${marker}=(\\w+)|@${marker}(\\w+)(?=@|$)`,
      'g',
    );
    let values = [];
    let match;
    while ((match = regex.exec(inputString)) !== null) {
      const value = match[1] || match[2] || match[3];
      if (value) values.push(value);
    }
    if (values[0]) extractedValues[marker] = values[0];
  });

  return extractedValues;
}

interface ActionButtonProps extends Omit<ButtonProps, 'onChange' | 'value'> {
  value?: ActionButtonComponent;
  disableLink?: boolean;
  actions?: { [key: string]: () => void };
  onChange?: (value: ActionButtonComponent) => void;
}

const ActionButton = ({
  value: button,
  disableLink,
  onChange,
  variant,
  ...buttonProps
}: ActionButtonProps) => {
  const { actionButtonPatterns } = useContext(PagesContext);
  const { currentLang } = useContext(LanguageContext);

  const { user } = useKustomSelector((state) => state.app);

  const isCustomAction = button?.actionString?.[0] === '@';
  const [actionPattern = '', actionHref] = button?.actionString?.split('=') || [
    '',
    '',
  ];
  const hasWysiwygEdit = !!(user && onChange);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const customAction = isCustomAction
    ? actionButtonPatterns?.[actionPattern]
    : undefined;

  const isEmail = button?.action === 'EMAIL';

  const isLinkDisabled = disableLink || isCustomAction;

  const LinkComponent =
    isEmail ||
    button?.action === 'LINK_EXTERNAL' ||
    button?.action === 'LINK_TO_BE' ||
    button?.target
      ? chakra.a
      : Link;

  const linkProps = useNavigationKustomHref(button?.actionString);

  if (!button || !button.label?.[currentLang]) return null;

  const labelData = button.label?.[currentLang];
  const label = labelData?.split('@')[0] || '';

  if (!labelData) return null;
  const extractedValues = extractValues(labelData, ['icon', 'style', 'class']);
  const icon = extractedValues.icon;
  const style = extractedValues.style;
  const className = extractedValues.class;
  const variantStyle = style === 'link' ? 'link' : variant ? variant : 'button';

  return (
    <>
      <WrapIf
        condition={!isLinkDisabled && !hasWysiwygEdit}
        wrapper={(children) => (
          <LinkComponent
            href={(isEmail ? 'mailto:' : '') + linkProps.href || ''}
            target={button.target}
          >
            {children}
          </LinkComponent>
        )}
      >
        <Box
          display="inline-block"
          {...getCSSAdminWysiwygItem({
            hasWysiwygEdit,
            isWysiwygEditOpen: isModalOpen,
            setIsOpen: setIsModalOpen,
          })}
        >
          <Button
            // @ts-ignore
            onClick={() => customAction?.(actionHref)}
            variant={variantStyle}
            {...buttonProps}
            {...(hasWysiwygEdit && {
              onClick: undefined,
            })}
            leftIcon={icon ? <Icon icon={icon} /> : undefined}
            className={className}
          >
            {/* <TranslatedString as="span" color="inherit">
              {button.label}
            </TranslatedString> */}
            {label}
          </Button>
        </Box>
      </WrapIf>
      {hasWysiwygEdit && (
        <EditActionButtonModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          button={button}
          onChange={onChange}
        />
      )}
    </>
  );
};

interface IconProps {
  icon: string;
}

const Icon = ({ icon }: IconProps) => {
  return <IconSvg icon={icon} size="1.5rem" />;
};

export default ActionButton;
