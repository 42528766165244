import { Box, Fade, IconButton, Portal, Text, chakra } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';

import MediasViewerContext from '@/contexts/MediasViewerContext';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import { Slide } from 'react-slideshow-image';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import { IconSvg } from './IconSvg';
import useDisablePageScroll from '@/hooks/useDisablePageScroll';

interface MediasViewerProps {}

const MediasViewer: React.FC<MediasViewerProps> = (props) => {
  const mediasViewerContext = useContext(MediasViewerContext);

  const deviceName = useResponsiveMediasDevice();
  const containerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(mediasViewerContext.index);

  useEffect(() => {
    setCurrentIndex(mediasViewerContext.index);
  }, [mediasViewerContext.index]);

  const getSize = () => {
    const newHeight = containerRef.current?.clientHeight;
    setHeight(newHeight || 0);
  };

  useEffect(() => {
    getSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediasViewerContext.medias, containerRef.current]);

  useEffect(() => {
    window.addEventListener('resize', getSize);
  }, []);

  const isOpen = !!mediasViewerContext.medias.length;

  const close = () => mediasViewerContext.setMedias([]);

  useDisablePageScroll(isOpen);

  const disableSlide = mediasViewerContext.medias.length <= 1;

  return (
    <Portal>
      <Fade
        in={isOpen}
        style={{
          position: 'relative',
          zIndex: '9999',
        }}
      >
        <Box
          display={isOpen ? 'block' : 'none'}
          zIndex={2}
          position="fixed"
          top={0}
          bottom={0}
          right={0}
          left={0}
          bgColor="rgba(0, 0, 0, 0.8)"
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              close();
            }
          }}
          p={[4, null, 24]}
        >
          <IconButton
            position="absolute"
            zIndex={2}
            bgColor="white"
            w={12}
            h={12}
            top={2}
            right={2}
            aria-label={'close'}
            onClick={close}
            color={'brand.500'}
          >
            <IconSvg icon="cross" size="1.5rem" />
          </IconButton>
          <Box
            position="relative"
            width={'100%'}
            height={'100%'}
            ref={containerRef}
          >
            <Box
              position="absolute"
              zIndex={4}
              bottom={[2, null, -10]}
              textAlign="center"
              left={0}
              right={0}
            >
              {!disableSlide && (
                <Text color="white">
                  {currentIndex + 1} | {mediasViewerContext.medias.length}
                </Text>
              )}
            </Box>
            {isOpen && (
              <Slide
                autoplay={false}
                transitionDuration={500}
                easing="ease"
                // infinite={!disableSlide}
                canSwipe={!disableSlide}
                defaultIndex={mediasViewerContext.index}
                onChange={(prevIndex, nextIndex) => setCurrentIndex(nextIndex)}
                prevArrow={
                  <Box
                    display={['none', null, !disableSlide ? 'flex' : 'none']}
                    w="60px"
                    h="60px"
                    alignItems="center"
                    justifyContent="center"
                    left={'-80px !important'}
                    bgColor="white"
                    borderRadius="base"
                    transition="opacity 0.3s ease"
                    color={'brand.500'}
                    _hover={{ opacity: 0.7 }}
                  >
                    {/* <chakra.span
                        fontSize="18px"
                        color="brand.500"
                        className="icon-diadao-arrow-left"
                        style={{
                          position: 'relative',
                          left: -2,
                        }}
                      /> */}
                    <IconSvg icon="left" size="1.5rem" />
                  </Box>
                }
                nextArrow={
                  <Box
                    display={['none', null, !disableSlide ? 'flex' : 'none']}
                    w="60px"
                    h="60px"
                    alignItems="center"
                    justifyContent="center"
                    right={'-80px !important'}
                    bgColor="white"
                    borderRadius="base"
                    transition="opacity 0.3s ease"
                    color={'brand.500'}
                    _hover={{ opacity: 0.7 }}
                  >
                    {/* <chakra.span
                        color="brand.500"
                        className="icon-diadao-arrow-right"
                        style={{
                          position: 'relative',
                          left: 1,
                        }}
                      /> */}
                    <IconSvg icon="right" size="1.5rem" />
                  </Box>
                }
              >
                {mediasViewerContext.medias.map((media, index) => (
                  <Box
                    height={height}
                    w="100%"
                    position="relative"
                    key={index}
                    userSelect={'none'}
                    sx={{
                      '*': {
                        userSelect: 'none',
                        pointerEvents: 'none',
                      },
                    }}
                  >
                    <ResponsiveMedias
                      key={index}
                      value={media}
                      currentDevice={deviceName}
                      noBackground
                      style={{
                        borderRadius: '4px',
                        objectFit: 'contain',
                        background: 'none !important',
                      }}
                      fill
                      width={undefined}
                      height={undefined}
                      skeletonColor={{
                        base: '#efd9c9',
                        highlight: '#F4E7DC',
                      }}
                    />
                  </Box>
                ))}
              </Slide>
            )}
          </Box>
        </Box>
      </Fade>
    </Portal>
  );
};

export default MediasViewer;
