import {
  Box,
  Button,
  Container,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  chakra,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  CarouselComponentData,
  KustomTranslatedString,
  TextImageComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import LanguageContext, {
  LanguageContextKey,
} from '@/lib/kustomcms-sdk/lib/contexts/language';
import React, { useContext } from 'react';

import Link from 'next/link';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useNavigationKustomHref from '@/lib/kustomcms-sdk/lib/hooks/useNavigationKustomHref';
import usePage from '@/lib/kustomcms-sdk/lib/hooks/usePage';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import { IconSvg } from './IconSvg';
import Image from 'next/image';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';

interface FooterProps {}

// const langs = [
//   { key: 'fr', label: 'Français' },
//   { key: 'en', label: 'English' },
//   { key: 'es', label: 'Spanish' },
// ];

const Footer: React.FC<FooterProps> = ({}) => {
  const { currentLang } = useContext(LanguageContext);

  const menuPage = usePage('hds5');

  const menu1Cmp = usePageComponent<CarouselComponentData>(
    menuPage,
    null,
    '16904561362299605',
  );

  const footerImgCmp = usePageComponent<TextImageComponentData>(
    menuPage,
    null,
    '17242450151674138',
  );
  const footerImg = footerImgCmp?.data?.media?.default;
  const footerImgAlt = footerImgCmp?.data?.text?.[currentLang];

  const menu = menu1Cmp?.data?.slides?.map((slide) => ({
    label: slide.title,
    href: slide.subtitle,
  }));

  return (
    <Box
      position="relative"
      zIndex="2"
      className="footer"
      display="flex"
      flexDir="column"
      alignItems="center"
      fontFamily="commutersSansLight"
      color="gray.500"
      pt={20}
      background={'brand.100'}
    >
      <Container
        maxW="1780px"
        display="flex"
        alignItems="center"
        flexDir="column"
        px={[6, null, 20]}
      >
        <Box width={'clamp(80px, 10vw, 180px)'}>
          <Image
            src={'/assets/img/logo/logo-2.svg'}
            width={155}
            height={200}
            alt=""
            style={{
              width: '100%',
            }}
          />
        </Box>
        {/* <Box
          mt={10}
          display="flex"
          w="100%"
          justifyContent="space-between"
          pt={6}
          pb={[16, null, null, null, 12]}
          alignItems="center"
          flexDir={['column', null, null, null, 'row']}
        > */}
        {/* <Box
            fontSize="18px"
            w={['100%', null, null, null, 'unset']}
            display="flex"
            flexDir={['column-reverse', null, 'row']}
            alignItems="center"
            justifyContent="space-between"
          >
            <Menu>
              <MenuButton
                fontSize="14px"
                px={5}
                as={Button}
                leftIcon={<IconLang />}
              >
                {langs.find((lang) => lang.key === language.currentLang)?.label}
              </MenuButton>
              <MenuList fontSize="14px" bgColor="brand.100">
                {langs.map((lang) => (
                  <MenuItem
                    bgColor="brand.100"
                    key={lang.key}
                    onClick={() => {
                      language.setCurrentLang(lang.key as LanguageContextKey);
                    }}
                  >
                    {lang.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <Text ml={4} mb={[8, null, 0]}>
              © 2023 Les Elmes Hotel & Spa
            </Text>
          </Box> */}

        {/* <Box mt={[16, null, null, null, 20]}>
          {langs.map((lang) => (
            <Button
              key={lang.key}
              onClick={() => {
                language.setCurrentLang(lang.key as LanguageContextKey);
              }}
            >
              {lang.label}
            </Button>
          ))}
        </Box> */}

        {/* <Box mt={[16, null, null, null, 20]}>
          <Link href="/" locale="fr">
            FR
          </Link>{' '}
          -{' '}
          <Link href="/" locale="en">
            EN
          </Link>{' '}
          -{' '}
          <Link href="/" locale="es">
            ES
          </Link>
        </Box> */}

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          color="gray.400"
          fontSize="0.875rem"
          gap="2rem"
          my={[16, null, null, null, 20]}
        >
          <Stack
            direction={['column', null, null, null, 'row']}
            gap={['0.5rem 1rem', null, null, null, '0.5rem 1.5rem']}
            justify="center"
            textAlign={['center', null, null, null, 'left']}
            flexWrap="wrap"
          >
            {menu?.map((item, index) => (
              <FooterNavItem key={index} {...item} />
            ))}
            <Link
              target="_blank"
              href="https://diadao.fr"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              <chakra.span mr={2}>Website by Diadao</chakra.span>{' '}
              <IconSvg icon="diadao" size="1rem" />
            </Link>
          </Stack>
          {footerImg?.url && (
            <Image
              alt="media"
              loading="lazy"
              src={footerImg.url}
              quality={80}
              style={{
                objectFit: 'cover',
                width: '75%',
                maxWidth: '600px',
              }}
              width={footerImg.metadata.width}
              height={footerImg.metadata.height}
              // sizes="(max-width: 959px) 70vw, (max-width: 1919px) 40vw, 800px"
              sizes={chakraResponsiveToImageSizes([
                '70vw',
                null,
                null,
                '40vw',
                null,
                '800px',
              ])}
            />
          )}

          <Box>© 2024 Maison Nova</Box>
        </Box>
        {/* </Box> */}
      </Container>
    </Box>
  );
};

interface FooterNavItemProps {
  label: KustomTranslatedString;
  href: KustomTranslatedString;
}

const FooterNavItem: React.FC<FooterNavItemProps> = ({ label, href }) => {
  const linkProps = useNavigationKustomHref(href);

  return (
    <Link {...linkProps} style={{ whiteSpace: 'nowrap' }}>
      <TranslatedString>{label}</TranslatedString>
    </Link>
  );
};

export default Footer;
