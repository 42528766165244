import {
  ActionButtonComponent,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  TextImageComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Heading } from '@chakra-ui/react';

import ActionButton from '@/components/atomics/ActionButton';
import AnimatePresence from '../atomics/AnimatePresence';
import { Parallax } from 'react-scroll-parallax';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import ContainerSection from '../ContainerSection';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import Section from '../Section';
import { SvgShape } from '../SvgShape';
import { ParallaxEl } from '../ParallaxEl';
import useIsMounted from '@/hooks/useIsMounted';
import { useRef } from 'react';
import { clamp, normalize } from '@/helpers/math';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';

const Section6 = ({
  component,
  page,
}: KustomPageComponentViewProps<TextImageComponentData>) => {
  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const textCpmId = component.data.atomicComponents?.findIndex(
    (cmp) => cmp.type === 'TEXT',
  );

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${textCpmId}.text`,
  );

  const buttonCpmId = component.data.atomicComponents?.findIndex(
    (cmp) => cmp.type === 'ACTION_BUTTON',
  );

  const atomActionButton1Bind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${buttonCpmId}`,
  );

  const mediaBind = useKustomData<KustomResponsiveMedia>(
    page,
    component,
    `data.media`,
  );

  const slide = {
    id: component.id,
    title: titleBind,
    text: textBind,
    media: mediaBind,
    button: atomActionButton1Bind,
  };

  const device = useResponsiveMediasDevice();
  const isMounted = useIsMounted();

  const backgroundImgEl = useRef<HTMLDivElement>(null);
  const shapeEl1 = useRef<HTMLDivElement>(null);
  const shapeEl2 = useRef<HTMLDivElement>(null);
  function fadeBackgroundImg(progress: number) {
    let opacity = 0;
    if (progress < 0.5) {
      opacity = clamp(normalize(progress, 0, 0.3), 0, 1);
    } else {
      opacity = clamp(normalize(0.5 - progress + 0.5, 0, 0.4), 0, 1);
    }
    if (progress > 0.99 || progress < 0.01) {
      backgroundImgEl.current?.style.setProperty('pointer-events', 'none');
    } else {
      backgroundImgEl.current?.style.setProperty('pointer-events', 'auto');
    }
    backgroundImgEl.current?.style.setProperty('opacity', String(opacity));
    shapeEl1.current?.style.setProperty('opacity', String(opacity));
    shapeEl2.current?.style.setProperty('opacity', String(opacity));
  }

  return (
    <>
      <Section color="brand.100" className={'section-6'}>
        {isMounted && (
          <Parallax
            onChange={(e) => fadeBackgroundImg(e.progress)}
            style={{
              width: '0.5rem',
              position: 'absolute',
              zIndex: 10000,
              left: 0,
              top: '30%',
              bottom: '30%',
            }}
          >
            <Box w="100%" h="100%" background="black" opacity={0}></Box>
          </Parallax>
        )}
        <ContainerSection>
          <Box
            ref={backgroundImgEl}
            position="fixed"
            zIndex="0"
            left="0"
            top="0"
            width="100vw"
            height="100vh"
            opacity={0}
            transition={'opacity 0.25s linear'}
            className="section-6-bg"
            backgroundColor="mint.900"
            pointerEvents={'none'}
          >
            <Box
              w="100%"
              h="100%"
              maxH="100svh"
              position="absolute"
              zIndex={2}
              left={0}
              top={0}
              background={'rgba(0,0,0,0.3)'}
              pointerEvents={'none'}
            />
            {slide.media.value && (
              <ResponsiveMedias
                {...slide.media}
                disableCSSAdminWysiwyg
                currentDevice={device}
                quality={50}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  background: 'var(--chakra-colors-mint-900)',
                }}
                fill
                width={undefined}
                height={undefined}
                sizes={chakraResponsiveToImageSizes([
                  '300vw',
                  null,
                  '200vw',
                  '100vw',
                ])}
                skeleton={false}
              />
            )}
          </Box>
          <Box
            ref={shapeEl1}
            bottom="0"
            right="0"
            position="absolute"
            zIndex="2"
            w={['90%', '70%', null, '60%']}
            pointerEvents={'none'}
          >
            <ParallaxEl speed={-15}>
              <SvgShape
                shape={3}
                dark
                style={{ transform: 'translate(44%, -10%) rotate(290deg)' }}
              />
            </ParallaxEl>
          </Box>
          <Box
            ref={shapeEl2}
            bottom="0"
            left="0"
            position="absolute"
            zIndex="2"
            w={['90%', '70%', null, '60%']}
            pointerEvents={'none'}
          >
            <ParallaxEl speed={18}>
              <SvgShape
                shape={3}
                dark
                style={{
                  transform: 'rotate(222deg) translate(62%, -67%)',
                }}
              />
            </ParallaxEl>
          </Box>
          <Box
            position="relative"
            zIndex={3}
            ml={['0', null, null, null, '16%']}
            mt={['16', null, null, '14', '24']}
            mb={['60%', '40%', null, '25%', '25%']}
          >
            <AnimatePresence opacity>
              <Heading
                as="h2"
                color="brand.100"
                w={['100%', null, null, '41%']}
              >
                <RichText {...slide.title} />
              </Heading>
              <RichText {...slide.text} w={['100%', null, null, '41%']} />
              <Box mt="clamp(2rem, 2.5vw, 3rem)">
                {slide.button && (
                  <ActionButton
                    textAlign="left"
                    variant="buttonLight"
                    {...slide.button}
                  />
                )}
              </Box>
            </AnimatePresence>
          </Box>
        </ContainerSection>
      </Section>
    </>
  );
};

export default Section6;
