import {
  ActionButtonComponent,
  CarouselComponentSlide,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Heading } from '@chakra-ui/react';

import ActionButton from '@/components/atomics/ActionButton';
import AnimatePresence from '../atomics/AnimatePresence';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import Slideshow from '../atomics/Slideshow';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useKustomDataMediasToSlidesTransformOptions from '@/lib/kustomcms-sdk/lib/helpers/kustomDataMediasToSlidesTransformOptions';
import ContainerSection from '../ContainerSection';
import Section from '../Section';
import { SvgShape } from '../SvgShape';
import { ParallaxEl } from '../ParallaxEl';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';

const Section1 = ({
  component,
  page,
}: KustomPageComponentViewProps<TextCarouselComponentData>) => {
  const slidesMediasBind = useKustomData<
    CarouselComponentSlide[],
    KustomResponsiveMedia[]
  >(page, component, `data.slides`, {
    ...useKustomDataMediasToSlidesTransformOptions,
  });

  const title1Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textTitle`,
  );
  const desc1Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );
  const atomActionButton1Id = component.data.atomicComponents?.findIndex(
    (cmp) => cmp.type === 'ACTION_BUTTON',
  );
  const atomActionButton1Bind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${atomActionButton1Id}`,
  );

  const slide = {
    id: component.id,
    titleBind: title1Bind,
    descriptionBind: desc1Bind,
    mediasBind: slidesMediasBind,
    actionButtonBind: atomActionButton1Bind,
  };

  return (
    <Section className={'section-3'}>
      <ContainerSection>
        <Box
          width={['80%', null, null, '52%']}
          right="0"
          bottom="0"
          position="absolute"
          pointerEvents={'none'}
        >
          <ParallaxEl speed={-5}>
            <SvgShape
              shape={3}
              style={{
                transform: 'translate(20%, 0)',
              }}
            />
          </ParallaxEl>
        </Box>
        <Box
          display={['block', null, null, 'flex']}
          justifyContent="space-between"
          // alignItems="start"
          alignItems="center"
          width={['100%', null, null, 'var(--grid-w-11)']}
          ml={['0', null, null, 'var(--grid-s-1)']}
        >
          <Box
            position="relative"
            zIndex={2}
            width={['100%', null, null, '37%']}
            // pt={['0', null, null, '12', '16']}
          >
            <AnimatePresence opacity>
              <Heading as="h2">
                <TranslatedString {...slide.titleBind} color="brand.500" />
              </Heading>
              <RichText {...slide.descriptionBind} />
              <Box mt="clamp(2rem, 2.5vw, 3rem)">
                {slide.actionButtonBind && (
                  <ActionButton
                    textAlign="left"
                    variant="link"
                    {...slide.actionButtonBind}
                  />
                )}
              </Box>
            </AnimatePresence>
          </Box>
          <Box
            width={['100%', null, null, '48%']}
            mt={['3rem', null, null, '0']}
            aspectRatio={'3 / 4'}
          >
            <ParallaxEl speed={5}>
              {slide.mediasBind.value && (
                <Slideshow
                  usingMediaViewer
                  value={slide.mediasBind.value}
                  onChange={slide.mediasBind.onChange}
                  hideArrows
                  hideCounter
                  canSwipe={true}
                  height={'100%'}
                  containerStyle={{
                    borderRadius: 'base',
                  }}
                  // sizes="(max-width: 959px) 95vw, (max-width: 1919px) 45vw, 920px"
                  sizes={chakraResponsiveToImageSizes([
                    '95vw',
                    null,
                    null,
                    '45vw',
                    null,
                    '920px',
                  ])}
                />
              )}
            </ParallaxEl>
          </Box>
        </Box>
      </ContainerSection>
    </Section>
  );
};

export default Section1;
