import { Box, IconButton, chakra } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { IconSvg } from './IconSvg';

interface BookingDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const BookingDrawer = (props: BookingDrawerProps) => {
  const { isOpen: _isOpen, onClose: _onClose } = props;

  const [isOpen, setIsOpen] = useState(_isOpen);

  useEffect(() => {
    setIsOpen(_isOpen);
  }, [_isOpen]);

  const onClose = () => {
    setIsOpen(false);
    _onClose();
  };

  return (
    <Box
      position={'fixed'}
      top={0}
      zIndex={'10000'}
      width={'100%'}
      height={'100%'}
      left={0}
      transform={isOpen ? 'translateX(0)' : 'translateX(100%)'}
      transition={isOpen ? 'none' : 'transform 0.1s linear 0.3s'}
    >
      <Box
        position={'absolute'}
        left={0}
        top={0}
        width={'100%'}
        height={'100%'}
        bg={'rgba(0, 0, 0, 0.3)'}
        opacity={isOpen ? 1 : 0}
        transition={'opacity 0.3s'}
        onClick={onClose}
      ></Box>

      <Box
        bgColor="brand.50"
        boxShadow={isOpen ? '-29px 0px 93px rgba(0,0,0,.05)' : 'none'}
        transform={isOpen ? 'translateX(0)' : 'translateX(100%)'}
        transition={
          isOpen ? 'transform 0.4s ease-out' : 'transform 0.25s ease-out'
        }
        maxWidth={['100%', null, '800px']}
        width={'100%'}
        height={'100%'}
        position={'absolute'}
        right={0}
        top={0}
      >
        <IconButton
          position="absolute"
          zIndex={3}
          shadow="sm"
          bgColor="brand.500"
          color="white"
          w={10}
          h={10}
          right={3}
          top={3}
          aria-label={'close'}
          onClick={onClose}
          fontSize="18px"
          _hover={{
            bgColor: 'brand.600',
            color: 'white',
          }}
        >
          <IconSvg icon="cross" />
        </IconButton>
        <Box
          height={'100%'}
          maxW={'100%'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={['flex-start', 'center']}
          overflowY={'auto'}
          overflowX={'hidden'}
          pt={[6, 0]}
        >
          <Box id="diadao-hsw-qs-booking" position={'relative'}></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingDrawer;
