import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import Image from 'next/image';
import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TestimonyComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ImgIgAccount from '@/../public/assets/img/logo_instagram.png';
import KustomString from '@/lib/kustomcms-sdk/lib/components/KustomString';
import Link from 'next/link';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useLightWidgetData from '@/hooks/useLightWidgetData';
// import { useSwipeable } from 'react-swipeable';
import ContainerSection from './ContainerSection';
import { IconSvg } from './IconSvg';
import Section from './Section';
import { ParallaxEl } from './ParallaxEl';
import { SvgShape } from './SvgShape';
import { useInView } from 'react-intersection-observer';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import { useSwipeable } from 'react-swipeable';

export interface IgPostsProps
  extends KustomPageComponentViewProps<TestimonyComponentData> {
  lightWidgetId: string;
  igUsername: string;
  igAccountLink: string;
}

const IgPosts: React.FC<IgPostsProps> = ({
  page,
  component,
  lightWidgetId,
}) => {
  const { settings } = useContext(SettingsContext);
  const networks = settings?.socialNetworks;

  const device = useResponsiveMediasDevice();

  const slides = component?.data?.slides;

  const [hasInteracted, setHasInteracted] = useState(false);

  const bpIndex = useBreakpointIndex();

  const [currentPostIndex, setCurrentPostIndex] = useState(0);

  const { posts: igPosts } = useLightWidgetData(lightWidgetId);

  const posts = useMemo(() => {
    return slides?.map((slide, index) => ({
      id: slide?.id,
      description: slide?.description,
      author: slide?.author,
      date: slide?.publishDate,
      mediaUrl: igPosts?.[index]?.mediaUrl,
      likes: igPosts?.[index]?.likes,
      isSlider: false,
    }));
  }, [slides, igPosts]);

  const next = useCallback(() => {
    if (currentPostIndex === posts?.length - 1) {
      setCurrentPostIndex(0);
    } else {
      setCurrentPostIndex(currentPostIndex + 1);
    }
  }, [currentPostIndex, posts?.length]);

  const prev = useCallback(() => {
    if (currentPostIndex === 0) {
      setCurrentPostIndex(posts?.length - 1);
    } else {
      setCurrentPostIndex(currentPostIndex - 1);
    }
  }, [currentPostIndex, posts?.length]);

  const [ref, inView, entry] = useInView({});

  useEffect(() => {
    if (!hasInteracted && inView) {
      const interval = setInterval(() => {
        next();
      }, 4000);
      return () => clearInterval(interval);
    }
  }, [hasInteracted, next, inView]);

  const userNext: MouseEventHandler<HTMLDivElement> = (e) => {
    setHasInteracted(true);
    // has clicked right or left to .smartphone-display
    const phoneDisplayX =
      document.querySelector('.smartphone-display')?.getBoundingClientRect()
        .x || 0;

    const textCardDisplayX =
      document.querySelector('.container-text-card')?.getBoundingClientRect()
        .x || 0;

    const hasClickedLeft =
      e.clientX < (device === 'default' ? phoneDisplayX : textCardDisplayX);

    if (hasClickedLeft) {
      prev();
    } else {
      next();
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  if (slides?.length < 3) return null;

  return (
    <Section className={'section-posts'} overflow={'hidden'}>
      <ContainerSection {...handlers}>
        <Box
          ref={ref}
          position={'relative'}
          onClick={userNext}
          aspectRatio={['6/5', null, null, 'inherit']}
          minH={['420px', null, null, 'auto']}
          maxW={'100%'}
          display={'flex'}
          justifyContent={['center', null, null, 'flex-start']}
        >
          <Box
            width={['70%', null, null, '43%']}
            left={['10%', null, null, '16%']}
            top="0"
            position="absolute"
            pointerEvents={'none'}
          >
            <ParallaxEl speed={-6}>
              <Box transform={'rotate(-24deg) translate(-22%, -50%)'}>
                <SvgShape shape={5} />
              </Box>
            </ParallaxEl>
          </Box>

          {/* SMARTPHONE DISPLAY */}

          <Box
            position={'relative'}
            display={['none', null, null, 'block']}
            className="smartphone-display"
            bgColor="white"
            boxShadow="0px 0px 50px rgba(0, 0, 0, 0.075)"
            borderRadius="xl"
            zIndex={2}
            w={'33%'}
            ml="16%"
          >
            <Link href={networks?.instagramUrl || '#'} target="_blank">
              <Box display="flex" alignItems="center" pb="3%" pt="10%" pl="3%">
                <Box w={'13%'}>
                  <Image src={ImgIgAccount} alt="instagram" loading="lazy" />
                </Box>
                <Text
                  ml={'3%'}
                  fontFamily="body"
                  color="black"
                  fontSize={'clamp(0.5rem, 1.3vw, 1.4rem)'}
                  fontWeight="bold"
                >
                  {networks?.instagramUsername || ''}
                </Text>
              </Box>
            </Link>
            <Box
              px="5%"
              pb="10%"
              // pt="4%"
              pt="1%"
              mt="95%"
            >
              <Box
                position="relative"
                display="flex"
                justifyContent="space-between"
                color="black"
              >
                <SimpleGrid columns={3} spacing={4}>
                  <IconSvg
                    icon="like"
                    size={[null, null, null, '1.675rem', '2.125rem']}
                  />
                  <IconSvg
                    icon="comment"
                    size={[null, null, null, '1.675rem', '2.125rem']}
                  />
                  <IconSvg
                    icon="send"
                    size={[null, null, null, '1.675rem', '2.125rem']}
                  />
                </SimpleGrid>
                <IconSvg
                  icon="mark"
                  size={[null, null, null, '1.675rem', '2.125rem']}
                />
              </Box>
              <Text
                fontFamily="body"
                color="black"
                fontSize={[null, null, null, '.8rem', '1rem']}
                fontWeight="bold"
                pt={2}
              >
                {posts?.[currentPostIndex]?.likes !== undefined &&
                posts?.[currentPostIndex]?.likes! > 0 ? (
                  <>{posts?.[currentPostIndex]?.likes} likes</>
                ) : null}
              </Text>
            </Box>
          </Box>

          {/* TEXT CARD */}

          <Box
            position="absolute"
            height="100%"
            zIndex={4}
            top="0"
            left={[null, null, null, '57%']}
            w={['60%', null, null, '33%']}
            minW={['260px', null, null, 'auto']}
            overflow={'visible'}
            className="container-text-card"
          >
            <Box
              h={`calc(100% * ${slides.length})`}
              transform={`translateY(calc( -100% / ${slides.length} * ${currentPostIndex} ))`}
              transition="transform 0.4s ease"
              display={['grid']}
              gridAutoRows={'1fr'}
            >
              {slides?.map((slide, index) => (
                <IgPostsSlide
                  key={slide.id}
                  page={page}
                  component={component}
                  isActiveSlide={index === currentPostIndex}
                  slideIndex={index}
                />
              ))}
            </Box>
          </Box>

          {/* IMG CARD */}

          <Box
            position={'absolute'}
            zIndex={3}
            width={'100%'}
            left={['min(calc((100% - 260px) / 2), 20%)', null, null, '15.9%']}
            top={['0%', null, null, '15%']}
            aspectRatio={['6/5', null, null, 'inherit']}
            minH={['420px', null, null, 'auto']}
            maxW={'100%'}
          >
            <Box
              display={'flex'}
              height={'100%'}
              alignItems={'center'}
              transform={[
                `translateX(calc((100% / ${
                  slides.length + 1
                } ) * -${currentPostIndex}))`,
                null,
                null,
                `translateX(calc( 100% / ${slides.length} * -${currentPostIndex}))`,
              ]}
              transition="transform 0.4s ease"
              w={[
                `calc(30% * ${slides.length - 1} + 60%)`,
                null,
                null,
                `calc(33% * ${slides.length})`,
              ]}
              minW={[
                `calc((130px) * ${slides.length - 1} + 260px)`,
                null,
                null,
                `auto`,
              ]}
            >
              {posts?.map((post, index) => (
                <Box
                  key={post.id}
                  position="relative"
                  aspectRatio={['3/4', null, null, '1/1']}
                  w={[
                    index === currentPostIndex ? '60%' : '30%',
                    null,
                    null,
                    '33%',
                  ]}
                  h={[
                    index === currentPostIndex ? '100%' : '60%',
                    null,
                    null,
                    '33%',
                  ]}
                  minW={[
                    index === currentPostIndex ? '260px' : '130px',
                    null,
                    null,
                    'auto',
                  ]}
                  minH={[
                    index === currentPostIndex ? '360px' : '100px',
                    null,
                    null,
                    'auto',
                  ]}
                  transition={'all 0.4s ease'}
                >
                  <Box
                    position={'relative'}
                    w={'92%'}
                    h={'92%'}
                    top={'4%'}
                    left={'4%'}
                    overflow={'hidden'}
                    borderRadius="lg"
                  >
                    {post.mediaUrl && (
                      <Image
                        alt="media"
                        loading="lazy"
                        src={post.mediaUrl}
                        quality={80}
                        fill
                        style={{
                          objectFit: 'cover',
                          transition: 'opacity 0.3s ease',
                          userSelect: 'none',
                          pointerEvents: 'none',
                          opacity:
                            bpIndex < 3 && index !== currentPostIndex
                              ? 1
                              : index === currentPostIndex && bpIndex > 2
                              ? 1
                              : 0.15,
                        }}
                        // sizes="(max-width: 959px) 70vw, (max-width: 1919px) 40vw, 800px"
                        sizes={chakraResponsiveToImageSizes([
                          '70vw',
                          null,
                          null,
                          '40vw',
                          null,
                          '800px',
                        ])}
                      />
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </ContainerSection>
    </Section>
  );
};

interface IgPostsSlideProps
  extends KustomPageComponentViewProps<TestimonyComponentData> {
  isActiveSlide: boolean;
  slideIndex: number;
}

const IgPostsSlide: React.FC<IgPostsSlideProps> = ({
  page,
  component,
  isActiveSlide,
  slideIndex,
}) => {
  const descBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.description`,
  );

  const authorBind = useKustomData<string>(
    page,
    component,
    `data.slides.${slideIndex}.author`,
  );

  const publishDateBind = useKustomData<string>(
    page,
    component,
    `data.slides.${slideIndex}.publishDate`,
  );

  return (
    <Box
      m="auto"
      display="flex"
      alignItems="center"
      justifyContent={['center']}
      position={'relative'}
      opacity={isActiveSlide ? 1 : 0}
      // pointerEvents={isActiveSlide ? 'auto' : 'none'}
      transition="opacity 0.2s ease"
      height={['100%', null, null, '75%']}
      pt={'0%'}
      width={'100%'}
    >
      <Box
        display="flex"
        flexDir="column"
        justifyContent="center"
        wordBreak="break-word"
        // fontSize={'clamp(1rem, 1.6vw, 1.625rem)'}
        fontSize={'clamp(1rem, 1.8vw, 1.625rem)'}
        w={['200px', '77%', null, '100%']}
      >
        {/* 
        <RichText {...descBind} fontFamily="basteleur" color="brand.500" />
        <Box
          display={['flex']}
          width={['100%']}
          position={[null, null, null, 'absolute']}
          mt={['5%']}
          bottom={0}
          gap={['5%']}
          fontSize={'0.75em'}
          fontFamily="commutersSansBold"
        >
          <KustomString {...authorBind} />
          <KustomString {...publishDateBind} />
        </Box> */}
        <RichText {...descBind} color={'gray.600'} />
        <Box
          display={['flex']}
          width={['100%']}
          position={[null, null, null, 'absolute']}
          mt={['5%']}
          bottom={0}
          gap={['5%']}
          fontSize={'0.75em'}
          fontFamily="commutersSansBold"
        >
          <KustomString color="brand.500" {...authorBind} />
          <KustomString color="brand.500" {...publishDateBind} />
        </Box>
      </Box>
    </Box>
  );
};

export default IgPosts;
