import { useEffect, useMemo, useRef } from 'react';

const useDisablePageScroll = (disableIf: boolean) => {
  const windowTypeof = typeof window;

  const customScrollWidth = 6; // 0.375rem

  const calcScrollWidth = () => {
    if (windowTypeof !== 'undefined') {
      const width = getScrollbarWidth();
      return width > 0 ? customScrollWidth : 0;
    }
    return 0;
  };

  useEffect(() => {
    const rootEl = document.querySelector<HTMLElement>(':root');

    if (disableIf) {
      const scrollWidth = calcScrollWidth();
      document.body.style.paddingRight = `${scrollWidth}px`;
      // document.body.style.overflowY = 'hidden';
      document.body.style.overflow = 'hidden';
      rootEl?.style.setProperty(
        '--display-page-scroll-shift',
        `${scrollWidth}px`,
      );
    } else {
      // document.body.style.overflowY = 'auto';
      document.body.style.overflow = 'auto';
      document.body.style.overflowX = 'hidden';
      document.body.style.paddingRight = '0px';
      rootEl?.style.setProperty('--display-page-scroll-shift', '0px');
    }
  }, [disableIf]);
};

function getScrollbarWidth() {
  // var inner = document.createElement('p');
  // inner.style.width = '100%';
  // inner.style.height = '200px';
  // inner.style.background = 'linear-gradient(#e66465, #9198e5)';

  // var outer = document.createElement('div');
  // outer.className = 'scrollbar-measure';
  // outer.style.position = 'fixed';
  // outer.style.zIndex = '200000';
  // outer.style.top = '400px';
  // outer.style.left = '0px';
  // outer.style.visibility = 'hidden';
  // outer.style.width = '200px';
  // outer.style.height = '150px';
  // outer.style.overflow = 'hidden';
  // outer.style.overflow = 'scroll';
  // outer.appendChild(inner);
  // document.body.appendChild(outer);
  // var w1 = inner.offsetWidth;
  // var w2 = inner.offsetWidth;
  // if (w1 == w2) w2 = outer.clientWidth;
  // document.body.removeChild(outer);

  const winH = window.innerWidth;
  const bodyH = document.body.clientWidth;

  return winH - bodyH;
}

export default useDisablePageScroll;
