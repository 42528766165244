import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import { Box, BoxProps, Heading } from '@chakra-ui/react';
import React, { useContext } from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import Section from '../Section';
import ContainerSection from '../ContainerSection';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';

export interface RoomTextSectionProps
  extends KustomPageComponentViewProps<TextComponentData> {
  containerProps?: BoxProps;
}

const RoomTextSection: React.FC<RoomTextSectionProps> = ({
  component,
  page,
}) => {
  const { currentLang } = useContext(LanguageContext);

  const titleBind = useKustomData(page, component, 'data.textTitle');

  const subtitleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textSubtitle`,
  );

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  return (
    <>
      <Section className={'section-text-medias'} pb={['8']}>
        <ContainerSection
          pt="0 !important"
          pb={['16', null, null, '24', '32', '44']}
        >
          <Box
            pl={[null, null, 'var(--grid-s-1)', 'var(--grid-s-3)']}
            pr={[null, null, 'var(--grid-s-1)', 'var(--grid-s-3)']}
          >
            <Heading
              as="h2"
              marginBottom={
                subtitleBind?.value?.[currentLang] !== '' ? '0.5rem' : undefined
              }
            >
              <RichText {...titleBind} />
            </Heading>
            {subtitleBind?.value?.[currentLang] !== '' ? (
              <Heading as="h3" variant="h5">
                <RichText {...subtitleBind} color="brand.500" />
              </Heading>
            ) : null}
          </Box>
          <RichText
            {...textBind}
            px={[null, null, 'var(--grid-s-2)', 'var(--grid-s-3)']}
          />
        </ContainerSection>
      </Section>
    </>
  );
};

export default RoomTextSection;
