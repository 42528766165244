import { Heading, chakra } from '@chakra-ui/react';
import {
  CodeComponent,
  KustomPage,
  KustomPageComponentViewProps,
  KustomTranslatedString,
  PressArticleComponentData,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useMemo } from 'react';

import Link from 'next/link';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useTranslatedString from '@/lib/kustomcms-sdk/lib/hooks/useTranslatedString';
import Section from '../Section';
import ContainerSection from '../ContainerSection';
import { ShapeComponent } from '../ShapeComponent';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

interface SiteMapSectionProps
  extends KustomPageComponentViewProps<TextComponentData> {
  filterPages?: (page: KustomPage) => boolean;
  firstSection?: boolean;
}

const SiteMapSection: React.FC<SiteMapSectionProps> = ({
  firstSection,
  filterPages = () => true,
  page,
  component,
}) => {
  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const { pages } = useContext(PagesContext);

  const validePages = useMemo(
    () => pages.filter(filterPages),
    [pages, filterPages],
  );

  const home = validePages.find((page) => page.pageCode === '4onpu');
  let updatedValidePages = [];
  if (home) {
    updatedValidePages = validePages.filter(
      (page) => page.pageCode !== '4onpu',
    );
    updatedValidePages.unshift(home);
  } else {
    updatedValidePages = validePages;
  }

  const code = component.data.atomicComponents.find(
    (cmp) => cmp.type === 'CODE',
  ) as CodeComponent;

  return (
    <>
      {updatedValidePages.length > 0 && (
        <Section>
          <ContainerSection pt={firstSection ? undefined : '0 !important'}>
            {code && <ShapeComponent code={code.code} />}
            <Heading
              as="h2"
              pl={[null, null, 'var(--grid-s-1)']}
              position="relative"
            >
              <RichText {...textBind} />
            </Heading>
            <chakra.ul listStyleType="none">
              {updatedValidePages.map((page) => (
                <SiteMapItem page={page} key={page._id} />
              ))}
            </chakra.ul>
          </ContainerSection>
        </Section>
      )}
    </>
  );
};

interface SiteMapItemProps {
  page: KustomPage;
}

const SiteMapItem: React.FC<SiteMapItemProps> = ({ page }) => {
  const href = useTranslatedString(page.prettyUrl || {});

  const pressHref = useMemo(() => {
    if (page.model !== 'pressarticle') return undefined;
    const data = page.components?.[0]?.data as
      | PressArticleComponentData
      | undefined;

    return data?.isExternalLink
      ? data?.pressArticleLink
      : data?.pressArticleMedia?.default?.url;
  }, []);

  if (page.excludeFromMenu) return null;

  return (
    <chakra.li my={1} pl={[null, null, 'var(--grid-s-1)', 'var(--grid-s-2)']}>
      <Link
        href={pressHref || href || '#'}
        target={pressHref ? '_blank' : undefined}
      >
        <TranslatedString
          transition="all 0.2s"
          _hover={{
            textDecoration: 'underline',
            color: 'accent.500',
          }}
        >
          {page.title}
        </TranslatedString>
      </Link>
    </chakra.li>
  );
};

export default SiteMapSection;
