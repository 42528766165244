import {
  ActionButtonComponent,
  CarouselComponentData,
  CarouselComponentSlide,
  KustomPage,
  KustomPageComponent,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Heading } from '@chakra-ui/react';

import ActionButton from '@/components/atomics/ActionButton';
import AnimatePresence from '../atomics/AnimatePresence';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import Slideshow from '../atomics/Slideshow';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useKustomDataMediasToSlidesTransformOptions from '@/lib/kustomcms-sdk/lib/helpers/kustomDataMediasToSlidesTransformOptions';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import { IconSvg } from '../IconSvg';
import ContainerSection from '../ContainerSection';
import Section from '../Section';
import { SvgShape } from '../SvgShape';
import { ParallaxEl } from '../ParallaxEl';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';

const Section2 = ({
  component,
  page,
}: KustomPageComponentViewProps<TextCarouselComponentData>) => {
  const slidesMediasBind = useKustomData<
    CarouselComponentSlide[],
    KustomResponsiveMedia[]
  >(page, component, `data.slides`, {
    ...useKustomDataMediasToSlidesTransformOptions,
  });

  const title1Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textTitle`,
  );
  const desc1Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );
  const atomActionButton1Id = component.data.atomicComponents?.findIndex(
    (cmp) => cmp.type === 'ACTION_BUTTON',
  );
  const atomActionButton1Bind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${atomActionButton1Id}`,
  );

  const slide = {
    id: component.id,
    titleBind: title1Bind,
    descriptionBind: desc1Bind,
    mediasBind: slidesMediasBind,
    actionButtonBind: atomActionButton1Bind,
  };

  const carrouselCmp = usePageComponent<CarouselComponentData>(
    page,
    null,
    '17102526656067306',
  );

  const slides = carrouselCmp?.data?.slides;

  return (
    <Section className={'section-2'}>
      <ContainerSection>
        <Box
          width={['90%', null, null, '60%']}
          left="0"
          bottom="0"
          position="absolute"
          pointerEvents={'none'}
        >
          <ParallaxEl speed={-4}>
            <SvgShape
              shape={1}
              style={{
                transform: 'translate(-22%, -15%)',
              }}
            />
          </ParallaxEl>
        </Box>
        <Box
          display="flex"
          flexDirection={['column', null, null, 'row']}
          justifyContent="space-between"
          // alignItems="start"
          alignItems="center"
        >
          <Box
            width={['100%', null, null, '57%']}
            mt={['3rem', null, null, '0']}
            aspectRatio={4 / 3}
          >
            <ParallaxEl speed={5}>
              {slide.mediasBind.value && (
                <Slideshow
                  usingMediaViewer
                  value={slide.mediasBind.value}
                  onChange={slide.mediasBind.onChange}
                  hideArrows
                  hideCounter
                  canSwipe={true}
                  height={'100%'}
                  containerStyle={{
                    borderRadius: 'base',
                  }}
                  // sizes="(max-width: 959px) 95vw, (max-width: 1919px) 45vw, 920px"
                  sizes={chakraResponsiveToImageSizes([
                    '95vw',
                    null,
                    null,
                    '45vw',
                    null,
                    '920px',
                  ])}
                />
              )}
            </ParallaxEl>
          </Box>
          <Box
            position="relative"
            zIndex={2}
            width={['100%', null, null, '35%']}
            order={['-1', null, null, '2']}
            // pt={['0', null, null, '12', '16']}
          >
            <AnimatePresence opacity>
              <Heading
                as="h2"
                // marginLeft={[null, null, null, '-43%']}
              >
                <TranslatedString {...slide.titleBind} color="brand.500" />
              </Heading>
              {slides && slides?.length > 0 && (
                <Box
                  display={'flex'}
                  gap="clamp(1rem, 2vw, 2.25rem)"
                  flexDirection="column"
                >
                  {slides?.map((slide, index) => (
                    <Slide
                      key={slide.id}
                      slide={slide}
                      page={page}
                      indexSlide={index}
                      component={carrouselCmp}
                    />
                  ))}
                </Box>
              )}
              {slide.actionButtonBind.value && (
                <Box mt="clamp(2rem, 3vw, 3rem)">
                  <ActionButton
                    textAlign="left"
                    variant="link"
                    {...slide.actionButtonBind}
                  />
                </Box>
              )}
            </AnimatePresence>
          </Box>
        </Box>
      </ContainerSection>
    </Section>
  );
};

export default Section2;

type SlideProps = {
  page: KustomPage;
  slide: CarouselComponentSlide;
  indexSlide: number;
  component: KustomPageComponent<any>;
};

const Slide = ({ page, slide, indexSlide, component }: SlideProps) => {
  const codeIndex = slide.atomicComponents.findIndex(
    (cmp) => cmp.type === 'CODE',
  );

  const codeBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${indexSlide}.atomicComponents.${codeIndex}.code`,
  );

  const textIndex = slide.atomicComponents.findIndex(
    (cmp) => cmp.type === 'TEXT',
  );

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${indexSlide}.atomicComponents.${textIndex}.text`,
  );

  return (
    <Box display={'flex'} gap="1.5rem" alignItems={'center'}>
      {codeBind.value && (
        <IconSvg
          icon={`${codeBind.value}`}
          size={['2.25rem', null, null, '2.5rem']}
          color="brand.500"
        />
      )}
      <RichText {...textBind} />
    </Box>
  );
};
