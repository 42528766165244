import React, { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';

import {
  KustomPageComponentViewProps,
  RoomBookingComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

interface RoomBookingProps
  extends KustomPageComponentViewProps<RoomBookingComponentData> {}

const RoomBooking: React.FC<RoomBookingProps> = ({ component }) => {
  const bookingId = component.data.systemData?.roomCode;
  const bookingContainer = useRef<HTMLDivElement>(null);
  const containerOrigin = useRef<ParentNode | null>(null);
  const hscContent = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!bookingId) return;

    const hscContentDiv = document.getElementById(
      `diadao-hsw-qs-roomstickydetail-${bookingId}`,
    );
    if (hscContentDiv) {
      hscContent.current = hscContentDiv;
    } else return;

    if (hscContent.current?.parentNode) {
      containerOrigin.current = hscContent.current.parentNode;
      bookingContainer.current?.appendChild(hscContent.current);
    }

    return () => {
      if (hscContent.current)
        containerOrigin.current?.appendChild(hscContent.current);
    };
  });

  return <Box ref={bookingContainer} mt={['0', null, null, '12', '0']}></Box>;
};

export default RoomBooking;
