import Router, { NextRouter, useRouter } from 'next/router';

import { KustomPage } from '@/lib/kustomcms-sdk/lib/types';
import { useEffect } from 'react';
import { LanguageContextKey } from '@/lib/kustomcms-sdk/lib/contexts/language';

const STORAGE_KEY_PREFIX = 'useScrollRestoration#';

function saveScrollPos(pageCode: string) {
  const scrollPos = { x: window.scrollX, y: window.scrollY };
  sessionStorage.setItem(
    STORAGE_KEY_PREFIX + pageCode,
    JSON.stringify(scrollPos),
  );
}

function restoreScrollPos(pageCode: string) {
  const string = sessionStorage.getItem(STORAGE_KEY_PREFIX + pageCode);

  if (!string) return;

  const scrollPos = JSON.parse(string);

  if (scrollPos) {
    window.scrollTo({ top: scrollPos.y });
    setTimeout(() => {
      window.scrollTo({ top: scrollPos.y });
    }, 10);
    removeScrollPosition(STORAGE_KEY_PREFIX + pageCode);
  }
}

function removeScrollPosition(key?: string) {
  window.scrollTo();
  if (!key) {
    for (const key in sessionStorage) {
      if (key.indexOf(STORAGE_KEY_PREFIX) === 0) {
        sessionStorage.removeItem(key);
      }
    }
    return;
  }

  sessionStorage.removeItem(key);
}

interface onRouteChangeArgs {
  url: string;
  removeScrollPosition: (url?: string) => void;
}

export default function useScrollRestoration(
  router: NextRouter,
  pages: KustomPage[] = [],
  onRouteChange: (args: onRouteChangeArgs) => void = () => {},
) {
  const { query, locale } = useRouter();
  const language = locale ? (locale as LanguageContextKey) : 'fr';
  const slug = ((query.slug || []) as string[])?.join('/');
  const path = `${language}/${slug}`;

  const page = path
    ? pages.find((p) => {
        return Object.values(p.prettyUrl).includes(path);
      })
    : undefined;

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';

      const onRouteChangeStart = () => {
        if (page) {
          saveScrollPos(page.pageCode || page.objectCode || '');
        }
      };

      const onRouteChangeComplete = (_path: string) => {
        const path = _path.slice(1);

        onRouteChange({ url: path, removeScrollPosition });
      };

      router.events.on('routeChangeStart', onRouteChangeStart);
      router.events.on('routeChangeComplete', onRouteChangeComplete);

      return () => {
        router.events.off('routeChangeStart', onRouteChangeStart);
        router.events.off('routeChangeComplete', onRouteChangeComplete);
      };
    }
  }, [router, onRouteChange, pages, page]);

  useEffect(() => {
    page && restoreScrollPos(page?.pageCode || page?.objectCode || '');
  }, [page]);
}
