import { motion } from 'framer-motion';

interface AnimatePresenceProps {
  x?: number;
  y?: number;
  opacity?: boolean;
  amount?: number;
  children: React.ReactNode;
  delay?: number;
  style?: React.CSSProperties;
}

const AnimatePresence = ({
  x,
  y,
  opacity,
  amount,
  delay,
  style,
  children,
}: AnimatePresenceProps) => {
  return (
    <motion.div
      style={style}
      initial={{
        x: x || 0,
        y: y || 0,
        opacity: opacity ? 0 : 1,
      }}
      whileInView={{
        x: 0,
        y: 0,
        opacity: 1,
      }}
      transition={{
        duration: 0.75,
        ease: 'easeOut',
        delay,
      }}
      viewport={{ once: true, amount: amount || 0.5 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatePresence;
