'use client';

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import {
  CodeComponent,
  KustomMedia,
  KustomPageComponent,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  MediasComponentData,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import MediasViewerContext from '@/contexts/MediasViewerContext';
import ParallaxElement from '../ParallaxElement';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useLazyAutoplay from '@/lib/kustomcms-sdk/lib/hooks/useLazyAutoplay';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import ContainerSection from '../ContainerSection';
import Section from '../Section';
import { ParallaxEl } from '../ParallaxEl';
import { SvgShape } from '../SvgShape';

const MasonryGrid = dynamic(() => import('./MasonryGrid').then((mod) => mod), {
  loading: () => <Box />,
  ssr: false,
});

const nColumns = Array(2).fill(0);

interface GallerySectionProps
  extends KustomPageComponentViewProps<TextComponentData> {}

const GallerySection: React.FC<GallerySectionProps> = ({ page }) => {
  const [currentGalleryIndex, setCurrentGalleryIndex] = useState(0);
  const [externalVideoViewer, setExternalVideoViewer] = useState('');

  const { labelsCmp, extraMedias, galleryCmps } = useMemo(() => {
    const labelsCmp = page.components
      .map((cmp) => (cmp.type === 'text' ? cmp : null))
      .filter(
        (cmp) => cmp !== null,
      ) as KustomPageComponent<TextComponentData>[];

    const extraMedias = page.components
      .map((cmp) =>
        cmp.type === 'text'
          ? (
              (cmp.data as TextComponentData).atomicComponents.filter(
                (acmp) => acmp.type === 'CODE',
              ) as CodeComponent[]
            ).map((acmp) => acmp.code)
          : null,
      )
      .filter((cmp) => !!cmp) as string[][];

    const galleryCmps = page.components.filter(
      (cmp) => cmp.type === 'medias',
    ) as KustomPageComponent<MediasComponentData>[];

    return {
      labelsCmp,
      extraMedias,
      galleryCmps,
    };
  }, [page]);

  const goToAnchor = (index: number) => {
    const anchor = document.getElementById('anchor-gallery-' + index);

    if (anchor) {
      window.scrollTo({
        behavior: 'smooth',
        top:
          anchor.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          110,
      });
    }
  };

  const lazyAutoplayBind = useLazyAutoplay();

  return (
    <Section className={'gallery'} overflow="clip">
      <ContainerSection
        display={['block', null, null, null, 'flex']}
        alignItems={['center', null, null, null, 'flex-start']}
        gap={[null, null, null, null, 16, 24]}
      >
        <Box
          width={['80%', null, null, '50%']}
          right="0"
          top="0"
          position="absolute"
          pointerEvents={'none'}
        >
          <ParallaxEl speed={-8}>
            <SvgShape
              shape={3}
              style={{
                transform: 'translate(20%, 15%)',
              }}
            />
          </ParallaxEl>
        </Box>
        <Box
          position={['relative', null, null, null, 'sticky']}
          top={[null, null, null, null, 'calc(clamp(1rem, 6vw, 7rem) + 4rem)']}
          pb={8}
          pt={[null, null, null, null, 8]}
          display="flex"
          flexDir={['column', null, null, 'row', 'column']}
          justifyContent={'center'}
          alignItems={['center', null, null, 'flex-start']}
          gap={[2, null, null, 6, 4, 6]}
        >
          {labelsCmp.map((labelCmp, index) => (
            <GallerySectionTitle
              key={labelCmp.id}
              page={page}
              component={labelCmp}
              onClick={() => goToAnchor(index)}
              isCurrentGallery={index === currentGalleryIndex}
            />
          ))}
        </Box>

        <Box position="relative" flexGrow={1}>
          <MasonryGrid
            columnsClassName="masonryGrid-col"
            itemsClassName="masonryGrid-item"
          >
            {nColumns.map((_, index) => (
              <Box
                key={'col-' + index}
                className="masonryGrid-col"
                mr={
                  index !== nColumns.length - 1
                    ? [0, 4, null, null, null, 6]
                    : 0
                }
                display={['block', ['none', null, null, 'block']][index]}
                w={['100%', null, null, '50%']}
              />
            ))}
            {galleryCmps.map((cmp, galleryIndex) => {
              const medias = cmp.data.medias;

              const _extraMedias = extraMedias[galleryIndex];

              return (
                <React.Fragment key={galleryIndex}>
                  {_extraMedias?.map((mediaUrl, index) => (
                    <GalleryItem
                      key={mediaUrl + galleryIndex + index}
                      mediaUrl={mediaUrl}
                      galleryMedias={medias}
                      galleryIndex={galleryIndex}
                      index={index}
                      setCurrentGalleryIndex={setCurrentGalleryIndex}
                      setExternalVideoViewer={setExternalVideoViewer}
                    />
                  ))}
                  {medias.map((media, index) => (
                    <GalleryItem
                      key={media.id + galleryIndex + index}
                      media={media}
                      galleryMedias={medias}
                      galleryIndex={galleryIndex}
                      index={index + (_extraMedias?.length || 0)}
                      mediasIndex={index}
                      setCurrentGalleryIndex={setCurrentGalleryIndex}
                    />
                  ))}
                </React.Fragment>
              );
            })}
          </MasonryGrid>
          <Modal
            isOpen={!!externalVideoViewer}
            onClose={() => setExternalVideoViewer('')}
            size="full"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton
                zIndex={3}
                bgColor="white"
                color="brand.500"
                size="lg"
                right={1.5}
              />
              <ModalBody p={0} position="relative">
                <video
                  {...lazyAutoplayBind}
                  loop
                  controls
                  style={{
                    height: '100%',
                    objectFit: 'cover',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <source src={externalVideoViewer} type="video/mp4" />
                </video>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      </ContainerSection>
    </Section>
  );
};

interface GalleryItemProps {
  media?: KustomResponsiveMedia;
  mediaUrl?: string;
  galleryMedias: KustomResponsiveMedia[];
  galleryIndex: number;
  mediasIndex?: number;
  index: number;
  setCurrentGalleryIndex: (index: number) => void;
  setExternalVideoViewer?: (url: string) => void;
}

const GalleryItem: React.FC<GalleryItemProps> = (props) => {
  const {
    media,
    mediaUrl,
    galleryMedias,
    setCurrentGalleryIndex,
    setExternalVideoViewer,
    galleryIndex,
    mediasIndex,
    index,
  } = props;

  const device = useResponsiveMediasDevice();
  const mediasViewer = useContext(MediasViewerContext);

  const currentMedia = (media?.[device as keyof KustomResponsiveMedia] ||
    media?.default) as KustomMedia | undefined;

  const { ref, inView } = useInView({});

  useEffect(() => {
    if (inView) {
      setCurrentGalleryIndex(galleryIndex);
    }
  }, [inView]);

  const ratio = useMemo(() => {
    const w = +(currentMedia?.metadata.width || 0);
    const h = +(currentMedia?.metadata.height || 0);

    return w > h ? '16 / 9' : w < h ? '9 / 16' : '1 / 1';
  }, []);

  const lazyAutoplayBind = useLazyAutoplay();

  return (
    <Box
      ref={ref}
      id={index === 0 ? 'anchor-gallery-' + galleryIndex : undefined}
      className={'masonryGrid-item masonryGrid-item' + (index + 1)}
      data-item-height-value={currentMedia?.metadata.height || '200'}
      position="relative"
      borderRadius="base"
      overflow="clip"
      mb={[4, null, null, 4, null, 6]}
      onClick={
        media
          ? () => {
              mediasViewer.setMedias(galleryMedias);
              mediasViewer.setIndex(mediasIndex || 0);
            }
          : () => setExternalVideoViewer?.(mediaUrl || '')
      }
    >
      <Box
        cursor="pointer"
        transition="transform 0.3s"
        role="group"
        _hover={{
          transform: 'scale(1.2)',
        }}
        aspectRatio={ratio}
        width="100%"
        sx={{}}
        overflow="clip"
      >
        {currentMedia ? (
          <ResponsiveMedias
            value={media!}
            currentDevice={device}
            fill
            sizes={chakraResponsiveToImageSizes(['100vw', null, null, '45vw'])}
            width={undefined}
            height={undefined}
            skeletonColor={{
              base: '#efd9c9',
              highlight: '#F4E7DC',
            }}
          />
        ) : (
          <video
            {...lazyAutoplayBind}
            loop
            muted
            playsInline
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          >
            <source src={mediaUrl} type="video/mp4" />
          </video>
        )}
      </Box>
    </Box>
  );
};

interface GallerySectionTitleProps
  extends KustomPageComponentViewProps<TextComponentData> {
  isCurrentGallery: boolean;
  onClick: () => void;
}

const GallerySectionTitle: React.FC<GallerySectionTitleProps> = ({
  page,
  component,
  onClick,
  isCurrentGallery,
}) => {
  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    'data.text',
  );

  return (
    <RichText
      // fontFamily="commutersSansBold"
      fontFamily="Basteleur"
      color="brand.500"
      fontSize={['1.5rem', null, null, null, '1.6rem', '2rem']}
      transition="color 0.2s"
      {...(isCurrentGallery && {
        color: 'brand.600',
      })}
      _groupHover={{
        color: 'brand.600',
      }}
      {...titleBind}
      onClick={onClick}
      cursor="pointer"
    />
  );
};

export default GallerySection;
