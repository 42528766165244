import { Box, Container, Heading } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import Section from '../Section';
import ContainerSection from '../ContainerSection';
import { ParallaxEl } from '../ParallaxEl';
import { SvgShape } from '../SvgShape';

// import EventsForm from './EventsForm';
import dynamic from 'next/dynamic';
const DynamicEventsForm = dynamic(() => import('./EventsForm'), {
  loading: () => <p>Loading...</p>,
});

interface EventsFormSectionProps
  extends KustomPageComponentViewProps<TextComponentData> {}

const EventsFormSection: React.FC<EventsFormSectionProps> = ({
  page,
  component,
}) => {
  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  return (
    <Section className={'events-form'}>
      <ContainerSection>
        <Box
          width={['50%', null, null, '35%']}
          right="0"
          bottom={'0'}
          position="absolute"
          pointerEvents={'none'}
        >
          <ParallaxEl speed={-6}>
            <Box transform={['translate(-30%, -10%) rotate(210deg)']}>
              <SvgShape shape={4} />
            </Box>
          </ParallaxEl>
        </Box>

        <Heading as="h2">
          <RichText {...textBind} />
        </Heading>
        <Box
          width={['100%', null, null, 'var(--grid-w-9)']}
          pl={['0', null, null, 'var(--grid-s-1)']}
        >
          {/* <EventsForm /> */}
          <DynamicEventsForm page={page} component={component} />
        </Box>
      </ContainerSection>
    </Section>
  );
};

export default EventsFormSection;
