import { ChakraProps, Box } from '@chakra-ui/react';

type SectionProp = ChakraProps & {
  children: React.ReactNode;
  debug?: boolean;
  ref?: React.ForwardedRef<HTMLDivElement>;
  className?: string;
  id?: string;
};

const Section = ({
  children,
  debug = false,
  ref,
  id,
  className = '',
  ...props
}: SectionProp) => {
  return (
    <Box
      className={`section ${className}`}
      position="relative"
      overflow="visible"
      maxW="100%"
      border={debug ? '2px solid gray' : 'none'}
      {...props}
      ref={ref}
      id={id}
      sx={{
        a: { textDecoration: 'underline' },
      }}
    >
      {children}
    </Box>
  );
};

export default Section;
