import {
  Box,
  BoxProps,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  SlideFade,
  Text,
  chakra,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';

import Link from 'next/link';
import PersistantModal from './atomics/PersistantModal';
import ReservationRouteContext from '@/contexts/ReservationRoute';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import WrapIf from './atomics/WrapIf';
// import useMewsButton from '@/hooks/useMews';
import { IconSvg } from './IconSvg';

// import dynamic from 'next/dynamic';
import { useKustomTranslation } from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import { ContactDiadao } from './ContactDiadao';
import useNavigationKustomHref from '@/lib/kustomcms-sdk/lib/hooks/useNavigationKustomHref';
import BookingDrawer from './BookingDrawer';
// const DynamicPxier = dynamic(() => import('./Pxier'), {
//   loading: () => <p>Loading...</p>,
// });

interface ReservationDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const ReservationDrawer = ({
  isOpen: _isOpen,
  onClose: _onClose,
}: ReservationDrawerProps) => {
  const {
    route,
    setRoute: _setRoute,
    modalRoute,
    setModalRoute,
  } = useContext(ReservationRouteContext);

  const [isOpen, setIsOpen] = useState(_isOpen);

  const { currentLang } = useContext(LanguageContext);

  const items: {
    icon: string;
    label?: string;
    href?: string;
    sidebarHref?: string;
    sidebarModalHref?: string;
    isExternalLink?: boolean;
    isButton?: boolean;
    className?: string;
    onClick?: () => void;
  }[] = [];

  const stay = useKustomTranslation('resa_stay')?.value?.[currentLang];
  const table = useKustomTranslation('resa_table')?.value?.[currentLang];
  const care = useKustomTranslation('resa_care')?.value?.[currentLang];
  const deckchair =
    useKustomTranslation('resa_deckchair')?.value?.[currentLang];
  const boat = useKustomTranslation('resa_boat')?.value?.[currentLang];
  const event = useKustomTranslation('resa_event')?.value?.[currentLang];
  const gift_box = useKustomTranslation('resa_gift_box')?.value?.[currentLang];
  // const gift_card =
  //   useKustomTranslation('resa_gift_card')?.value?.[currentLang];
  const littorine =
    useKustomTranslation('resa_littorine')?.value?.[currentLang];
  const casot = useKustomTranslation('resa_casot')?.value?.[currentLang];

  const settingsCtx = useContext(SettingsContext);

  // const buttonProps = useMewsButton();

  const bookingUrl = settingsCtx?.settings?.bookingEngine?.enginePublicLink;
  // if (buttonProps)
  // if (bookingUrl)
  //   items.push({
  //     icon: 'bed',
  //     label: stay,
  //     isButton: true,
  //     // href: '#reservation',
  //     // ...buttonProps,
  //     href: bookingUrl,
  //     isExternalLink: true,
  //   });
  items.push({
    icon: 'bed',
    label: stay,
    isButton: true,
    sidebarModalHref: '/reservation',
    isExternalLink: false,
  });

  const restaurantLittorineUrl =
    settingsCtx?.settings?.modules?.bookingRestaurantWidgetUrl;

  const restaurantCasotUrl =
    settingsCtx?.settings?.modules?.extraFields?.['widgetRestaurantCasotUrl']
      ?.value;

  if (restaurantLittorineUrl || restaurantCasotUrl)
    items.push({
      icon: 'meal',
      label: table,
      sidebarHref: '/restaurant',
      isExternalLink: false,
    });

  // items.push({
  //   icon: 'spa',
  //   // label: 'Soin',
  //   label: care,
  //   sidebarModalHref: '/spa',
  //   isExternalLink: false,
  // });

  // const urlPxier =
  //   settingsCtx?.settings?.bookingEngine?.extraFields?.['url_pxier']?.value;

  items.push({
    icon: 'spa',
    // label: 'Soin',
    label: care,
    sidebarModalHref: '/care',
    isExternalLink: true,
  });

  const deckchairUrl =
    settingsCtx?.settings?.modules?.extraFields?.['widgetDeckchairUrl']?.value;

  if (deckchairUrl)
    items.push({
      icon: 'umbrella',
      // label: 'Transats',
      label: deckchair,
      sidebarModalHref: '/deckchair',
      isExternalLink: false,
    });

  const boatUrl =
    settingsCtx?.settings?.modules?.extraFields?.['widgetBoatUrl']?.value;
  if (boatUrl)
    items.push({
      icon: 'boat',
      // label: 'Bateau',
      label: boat,
      href: boatUrl,
      isExternalLink: true,
    });

  const eventsUrlData =
    settingsCtx?.settings?.modules?.extraFields?.['widgetEventsUrl']?.value;
  const eventsUrl = useNavigationKustomHref(eventsUrlData);

  if (eventsUrl) {
    items.push({
      icon: 'event',
      // label: 'Événement & Privatisation',
      label: event,
      // sidebarModalHref: '/events',
      href: eventsUrl.href,
      isExternalLink: false,
    });
  }

  const giftUrl =
    settingsCtx?.settings?.modules?.extraFields?.['widgetGiftUrl']?.value;
  if (giftUrl)
    items.push({
      icon: 'gift',
      // label: 'Coffret cadeau',
      label: gift_box,
      href: giftUrl,
      isExternalLink: true,
    });

  useEffect(() => {
    setIsOpen(_isOpen);
  }, [_isOpen]);

  const [isModalLoading, setIsModalLoading] = useState(false);

  const setRoute = (value: string, isModal?: boolean) => {
    if (isModal) {
      setModalRoute(value);
    } else {
      _setRoute(value);
    }
  };

  const onClose = () => {
    setIsOpen(false);
    setRoute('');
    _onClose();
  };

  useEffect(() => {
    if (isOpen && !route) {
      setRoute('/');
    }
  }, [isOpen, modalRoute]);

  useEffect(() => {
    if (route) {
      setIsOpen(true);
    }
  }, [route]);

  const modalRoutes = [
    // '/events',
    '/restaurant-littorine',
    '/restaurant-casot',
    '/care',
  ];
  useEffect(() => {
    if (modalRoutes.includes(modalRoute)) {
      setIsModalLoading(true);
    }
  }, [modalRoute]);

  const openDrawerFromHSP = (e: Event) => {
    if (!isOpen && (e as CustomEvent).detail === 'prix') setIsOpen(true);
  };

  useEffect(() => {
    window.addEventListener('diadaoBooking', (e) => openDrawerFromHSP(e));
    return () => {
      window.removeEventListener('diadaoBooking', (e) => openDrawerFromHSP(e));
    };
  }, []);

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        blockScrollOnMount={false}
        trapFocus={false}
      >
        <DrawerOverlay onClick={() => {}} />
        <DrawerContent
          maxWidth={['100%', null, '360px', '400px', '420px']}
          overflowY="auto"
          minH="100svh"
          bgColor="brand.100"
          boxShadow={isOpen ? '-29px 0px 93px rgba(0,0,0,.05)' : 'none'}
        >
          <Box minH="100%">
            {route !== '/' && (
              <IconButton
                aria-label="Back"
                fontSize={['14px']}
                bgColor="transparent !important"
                color="brand.500"
                _hover={{
                  color: 'brand.600',
                }}
                p={2}
                py={4}
                m={2}
                icon={<IconSvg icon="left" size="1.25rem" />}
                position="absolute"
                top={1}
                left={1}
                onClick={() => setRoute('/')}
              />
            )}
            <IconButton
              aria-label="Close"
              p={2}
              py={4}
              m={2}
              bgColor="transparent"
              color="brand.500"
              _hover={{
                backgroundColor: 'brand.200',
              }}
              icon={<IconSvg icon="cross" size="1.5rem" />}
              position="absolute"
              top={1}
              right={1}
              onClick={onClose}
            />
            {
              {
                '/': (
                  <MenuLayout>
                    <SlideFade offsetX="30px" offsetY={0} in key="1">
                      <Flex flexDir="column" py={8}>
                        {items.map((item, index) => (
                          <MenuItem
                            key={index}
                            {...item}
                            setRoute={setRoute}
                            index={index}
                            onClose={onClose}
                          />
                        ))}
                      </Flex>
                    </SlideFade>
                  </MenuLayout>
                ),
                '/restaurant': (
                  <MenuLayout>
                    <SlideFade offsetX="30px" in key="2" offsetY={0}>
                      <Flex flexDir="column" py={8}>
                        <MenuItem icon="meal" label={table} />
                        <MenuItem
                          // label="La Littorine"
                          label={littorine}
                          sidebarModalHref="/restaurant-littorine"
                          setRoute={setRoute}
                        />
                        <MenuItem
                          // label="Le Casot"
                          label={casot}
                          index={1}
                          sidebarModalHref="/restaurant-casot"
                          setRoute={setRoute}
                        />
                      </Flex>
                    </SlideFade>
                  </MenuLayout>
                ),
              }[route]
            }
          </Box>
        </DrawerContent>
      </Drawer>
      <BookingDrawer
        isOpen={modalRoute === '/reservation'}
        // isOpen={true}
        onClose={() => setModalRoute('')}
      />
      {modalRoutes.includes(modalRoute) && (
        <PersistantModal
          isOpen={!!modalRoute}
          isLoading={isModalLoading}
          onClose={() => setModalRoute('')}
          modalStyle={{
            maxW: {
              '/spa': ['100%', null, '1500px'],
              // '/events': ['100%', null, '90%'],
              // '/restaurant-littorine': ['100%', null, '600px'],
              // '/restaurant-casot': ['100%', null, '600px'],
              '/deckchair': ['100%', null, '600px'],
              '/care': ['100%', null, '1000px'],
            }[modalRoute],
            maxH: {
              // '/restaurant-littorine': ['100%', null, '630px'],
              // '/restaurant-casot': ['100%', null, '630px'],
              '/deckchair': ['100%', null, '630px'],
            }[modalRoute],
            // my: [0, null, 16],
          }}
        >
          {/* <Box display={modalRoute === '/spa' ? 'block' : 'none'} h="100%">
          {modalRoute === '/spa' && (
            <DynamicPxier
              onLoad={() => {
                setIsModalLoading(false);
              }}
              onLoading={() => {
                setIsModalLoading(true);
              }}
            />
          )}
        </Box> */}
          {modalRoute === '/restaurant-littorine' && (
            <chakra.iframe
              src={restaurantLittorineUrl}
              width="100%"
              height="100%"
              opacity={isModalLoading ? 0 : 1}
              transition="opacity 0.3s"
              onLoad={() => {
                setIsModalLoading(false);
              }}
            ></chakra.iframe>
          )}
          {modalRoute === '/restaurant-casot' && (
            <chakra.iframe
              src={restaurantCasotUrl}
              width="100%"
              height="100%"
              opacity={isModalLoading ? 0 : 1}
              transition="opacity 0.3s"
              onLoad={() => {
                setIsModalLoading(false);
              }}
            ></chakra.iframe>
          )}
          {/* {modalRoute === '/restaurant-plage' && (
          <chakra.iframe
            src={restaurantPlageUrl}
            width="100%"
            height="100%"
            opacity={isModalLoading ? 0 : 1}
            transition="opacity 0.3s"
            onLoad={() => {
              setIsModalLoading(false);
            }}
          ></chakra.iframe>
        )} */}
          {modalRoute === '/deckchair' && (
            <chakra.iframe
              src={deckchairUrl}
              width="100%"
              height="100%"
              opacity={isModalLoading ? 0 : 1}
              transition="opacity 0.3s"
              onLoad={() => {
                setIsModalLoading(false);
              }}
            ></chakra.iframe>
          )}
          {/* {modalRoute === '/events' && (
          <chakra.iframe
            key="events-iframe"
            w="100%"
            h="100%"
            opacity={isModalLoading ? 0 : 1}
            transition="opacity 0.3s"
            src={eventsUrl}
            onLoad={() => {
              setIsModalLoading(false);
            }}
          />
        )} */}
          {modalRoute === '/care' && (
            <chakra.iframe
              src={'/iframe/planity.html'}
              key="events-iframe"
              w="100%"
              h="100%"
              opacity={isModalLoading ? 0 : 1}
              transition="opacity 0.3s"
              onLoad={() => {
                setIsModalLoading(false);
              }}
            />
          )}
        </PersistantModal>
      )}
    </>
  );
};

const MenuLayout = ({ children }: BoxProps) => {
  const { currentLang } = useContext(LanguageContext);
  const title = useKustomTranslation('resa_title')?.value?.[currentLang];

  return (
    <Box
      px={[5, null, null, 8, null, 10]}
      pt={16}
      pb={10}
      display="flex"
      flexDir="column"
      minH="100%"
    >
      <Heading as="h2" fontSize="1.5rem">
        {/* Votre réservation à La Maison Nova */}
        {title}
      </Heading>

      <Box flexGrow={1}>{children}</Box>

      <ContactDiadao />
    </Box>
  );
};

interface MenuItemProps {
  icon?: string;
  label?: string;
  href?: string;
  onClick?: () => void;
  className?: string;
  sidebarHref?: string;
  sidebarModalHref?: string;
  isExternalLink?: boolean;
  setRoute?: (route: string, isModal?: boolean) => void;
  index?: number;
  isButton?: boolean;
  onClose?: () => void;
}

const MenuItem = ({
  icon,
  label,
  href,
  onClick,
  className,
  setRoute,
  sidebarHref,
  sidebarModalHref,
  isExternalLink = false,
  index,
  onClose,
}: MenuItemProps) => {
  const isInteractible = !!href || !!sidebarHref || !!sidebarModalHref;
  return (
    <WrapIf
      condition={!!href}
      key={label}
      wrapper={(c) => (
        <Link
          href={href!}
          target={isExternalLink ? '_blank' : undefined}
          onClick={onClose}
        >
          {c}
        </Link>
      )}
    >
      <Box
        key={label}
        display="flex"
        alignItems="center"
        className={className}
        py={4}
        color="gray.500"
        cursor={isInteractible ? 'pointer' : 'default'}
        borderTop={!index ? 'none' : '1px solid'}
        borderColor="brand.300"
        // _hover={
        //   isInteractible
        //     ? {
        //         bgColor: '#f8f8f8',
        //         borderRadius: 'md',
        //       }
        //     : {}
        // }
        onClick={
          onClick ||
          (() => {
            if (sidebarHref) {
              setRoute?.(sidebarHref);
            }
            if (sidebarModalHref) {
              setRoute?.(sidebarModalHref, true);
            }
          })
        }
        position="relative"
        role="group"
      >
        {icon && <IconSvg icon={icon} size="1.5rem" />}
        <Text
          fontFamily="commutersSansBold"
          fontSize="1rem"
          color="gray.500"
          pl={icon ? 4 : 0}
        >
          {label}
        </Text>
        {isInteractible && (
          <>
            <IconSvg
              icon={isExternalLink ? 'link' : 'right'}
              size="1rem"
              position={'absolute'}
              right="0"
              transition="transform 0.2s ease"
              _groupHover={{
                transform: 'translate(7px, 0)',
              }}
            />
          </>
        )}
      </Box>
    </WrapIf>
  );
};

export default ReservationDrawer;
