import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import useNavigationKustomHref from '@/lib/kustomcms-sdk/lib/hooks/useNavigationKustomHref';
import { News, Offer } from '@/lib/kustomcms-sdk/lib/types';
import {
  Box,
  Button,
  Container,
  Heading,
  IconButton,
  Text,
  chakra,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { IconSvg } from '../IconSvg';

const skeletonColor = {
  base: '#f7eee0',
  highlight: '#fcf3f0',
};
const quality = 80;

interface OffersAndNewsDetailsProps {
  offersAndNews: News | Offer;
  onClose: () => void;
}

// const labelType = ['OFFER', 'BOOK', 'DISCOVER', 'TAKE', 'BUY'];
// const labelTheme = [
//   'LAST_MINUTE',
//   'EARLY_BOOKING',
//   'PROMOTION',
//   'PACKAGE',
//   'GIFT_BOX',
// ];

const OffersAndNewsDetails: React.FC<OffersAndNewsDetailsProps> = ({
  offersAndNews,
  onClose,
}) => {
  const router = useRouter();
  const { currentLang } = useContext(LanguageContext);
  const settingsCtx = useContext(SettingsContext);

  const media = offersAndNews.medias?.[0];
  const [isLoaded, setIsLoaded] = useState(false);

  const getTranslation = (key: string) => {
    return settingsCtx.translations?.[`offers_${key}`]?.[currentLang];
  };

  const textButton = offersAndNews?.buttonLabel
    ? getTranslation(offersAndNews?.buttonLabel)
      ? getTranslation(offersAndNews?.buttonLabel)
      : offersAndNews?.buttonLabel
    : null;

  const offer: Offer | null =
    offersAndNews.objectType === 'offers' ? (offersAndNews as Offer) : null;

  const offerTheme: string | null =
    offersAndNews.objectType === 'offers'
      ? (offersAndNews as Offer).theme
      : null;
  // : 'NEWS';

  const textTheme = offerTheme
    ? getTranslation(offerTheme)
      ? getTranslation(offerTheme)
      : offerTheme
    : null;

  const link = useNavigationKustomHref(offersAndNews.buttonActionString);

  const price =
    offer?.price &&
    (offer.price.toString().includes('.') ||
      offer.price.toString().includes(','))
      ? Number(offer.price).toFixed(2)
      : offer?.price;

  return (
    <Box
      bgColor="accent.100"
      position="fixed"
      zIndex={3000}
      left={0}
      top={0}
      right={0}
      bottom={0}
      boxShadow="-8px 8px 8px #00000014"
      display="flex"
      flexDir="column"
      p={[4, null, 6]}
      overflow={'auto'}
      className="menu-modal-content"
    >
      <IconButton
        position="absolute"
        zIndex={3}
        shadow="sm"
        bgColor="brand.500"
        color="white"
        w={10}
        h={10}
        right={3}
        top={3}
        aria-label={'close'}
        onClick={onClose}
        fontSize="18px"
        _hover={{
          bgColor: 'brand.600',
          color: 'white',
        }}
      >
        <IconSvg icon="cross" />
      </IconButton>
      <Container
        maxW={['container.xl', null, null, null, null, '1780px']}
        h={'100%'}
      >
        <Box
          display={['grid']}
          gridTemplateColumns={['1fr', null, null, '1fr 1fr', '1fr 2fr']}
          position={'relative'}
          height={['auto', null, null, '100%']}
        >
          <Box
            px={[0, null, 8, null, 10]}
            py={[7, null, 10]}
            display="flex"
            flexDir="column"
            flexGrow={1}
          >
            <Heading as="h3" variant={'h3'} mb="0" color="brand.500">
              <TranslatedString>{offersAndNews.title}</TranslatedString>
            </Heading>

            {(offersAndNews.description?.[currentLang] ||
              offersAndNews.description?.fr) && (
              <Text
                fontSize={['14px', null, '20px']}
                display="flex"
                flexDir="row"
                flexWrap="wrap"
                mt={[4, null, 6]}
              >
                <RichText value={offersAndNews.description}></RichText>
              </Text>
            )}
            <Box
              color="brand.500"
              display={'flex'}
              gap="2"
              justifyContent={'space-between'}
              alignItems={'center'}
              w="100%"
              mt={[4, null, 6]}
            >
              {/* {offer && !offer.isPriceDisabled && offer.price ? ( */}
              {offer && !offer.isPriceDisabled && price ? (
                <Box flexGrow={1}>
                  <>
                    {offer.isDiscountEnabled && offer.discount ? (
                      <>
                        {offer.isDiscountPercentage ? (
                          <Box fontWeight={'bold'} fontSize={'1.25em'}>
                            -{offer.discount}%
                          </Box>
                        ) : (
                          <Box textDecoration={'line-through'}>
                            {offer.discount}€
                          </Box>
                        )}
                      </>
                    ) : null}
                    {offer.isPriceFrom ? (
                      <Box fontSize={'0.85em'}>à partir de :</Box>
                    ) : null}
                    <Box fontWeight={'700'}>
                      <Box as="span" fontSize={'1.75em'} lineHeight={'1.125'}>
                        {/* {offer.price} */}
                        {price}
                        <Box as="span" fontSize={'0.85em'}>
                          {'€ '}
                        </Box>
                      </Box>
                      {offer.priceDetails ? (
                        <>{offer.priceDetails[currentLang]}</>
                      ) : null}
                    </Box>
                  </>
                </Box>
              ) : null}
              {textButton && link?.href && (
                <Button
                  colorScheme="brand"
                  variant="button"
                  alignSelf={'flex-end'}
                  justifySelf={'end'}
                  onClick={() => {
                    router.push(link.href);
                  }}
                >
                  {textButton}
                </Button>
              )}
            </Box>
          </Box>
          {media?.url && (
            <Box
              overflow={'clip'}
              position={'relative'}
              borderRadius={'base'}
              aspectRatio={['16/9', null, null, 'unset']}
              height={['fit-content', null, null, 'unset']}
              order={[-1, null, null, 1]}
            >
              {textTheme && (
                <Box
                  bg={'brand.500'}
                  color="#ffffff"
                  position={'absolute'}
                  left={0}
                  top={0}
                  zIndex={2}
                  py={3}
                  px={4}
                  fontFamily={'body'}
                  fontSize={'0.9em'}
                  fontWeight={700}
                  borderRadius={'0 0 3px 0'}
                >
                  {textTheme}
                </Box>
              )}
              {!isLoaded && (
                <Skeleton
                  width="100%"
                  height="100%"
                  style={{
                    zIndex: 0,
                    position: 'absolute',
                    right: 0,
                    left: 0,
                    top: 0,
                    opacity: isLoaded ? 0 : 1,
                  }}
                  borderRadius={0}
                  baseColor={skeletonColor.base}
                  highlightColor={skeletonColor.highlight}
                />
              )}
              <Box position={'relative'} w={'100%'} h={'100%'}>
                <Image
                  loading="lazy"
                  alt={''}
                  src={media!.url}
                  quality={quality}
                  fill
                  style={{
                    opacity: isLoaded ? 1 : 0,
                    transition: 'transform 0.3s ease',
                    objectFit: 'cover',
                  }}
                  sizes="(max-width: 960px) 95vw, (max-width: 1200px) 45vw,(max-width: 1480px) 60vw, 1000px"
                  onLoadingComplete={() => {
                    setIsLoaded(true);
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default OffersAndNewsDetails;
