import { Box, BoxProps, Heading, keyframes, SlideFade } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  TextImageComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import { useRef } from 'react';
import useIsMounted from '@/hooks/useIsMounted';
import { Parallax } from 'react-scroll-parallax';
import useDelay from '@/hooks/useDelay';
import Logo from '@/../public/assets/img/logo/logo-3.svg';

import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { clamp } from '@/helpers/math';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import Link from 'next/link';

interface PageHeaderProps
  extends KustomPageComponentViewProps<TextImageComponentData> {
  style?: BoxProps;
}

const PageHeader = ({ page, component, style }: PageHeaderProps) => {
  const isMounted = useIsMounted();

  const mediaBind = useKustomData<KustomResponsiveMedia>(
    page,
    component,
    `data.media`,
  );

  const subtitleIndex = component.data.atomicComponents.findIndex(
    (cmp) => cmp.type === 'TEXT',
  );

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const subtitleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${subtitleIndex}.text`,
  );

  const currentDevice = useResponsiveMediasDevice();

  const delayIndex = useDelay(300, 5);

  const containerHeaderEl = useRef<HTMLDivElement>(null);
  function fadeBackgroundImg(progress: number) {
    // containerHeaderEl.current?.style.setProperty(
    //   '--scroll',
    //   `${String(-progress * 1)}px`,
    // );
    containerHeaderEl.current?.style.setProperty(
      '--opacity',
      `${String(
        clamp(1 - Math.abs(progress / window.innerHeight) * 1.5, 0, 1),
      )}`,
    );
  }

  useScrollPosition(({ currPos }) => {
    fadeBackgroundImg(currPos.y);
  });

  return (
    <Box
      w="100vw"
      textAlign="center"
      position="relative"
      {...style}
      ref={containerHeaderEl}
      opacity={'var(--opacity, 1)'}
      className="ignoreSmoothScroll page-header"
      background={'brand.600'}
      overflow={'hidden'}
    >
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        w="100vw"
        // transform={`translateY(var(--scroll, 0))`}
        className="ignoreSmoothScroll"
        overflow={'hidden'}
        sx={{
          '.chakra-popover__popper': {
            top: '50% !important',
            transform: 'translate(10%, -50%) !important',
          },
        }}
      >
        <Box
          w="100%"
          h="100%"
          position="absolute"
          zIndex={2}
          left={0}
          top={0}
          background={'rgba(0,0,0,0.3)'}
          boxShadow={'inset 0 0 10rem rgba(0,0,0,0.1)'}
          pointerEvents={'none'}
        />
        {mediaBind.value && (
          <ResponsiveMedias
            // value={media}
            skeleton={false}
            {...mediaBind}
            disableCSSAdminWysiwyg
            width={undefined}
            height={undefined}
            fill
            currentDevice={currentDevice}
            quality={50}
            style={{
              background: 'brand.600',
              objectFit: 'cover',
            }}
            sizes={chakraResponsiveToImageSizes([
              '300vw',
              null,
              '200vw',
              '100vw',
            ])}
          />
        )}
      </Box>
      {isMounted && (
        <>
          <Box
            display="inline-flex"
            position="relative"
            zIndex={3}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={10}
            h="100%"
            margin="0 auto"
            width="auto"
            pt={[40, null, 40, 44]}
            pb={[40, null, 40, 44]}
            px={8}
          >
            <Parallax
              speed={15}
              opacity={[1, 0]}
              translateY={['0', '-20%']}
              startScroll={0}
              endScroll={window.innerHeight * 0.25}
            >
              <SlideFade
                offsetY="10%"
                in={delayIndex > 0.5}
                transition={{
                  enter: {
                    duration: 1,
                    easing: 'easeInOut',
                  },
                }}
                style={{
                  // width: 'clamp(120px, 11vw, 220px)',
                  width: 'clamp(80px, 10vw, 180px)',
                  aspectRatio: '32/25',
                }}
              >
                <Link href="/">
                  <Logo
                    style={{
                      width: '100%',
                      color: 'white',
                      filter:
                        'drop-shadow(0 0.25rem 0.25rem rgba(0, 0, 0, 0.1)',
                    }}
                  />
                </Link>
              </SlideFade>
            </Parallax>

            <Parallax
              speed={15}
              opacity={[1, 0]}
              translateY={['0', '0']}
              startScroll={0}
              endScroll={window.innerHeight * 0.25}
            >
              <Heading
                as="h2"
                color="white"
                mb={0}
                animation={`${keyframes`from {opacity: 0} to {opacity: 1}`} 0.5s ease-out 600ms 1 both`}
              >
                <RichText {...titleBind} />
              </Heading>
            </Parallax>
          </Box>
          <Box
            position="absolute"
            width="100%"
            zIndex={3}
            px="1rem"
            mb="3rem"
            bottom="0"
            animation={`${keyframes`from {opacity: 0} to {opacity: 1}`} 0.5s ease-out 750ms 1 both`}
          >
            <Parallax
              speed={15}
              opacity={[1, 0]}
              translateY={['0', '0']}
              startScroll={0}
              endScroll={window.innerHeight * 0.25}
            >
              <RichText
                as="h1"
                fontSize="1rem"
                fontFamily="commutersSansBold"
                textAlign="center"
                color="white"
                {...subtitleBind}
              />
            </Parallax>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PageHeader;
