import { ChakraStyledOptions } from '@chakra-ui/react';
import Image from 'next/image';

type SvgShapeProps = {
  style?: ChakraStyledOptions;
} & (
  | {
      shape: 1 | 2 | 3 | 4 | 5;
      dark?: false;
    }
  | {
      shape: 3;
      dark: true;
    }
);

export function SvgShape({
  shape,
  dark = false,
  style,
  ...props
}: Readonly<SvgShapeProps>) {
  return (
    <Image
      src={`/assets/img/shapes/form-${shape}${dark ? '-dark' : ''}.svg`}
      width={1}
      height={1}
      alt=""
      style={{
        display: 'block',
        width: '100%',
        objectFit: 'contain',
        ...style,
      }}
      {...props}
    />
  );
}
