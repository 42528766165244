import {
  KustomPageComponentViewProps,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import { Box } from '@chakra-ui/react';
import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

const RoomDescription: React.FC<
  KustomPageComponentViewProps<TextComponentData>
> = ({ component, page }) => {
  const descBind = useKustomData(page, component, 'data.text');

  return (
    <Box ml={[null, null, 'var(--grid-s-2)']} mt="clamp(2rem, 2.5vw, 3rem)">
      <RichText {...descBind} />
    </Box>
  );
};

export default RoomDescription;
