import {
  Box,
  Button,
  Heading,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  KustomPage,
  KustomPageComponent,
  KustomResponsiveMedia,
  KustomTranslatedString,
  MediasComponentData,
  RoomBookingComponentData,
  RoomSpecsComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { MouseEventHandler, useState } from 'react';
import useKustomData, {
  useKustomDataReturn,
  useKustomTranslation,
} from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

import KustomString from '@/lib/kustomcms-sdk/lib/components/KustomString';
import Slideshow from '../atomics/Slideshow';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import RoomCardBooking from './RoomCardBooking';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';

interface RoomCardProps {
  room: KustomPage;
  index: number;
  onClick: MouseEventHandler<HTMLDivElement>;
}

const RoomCard: React.FC<RoomCardProps> = ({ room, index, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const roomBooking = usePageComponent<RoomBookingComponentData>(
    room,
    'roomBooking',
  );
  const bookingId = roomBooking?.data.systemData?.roomCode;

  const mediaCmp = room
    .components[3] as KustomPageComponent<MediasComponentData>;

  const mediasBind = useKustomData<KustomResponsiveMedia[]>(
    room,
    mediaCmp,
    `data.medias`,
  );

  const specsCmp = room
    .components[0] as KustomPageComponent<RoomSpecsComponentData>;
  const titleBind = useKustomData<KustomTranslatedString>(
    room,
    specsCmp,
    `data.title`,
  );

  const peoplesNumberBind = useKustomData<string>(
    room,
    specsCmp,
    `data.peoplesNumber`,
  );

  const areaBind = useKustomData<string>(room, specsCmp, `data.area`);
  const bedBind = useKustomData<string | KustomTranslatedString>(
    room,
    specsCmp,
    `data.bed`,
  );
  const bedBindString = (
    typeof bedBind.value === 'string' ? bedBind : null
  ) as useKustomDataReturn<string> | null;
  const bedBindObject = (
    typeof bedBind.value === 'object' ? bedBind : null
  ) as useKustomDataReturn<KustomTranslatedString> | null;

  const peoplesLabelBind = useKustomTranslation('room_label_peoples');
  const toBookLabelBind = useKustomTranslation('to_book');

  const mediasHeight = useBreakpointValue({
    base: 260,
    lg: 360,
    '2xl': 450,
  });

  const [isWysiwygEditOpen, setIsWysiwygEditOpen] = useState(false);

  return (
    <Box
      onClick={onClick}
      bgColor="white"
      cursor="pointer"
      h="100%"
      position="relative"
      borderRadius="lg"
      boxShadow="0 0 1rem rgba(0, 0, 0, 0.05)"
      display="flex"
      flexDir="column"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        overflow={isWysiwygEditOpen ? 'visible' : 'clip'}
        borderRadius="0.45rem 0.45rem 0 0"
      >
        <Slideshow
          hideArrows
          hideCounter
          // usingMediaViewer
          height={mediasHeight}
          delay={index * 700}
          useOverlay
          sizes="50vw"
          isHovered={isHovered}
          // onClickItem={(e, index) => {
          //   e.preventDefault();
          //   e.stopPropagation();
          // }}
          handleIsWysiwygEditOpen={setIsWysiwygEditOpen}
          style={{
            transition: 'transform 0.3s ease',
            transform: isHovered ? 'scale(1.075)' : 'scale(1)',
            cursor: 'pointer',
            aspectRatio: '16/9',
          }}
          {...mediasBind}
        />
      </Box>
      <Box
        p={[6, 8, null, null, null, 10]}
        pt={[8, 10, null, null, null, 14]}
        display="flex"
      >
        <Box
          display={'flex'}
          gap="2"
          justifyContent={'space-between'}
          alignItems={'center'}
          w="100%"
        >
          <Box>
            <Heading as="h3" variant={'h4'} mb="0">
              <TranslatedString {...titleBind} />
            </Heading>
            <Text display="flex" flexDir="row" flexWrap="wrap" mt="3">
              <KustomString as="span" {...peoplesNumberBind} />
              &nbsp;
              <TranslatedString as="span" {...peoplesLabelBind} />
              <Box as="span" color="brand.500" px="3">
                |
              </Box>
              <KustomString as="span" {...areaBind} />
              <Box as="span" color="brand.500" px="3">
                |
              </Box>
              {bedBindObject && (
                <TranslatedString as="span" {...bedBindObject} />
              )}
              {bedBindString && <KustomString as="span" {...bedBindString} />}
            </Text>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
            alignItems={'center'}
            gap={bookingId ? '1rem' : '0'}
          >
            {bookingId && <RoomCardBooking id={bookingId} />}
            <Button colorScheme="brand" variant="button">
              <TranslatedString color="inherit" {...toBookLabelBind} />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RoomCard;
