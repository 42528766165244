import { Box, Button, Container, Heading } from '@chakra-ui/react';
import {
  CodeComponent,
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextComponent,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useEffect, useState } from 'react';

import GoogleMap from 'google-maps-react-markers';
import Image from 'next/image';

import ImgMarker from '@/../public/assets/Contact/map-pin-rect.png';

import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import mapStyle from './mapStyle';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import CookiesConsentContext from '@/contexts/CookiesConsentContext';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import Section from '../Section';
import ContainerSection from '../ContainerSection';
import { ShapeComponent } from '../ShapeComponent';
import useKustomData, {
  useKustomTranslation,
} from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';

interface ContactMapProps
  extends KustomPageComponentViewProps<TextComponentData> {}

const ContactMap: React.FC<ContactMapProps> = ({ page, component }) => {
  const { currentLang } = useContext(LanguageContext);

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const textIndex = component.data.atomicComponents.findIndex(
    (cmp) => cmp.type === 'TEXT',
  );

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${textIndex}.text`,
  );

  const gmapsButtonLabel = useKustomTranslation('map_active_map');
  const gmapsText = useKustomTranslation('map_text');

  const settingsCtx = useContext(SettingsContext);
  const googleMapAPIKey =
    settingsCtx?.settings?.googleServices?.googleMapAPIKey;

  const { consents, getConsent, openConsent } = useContext(
    CookiesConsentContext,
  );
  const [allowDisplayMap, setAllowDisplayMap] = useState(getConsent('gmaps'));
  useEffect(() => {
    setAllowDisplayMap(getConsent('gmaps'));
  }, [consents, getConsent]);

  const codes = component.data.atomicComponents.filter(
    (cmp) => cmp.type === 'CODE',
  ) as CodeComponent[];

  const codeShape = codes.find((cmp) =>
    cmp.code.includes('shape'),
  ) as CodeComponent;

  const codesCoords = (
    codes.filter((cmp) => cmp.code.match(/^\d/)) as CodeComponent[]
  ).map((cmp) => ({
    code: parseFloat(cmp.code),
  }));

  return (
    <Section className={'contact-map'}>
      <ContainerSection>
        {codeShape && <ShapeComponent code={codeShape.code} />}
        <Box
          display={['block', null, null, 'flex']}
          justifyContent="space-between"
          // alignItems="start"
          alignItems="center"
        >
          <Box
            position="relative"
            zIndex={2}
            width={['100%', null, null, '37%']}
            // pt={['0', null, null, '12', '16']}
          >
            <Heading as="h2">
              <RichText {...titleBind} />
            </Heading>
            <RichText ml={['0', null, null, null, '22%']} {...textBind} />
          </Box>

          {googleMapAPIKey && codesCoords[0]?.code && codesCoords[1]?.code && (
            <Box
              h="clamp(400px, 60vw, 600px)"
              position="relative"
              bg="brand.200"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={['100%', null, null, '57%']}
              mt={['3rem', null, null, '0']}
            >
              {allowDisplayMap ? (
                <GoogleMap
                  apiKey={googleMapAPIKey}
                  defaultCenter={{
                    lat: codesCoords[0].code,
                    lng: codesCoords[1].code,
                  }}
                  defaultZoom={10}
                  options={{
                    styles: mapStyle,
                    disableDefaultUI: true,
                  }}
                >
                  <HotelMarker
                    key="hotelMarker"
                    markerId="hotelMarker"
                    lat={codesCoords[0].code}
                    lng={codesCoords[1].code}
                  />
                </GoogleMap>
              ) : (
                <Box
                  color="brand.500"
                  display="flex"
                  flexDir="column"
                  gap="1.5rem"
                  p="1.5rem"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                >
                  <TranslatedString
                    color="inherit"
                    {...gmapsText}
                    fontSize="1.125rem"
                  />
                  <Button variant="button" onClick={openConsent}>
                    <TranslatedString color="inherit" {...gmapsButtonLabel} />
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </ContainerSection>
    </Section>
  );
};

const HotelMarker = (props: any) => {
  const device = useResponsiveMediasDevice();

  return (
    <Box
      w={device === 'mobile' ? 75 : 100}
      h={device === 'mobile' ? 75 : 100}
      transform="translate(-50%, -100%)"
    >
      <Image
        loading="lazy"
        src={ImgMarker}
        alt="marker"
        style={{
          width: '100%',
        }}
      />
    </Box>
  );
};

export default ContactMap;
