import {
  AccordionComponentData,
  KustomPageComponentViewProps,
} from '@/lib/kustomcms-sdk/lib/types';

import AccordionComponent from '../AccordionComponent';
import React from 'react';
import ContainerSection from '../ContainerSection';
import Section from '../Section';

interface FAQAccordionSectionProps
  extends KustomPageComponentViewProps<AccordionComponentData> {}

const FAQAccordionSection: React.FC<FAQAccordionSectionProps> = ({
  component,
  page,
  index,
}) => {
  return (
    <Section className={'faq'} position="relative" zIndex="2">
      <ContainerSection pt="0 !important">
        <AccordionComponent
          component={component}
          page={page}
          index={index}
          containerProps={{
            w: [
              null,
              null,
              'var(--grid-w-10)',
              'var(--grid-w-8)',
              'var(--grid-w-6)',
            ],
            ml: [null, null, null, 'var(--grid-s-1)'],
          }}
        />
      </ContainerSection>
    </Section>
  );
};

export default FAQAccordionSection;
