import {
  ActionButtonComponent,
  CarouselComponentSlide,
  CodeComponent,
  CustomComponentData,
  KustomPageComponentViewProps,
  KustomTranslatedString,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Heading } from '@chakra-ui/react';

import ActionButton from '@/components/atomics/ActionButton';
import React, { useContext } from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import RoomArtistSlides from './RoomArtistSlides';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import checkTranslatedStringValues from '@/lib/kustomcms-sdk/lib/helpers/checkTranslatedStringValues';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import ContainerSection from '../ContainerSection';
import Section from '../Section';
import AnimatePresence from '../atomics/AnimatePresence';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import { ShapeComponent } from '../ShapeComponent';

const MaisonArtist: React.FC<
  KustomPageComponentViewProps<CustomComponentData>
> = ({ page, component }) => {
  const { currentLang } = useContext(LanguageContext);

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textTitle`,
  );

  const subtitleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textSubtitle`,
  );

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const hasText =
    [titleBind.value, subtitleBind.value, textBind.value].filter(
      (t) => t?.fr || t?.en || t?.es,
    ).length > 0;

  const mediasBind = useKustomData<
    CarouselComponentSlide[],
    CarouselComponentSlide[]
  >(page, component, `data.slides`);

  const buttonsIndex = component.data.atomicComponents
    .map((cmp, index) =>
      cmp.type === 'ACTION_BUTTON' ? { index, id: cmp.id } : undefined,
    )
    .filter((i) => i !== undefined) as { id: string; index: number }[];

  const codeCpm = component?.data?.atomicComponents.filter(
    (c) => c.type === 'CODE',
  ) as CodeComponent[];

  const codeShape = codeCpm?.find((c) => c.code.includes('shape-'));

  const codeArtist =
    codeCpm?.find((c) => c.code.includes('artist-'))?.code || '';

  const artistId = codeArtist.match(/artist-[^ ]+/)?.[0] || '';

  return (
    <Section className={'section-artist'} id={artistId}>
      <ContainerSection
        pt={['32', null, null, '24', '32', '44']}
        pb={['0', null, null, '24', '32', '44']}
      >
        <Box
          display={['block', null, null, 'flex']}
          justifyContent="space-between"
          alignItems="stretch"
        >
          {hasText && (
            <Box
              zIndex={2}
              width={['100%', null, null, '37%']}
              // pt={['0', null, null, '12', '16']}
              top="0"
            >
              <Box position="sticky" top="calc(clamp(1rem, 6vw, 7rem) + 5rem)">
                <AnimatePresence opacity>
                  <Heading
                    as="h2"
                    variant="h2b"
                    textTransform="none"
                    marginBottom={
                      subtitleBind?.value?.[currentLang] !== ''
                        ? '0.6rem'
                        : undefined
                    }
                  >
                    <TranslatedString {...titleBind} color="brand.500" />
                  </Heading>
                  {subtitleBind?.value?.[currentLang] !== '' ? (
                    <Heading as="h3" variant="h5">
                      <RichText {...subtitleBind} color="brand.500" />
                    </Heading>
                  ) : null}
                  {checkTranslatedStringValues(textBind.value) && (
                    <RichText {...textBind} />
                  )}
                  {!!buttonsIndex.length && (
                    <Box
                      mt="clamp(2rem, 2.5vw, 3rem)"
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'flex-start'}
                      gap={'2'}
                      sx={{
                        '.mb': {
                          mb: '4',
                        },
                      }}
                    >
                      {buttonsIndex.map(({ index: buttonIndex, id }, index) => (
                        <MaisonArtistButton
                          component={component}
                          page={page}
                          key={id}
                          index={buttonIndex}
                        />
                      ))}
                    </Box>
                  )}
                </AnimatePresence>
              </Box>
            </Box>
          )}

          {mediasBind.value?.length ? (
            <Box
              width={['100%', null, null, '57%']}
              mt={['3rem', null, null, '0']}
              aspectRatio={4 / 3}
            >
              {codeShape && <ShapeComponent code={codeShape.code} />}
              <RoomArtistSlides
                usingMediaViewer
                {...mediasBind}
                hideArrows
                hideCounter
                canSwipe={true}
                height={'100%'}
                containerStyle={{
                  borderRadius: 'base',
                }}
                // sizes="(max-width: 959px) 95vw, (max-width: 1919px) 45vw, 920px"
                sizes={chakraResponsiveToImageSizes([
                  '95vw',
                  null,
                  null,
                  '45vw',
                  null,
                  '920px',
                ])}
              />
            </Box>
          ) : null}
        </Box>
      </ContainerSection>
    </Section>
  );
};

interface MaisonArtistButtonProps
  extends KustomPageComponentViewProps<CustomComponentData> {
  index: number;
}

const MaisonArtistButton: React.FC<MaisonArtistButtonProps> = ({
  component,
  page,
  index,
}) => {
  const buttonBind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${index}`,
  );

  return <ActionButton {...buttonBind} />;
};

export default MaisonArtist;
