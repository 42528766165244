import {
  ActionButtonComponent,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Heading } from '@chakra-ui/react';

import ActionButton from '@/components/atomics/ActionButton';
import AnimatePresence from '../atomics/AnimatePresence';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import ContainerSection from '../ContainerSection';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import Section from '../Section';
import { SvgShape } from '../SvgShape';
import { ParallaxEl } from '../ParallaxEl';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';

const Section5 = ({
  component,
  page,
}: KustomPageComponentViewProps<TextCarouselComponentData>) => {
  const title1Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.0.title`,
  );

  const title2Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.1.title`,
  );

  const desc1Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.0.description`,
  );

  const desc2Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.1.description`,
  );

  const media1Bind = useKustomData<KustomResponsiveMedia>(
    page,
    component,
    `data.slides.0.medias`,
  );

  const media2Bind = useKustomData<KustomResponsiveMedia>(
    page,
    component,
    `data.slides.1.medias`,
  );

  const atomActionButton1Bind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.slides.1.atomicComponents.0`,
  );

  const atomActionButton2Bind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.slides.1.atomicComponents.1`,
  );

  const slide1 = {
    id: component.id,
    title: title1Bind,
    desc: desc1Bind,
    media: media1Bind,
  };

  const slide2 = {
    id: component.id,
    title: title2Bind,
    desc: desc2Bind,
    media: media2Bind,
    actionButtonBind1: atomActionButton1Bind,
    actionButtonBind2: atomActionButton2Bind,
  };

  const device = useResponsiveMediasDevice();

  return (
    <Section className={'section-5'}>
      {slide1 && (
        <ContainerSection>
          <Box
            position="absolute"
            zIndex="0"
            bottom="0"
            left="50%"
            w={['60%', null, null, '40%']}
            pointerEvents={'none'}
          >
            <ParallaxEl speed={-4}>
              <Box
                transform={[
                  'translate(0, -10%)',
                  null,
                  null,
                  'rotate(245deg) translate(3%, -67%)',
                ]}
              >
                <SvgShape shape={3} />
              </Box>
            </ParallaxEl>
          </Box>
          <Box
            display="flex"
            flexDirection={['column', null, null, 'row']}
            justifyContent="space-between"
            // alignItems="start"
            alignItems="center"
          >
            <Box
              position={'relative'}
              width={['100%', null, null, '57%']}
              mt={['3rem', null, null, '0']}
              aspectRatio={4 / 3}
            >
              <ParallaxEl speed={5}>
                <ResponsiveMedias
                  {...slide1.media}
                  disableCSSAdminWysiwyg
                  currentDevice={device}
                  style={{
                    objectFit: 'cover',
                    borderRadius: 4,
                    background: 'var(--chakra-colors-brand-200)',
                  }}
                  fill
                  width={undefined}
                  height={undefined}
                  // sizes="(max-width: 959px) 95vw, (max-width: 1919px) 45vw, 920px"
                  sizes={chakraResponsiveToImageSizes([
                    '95vw',
                    null,
                    null,
                    '45vw',
                    null,
                    '920px',
                  ])}
                  skeletonColor={{
                    base: '#efd9c9',
                    highlight: '#F4E7DC',
                  }}
                />
              </ParallaxEl>
            </Box>
            <Box
              position="relative"
              zIndex={2}
              width={['100%', null, null, '35%']}
              order={['-1', null, null, '2']}
              // pt={['0', null, null, '12', '16']}
            >
              <AnimatePresence opacity>
                <Heading
                  as="h2"
                  // marginLeft={[null, null, null, '-63%']}
                >
                  <TranslatedString {...slide1.title} color="brand.500" />
                </Heading>
                <RichText {...slide1.desc} />
              </AnimatePresence>
            </Box>
          </Box>
        </ContainerSection>
      )}
      {slide2 && (
        <ContainerSection pt="0 !important">
          <Box
            display="flex"
            flexDirection={['column', null, null, 'row']}
            justifyContent="space-between"
            // alignItems="start"
            alignItems="center"
          >
            <Box
              position="relative"
              zIndex={2}
              width={['100%', null, null, '35%']}
              // pt={['0', null, null, '12', '16']}
            >
              <AnimatePresence opacity>
                <Heading
                  as="h2"
                  // marginRight={[null, null, null, '-63%']}
                >
                  <TranslatedString {...slide2.title} color="brand.500" />
                </Heading>
                <RichText {...slide2.desc} />
                {(slide2.actionButtonBind1 || slide2.actionButtonBind2) && (
                  <Box
                    mt="clamp(2rem, 2.5vw, 3rem)"
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                    gap={'4'}
                  >
                    {slide2.actionButtonBind1 && (
                      <ActionButton
                        // variant="link"
                        {...slide2.actionButtonBind1}
                      />
                    )}
                    {slide2.actionButtonBind2 && (
                      <ActionButton
                        // variant="button"
                        {...slide2.actionButtonBind2}
                      />
                    )}
                  </Box>
                )}
              </AnimatePresence>
            </Box>
            <Box
              position={'relative'}
              width={['100%', null, null, '57%']}
              mt={['3rem', null, null, '0']}
              aspectRatio={4 / 3}
            >
              <ParallaxEl speed={5}>
                <ResponsiveMedias
                  {...slide2.media}
                  disableCSSAdminWysiwyg
                  currentDevice={device}
                  style={{
                    objectFit: 'cover',
                    borderRadius: 4,
                    background: 'var(--chakra-colors-brand-200)',
                  }}
                  fill
                  width={undefined}
                  height={undefined}
                  // sizes="(max-width: 959px) 95vw, (max-width: 1919px) 45vw, 920px"
                  sizes={chakraResponsiveToImageSizes([
                    '95vw',
                    null,
                    null,
                    '45vw',
                    null,
                    '920px',
                  ])}
                  skeletonColor={{
                    base: '#efd9c9',
                    highlight: '#F4E7DC',
                  }}
                />
              </ParallaxEl>
            </Box>
          </Box>
        </ContainerSection>
      )}
    </Section>
  );
};

export default Section5;
