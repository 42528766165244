import {
  AtomicComponent,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  MediaComponent,
  TextImageComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Container, Heading } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { Parallax } from 'react-scroll-parallax';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
// import SketchShell1 from '@/../public/assets/img/illu-coquillage-1.svg';
import Slideshow from '../atomics/Slideshow';
import { uniqID } from '@/lib/kustomcms-sdk/lib/admin/helpers/random';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import Section from '../Section';
import ContainerSection from '../ContainerSection';
import { ParallaxEl } from '../ParallaxEl';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import { SvgShape } from '../SvgShape';

const RoomsBreakfast: React.FC<
  KustomPageComponentViewProps<TextImageComponentData>
> = ({ page, component }) => {
  const mediaBind = useKustomData<KustomResponsiveMedia>(
    page,
    component,
    `data.media`,
  );

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const [text2Id] = component.data.atomicComponents
    .map((atomicComponent, index) =>
      atomicComponent.type === 'TEXT' ? index : null,
    )
    .filter((index) => index !== null);

  const text2Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${text2Id}.text`,
  );

  const _medias = component.data.atomicComponents.filter(
    (atomicComponent) => atomicComponent.type === 'MEDIA',
  ) as MediaComponent[];

  const medias = [mediaBind.value, ..._medias.map((m) => m.media)].filter(
    (m) => !!m,
  ) as KustomResponsiveMedia[];

  const atomsBind = useKustomData<AtomicComponent[]>(
    page,
    component,
    `data.atomicComponents`,
  );

  const onMediasChange = useMemo(
    () =>
      atomsBind.onChange
        ? (_medias: KustomResponsiveMedia[]) => {
            const [firstMedia, ...medias] = _medias;

            if (firstMedia) {
              mediaBind.onChange?.(firstMedia);
            }
            const atomsNoMedias = atomsBind.value?.filter(
              (cmp) => cmp.type !== 'MEDIA',
            );

            const newAtoms = medias.map((media) => {
              const mediaAtomCmp = (atomsBind.value?.find(
                (cmp) =>
                  cmp.type === 'MEDIA' &&
                  (cmp as MediaComponent).media.id === media.id,
              ) || {
                id: uniqID(),
                type: 'MEDIA',
                media,
              }) as MediaComponent;

              mediaAtomCmp.media = media;

              return mediaAtomCmp;
            });

            atomsBind.onChange?.([...newAtoms, ...(atomsNoMedias || [])]);
          }
        : undefined,
    [atomsBind.onChange, atomsBind.value],
  );

  return (
    <Section className={'rooms-breakfast'}>
      <ContainerSection>
        <Box
          width={['80%', null, null, '52%']}
          right="0"
          bottom={'0'}
          position="absolute"
          pointerEvents={'none'}
        >
          <ParallaxEl speed={-5}>
            <SvgShape
              shape={3}
              style={{
                transform: 'translate(20%, 0)',
              }}
            />
          </ParallaxEl>
        </Box>
        <Box
          display={['block', null, null, 'flex']}
          justifyContent="space-between"
          // alignItems="start"
          alignItems="center"
        >
          <Box
            position="relative"
            zIndex={2}
            width={['100%', null, null, '37%']}
            // pt={['0', null, null, '12', '16']}
          >
            <Heading as="h2">
              <RichText color="brand.500" {...textBind} />
            </Heading>
            <RichText {...text2Bind} />
          </Box>
          {medias && (
            <Box
              width={['100%', null, null, '57%']}
              mt={['3rem', null, null, '0']}
              aspectRatio={4 / 3}
            >
              <ParallaxEl speed={5}>
                <Slideshow
                  usingMediaViewer
                  value={medias}
                  onChange={onMediasChange}
                  hideArrows
                  hideCounter
                  canSwipe={true}
                  height={'100%'}
                  containerStyle={{
                    borderRadius: 'base',
                  }}
                  // sizes="(max-width: 959px) 95vw, (max-width: 1919px) 45vw, 920px"
                  sizes={chakraResponsiveToImageSizes([
                    '95vw',
                    null,
                    null,
                    '45vw',
                    null,
                    '920px',
                  ])}
                />
              </ParallaxEl>
            </Box>
          )}
        </Box>
      </ContainerSection>
    </Section>
  );
};

export default RoomsBreakfast;
