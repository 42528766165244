import { useContext, useEffect, useRef, useState } from 'react';
import CookiesConsentContext from '@/contexts/CookiesConsentContext';
import LanguageContext, {
  LanguageContextKey,
} from '@/lib/kustomcms-sdk/lib/contexts/language';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';

type Window = typeof window & {
  HiJiffyWidget?: {
    Token?: string;
  };
};

export const ScriptsInjector = () => {
  const { getConsent, consents } = useContext(CookiesConsentContext);
  const { settings } = useContext(SettingsContext);
  const { currentLang } = useContext(LanguageContext);

  // Hijiffy

  const alreadyHijiffyInjected = useRef(false);
  const injectScript = () => {
    const _window: Window = window;
    _window.HiJiffyWidget = _window.HiJiffyWidget || {};
    _window.HiJiffyWidget.Token = 'PxghI0nHCxcwN1';

    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.id = 'hijiffy-kustom';
    script.src =
      'https://widget.hijiffy.com/build.js?t=' +
      Math.floor(new Date().getTime() / 60000);
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (getConsent('hijiffy') && !alreadyHijiffyInjected.current) {
      alreadyHijiffyInjected.current = true;
      injectScript();
    }
  }, [consents, getConsent]);

  // GTM

  const alreadyGtmHeadInjected = useRef(false);
  const injectScriptHead = () => {
    const script = document.createElement('script');
    script.id = 'gtm-head';
    const inlineCode = document.createTextNode(
      settings?.googleServices?.googleTagManagerHeaderTag || '',
    );
    script.appendChild(inlineCode);
    document.head.appendChild(script);
  };

  const alreadyGtmBodyInjected = useRef(false);
  const injectScriptEndOfBody = () => {
    const script = document.createElement('div');
    script.id = 'gtm-body';
    const inlineCode = settings?.googleServices?.googleTagManagerBodyTag || '';
    script.innerHTML = inlineCode;
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!alreadyGtmHeadInjected.current) {
      alreadyGtmHeadInjected.current = true;
      injectScriptHead();
    }
    if (!alreadyGtmBodyInjected.current) {
      alreadyGtmBodyInjected.current = true;
      injectScriptEndOfBody();
    }
  }, []);

  // HSW

  const version = '?v=10';

  const alreadyHswInjected = useRef<string | null>(null);
  const injectScriptHotelSmartWidgets = (
    currentLang: LanguageContextKey,
    id: string,
    domain: string,
  ) => {
    if (alreadyHswInjected.current === currentLang) return;
    alreadyHswInjected.current = currentLang;

    const lang = currentLang === 'fr' ? '' : `-${currentLang}`;
    const currentScript = document.getElementById('diadao-hsw-dist-script-js');
    const currentStyle = document.getElementById('diadao-hsw-dist-style-css');
    const currentHtml = document.getElementById('diadao-hsw-hsp-html');

    if (currentScript) currentScript.remove();
    if (currentStyle) currentStyle.remove();
    if (currentHtml) currentHtml.remove();

    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.id = 'diadao-hsw-dist-script-js';
    script.src = `${domain}/hsw-${id}${lang}.js${version}`;

    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.id = 'diadao-hsw-dist-style-css';
    link.href = `${domain}/hsw-${id}${lang}.css${version}`;
    link.media = 'all';
    link.crossOrigin = 'anonymous';
    document.head.appendChild(link);
  };

  // const [isOpenHsw, setIsOpenHsw] = useState(false);

  // useDisablePageScroll(isOpenHsw);

  useEffect(() => {
    const id = settings?.modules?.extraFields?.hotelSmartWidgetsId?.value;

    const domain =
      settings?.modules?.extraFields?.hotelSmartWidgetsDomain?.value;

    if (id && domain) {
      setTimeout(() => {
        injectScriptHotelSmartWidgets(currentLang, id, domain);
      }, 2000);
    }

    // window.addEventListener('smartpreviewPanelOpened', () => {
    //   setIsOpenHsw(true);
    // });

    // window.addEventListener('smartpreviewPanelClosed', () => {
    //   setIsOpenHsw(false);
    // });

    window.addEventListener('click', (e) => {
      if (e.target instanceof HTMLElement) {
        const isHspClick = e.target?.closest('#smartpreview');
        const isAxeptioClick = e.target?.closest('#axeptio_overlay');
        if (isHspClick || isAxeptioClick) {
          e.stopPropagation();
        } else {
          window.dispatchEvent(new CustomEvent('smartpreviewPanelClose'));
        }
      }
    });

    // return () => {
    //   window.removeEventListener('smartpreviewPanelOpened', () => {
    //     setIsOpenHsw(true);
    //   });

    //   window.removeEventListener('smartpreviewPanelClosed', () => {
    //     setIsOpenHsw(false);
    //   });
    // };
  }, [currentLang]);

  return null;
};
