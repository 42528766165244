import { Box, Container } from '@chakra-ui/react';
import {
  CodeComponent,
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import Section from './Section';
import { ShapeComponent } from './ShapeComponent';
import ContainerSection from './ContainerSection';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

export interface TextSectionProps
  extends KustomPageComponentViewProps<TextComponentData> {}

const TextSection: React.FC<TextSectionProps> = ({ page, component }) => {
  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const code = component.data.atomicComponents.find(
    (cmp) => cmp.type === 'CODE',
  ) as CodeComponent;

  return (
    <Section className={'text'}>
      <ContainerSection
        pb="0 !important"
        sx={{
          h2: {
            color: 'brand.500',
            fontFamily: 'basteleur',
            textTransform: 'uppercase',
            fontSize: 'clamp(1.75rem, 1.8vw, 2.5rem)',
            marginTop: 'clamp(2rem, 2.5vw, 3rem)',
            marginBottom: 'clamp(1rem, 1.25vw, 1.75rem)',
            lineHeight: '1.15',
            textWrap: 'balance',
          },
          strong: {
            fontFamily: 'commutersSansBold',
          },
          a: { textDecoration: 'underline' },
          ul: {
            listStyleType: 'disc',
            marginLeft: '1rem',
          },
        }}
      >
        {code && <ShapeComponent code={code.code} />}
        <RichText {...textBind} />
      </ContainerSection>
    </Section>
  );
};

export default TextSection;
