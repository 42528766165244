import { Box, Heading, SimpleGrid, useTheme } from '@chakra-ui/react';
import {
  CodeComponent,
  CustomComponentData,
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useMemo } from 'react';

import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import ContainerSection from '../ContainerSection';
import Section from '../Section';
import { IconSvg } from '../IconSvg';
import { ParallaxEl } from '../ParallaxEl';
import { SvgShape } from '../SvgShape';
import AnimatePresence from '../atomics/AnimatePresence';

const RoomsService: React.FC<
  KustomPageComponentViewProps<CustomComponentData>
> = ({ page, component }) => {
  const textCmp = usePageComponent<TextComponentData>(
    page,
    null,
    '16899421193335740',
  );

  const extraTextBind = useKustomData<KustomTranslatedString>(
    page,
    textCmp,
    `data.text`,
  );

  const textCmp2 = usePageComponent<TextComponentData>(
    page,
    null,
    '17122406601971594',
  );

  const extraTextBind2 = useKustomData<KustomTranslatedString>(
    page,
    textCmp2,
    `data.text`,
  );

  const items = useMemo(() => {
    const n =
      component.data.atomicComponents.length % 2
        ? component.data.atomicComponents.length - 1
        : component.data.atomicComponents.length;

    const items = [];

    for (let i = 0; i < n; i += 2) {
      const icon = component.data.atomicComponents[i] as CodeComponent;
      const label = i + 1;

      items.push({
        id: component.data.atomicComponents[i]?.id,
        icon: icon.code,
        labelIndex: label,
      });
    }

    return items;
  }, [component.data.atomicComponents]);

  const [servicesMobileOpen, setServicesMobileOpen] = React.useState(false);

  return (
    <Section className={'rooms-service'}>
      <ContainerSection
        pt={['8', null, null, '0 !important']}
        pb={['8', null, null, '12 !important', null, null]}
      >
        <Box
          display={['none', null, null, 'block']}
          width={['65%', null, null, '55%', '52%']}
          left={['auto', null, null, '0']}
          right={['0', null, null, 'auto']}
          bottom="0"
          position="absolute"
          pointerEvents={'none'}
        >
          <ParallaxEl speed={-5}>
            <Box
              transform={[
                'translate(50%, -30%) rotate(310deg)',
                null,
                null,
                'translate(-40%, -30%) rotate(230deg)',
              ]}
            >
              <SvgShape shape={5} />
            </Box>
          </ParallaxEl>
        </Box>
        <Box
          ml={['0', null, null, 'var(--grid-s-4)']}
          position="relative"
          zIndex={2}
          // borderTop="1px solid var(--chakra-colors-brand-300)"
          // borderBottom="1px solid var(--chakra-colors-brand-300)"
          bg={'rgba(255, 255, 255, 0.35)'}
          boxShadow="0 0 1rem rgba(0, 0, 0, 0.05)"
          px="9"
          borderRadius={'lg'}
          // pb={[8, null, 12]}
        >
          <AnimatePresence opacity>
            <Box
              onClick={() => setServicesMobileOpen((isOpen) => !isOpen)}
              py={[8, null, 12]}
              display={'flex'}
              justifyContent={'space-between'}
              gap={['0.5rem']}
            >
              <Heading as="h3" variant={['h4', null, 'h3']} mb="0">
                <RichText {...extraTextBind2} />
              </Heading>
              <IconSvg
                display={['block', null, 'none']}
                icon="right"
                size="1.5rem"
                color="brand.500"
                transform={
                  servicesMobileOpen ? 'rotate(-90deg)' : 'rotate(90deg)'
                }
              />
            </Box>

            <Box
              display={'grid'}
              gridTemplateRows={[
                servicesMobileOpen ? '1fr' : '0fr',
                null,
                '1fr',
              ]}
              transition={'grid-template-rows 0.5s ease-in-out'}
            >
              <Box overflow={'hidden'}>
                <SimpleGrid
                  gridTemplateColumns={[
                    'repeat(1, 1fr);',
                    null,
                    'repeat(2, 1fr);',
                  ]}
                  gap={['clamp(1rem, 2vw, 2.25rem)']}
                  pb={[8, null, 12]}
                >
                  {items.map(({ id, icon, labelIndex }, index) => (
                    <RoomsServiceItem
                      page={page}
                      component={component}
                      key={id}
                      icon={icon}
                      labelIndex={labelIndex}
                    />
                  ))}
                </SimpleGrid>
                {extraTextBind.value && (
                  <RichText
                    {...extraTextBind}
                    fontSize={'0.75em'}
                    mt={[6, null, 12]}
                    border={'1px solid green'}
                  />
                )}
              </Box>
            </Box>
          </AnimatePresence>
        </Box>
      </ContainerSection>
    </Section>
  );
};

interface RoomsServiceItemProps
  extends KustomPageComponentViewProps<CustomComponentData> {
  icon: string;
  labelIndex: number;
}

const RoomsServiceItem: React.FC<RoomsServiceItemProps> = ({
  icon,
  labelIndex,
  page,
  component,
}) => {
  const labelBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${labelIndex}.text`,
  );

  return (
    <Box
      display={'flex'}
      gap="1.5rem"
      alignItems={'center'}
      pr={['0', null, null, null, '6', '32']}
    >
      <IconSvg
        icon={`${icon}`}
        size={['2.25rem', null, null, '2.5rem']}
        color="brand.500"
        flexShrink={0}
      />
      <RichText {...labelBind} />
    </Box>
  );
};

export default RoomsService;
