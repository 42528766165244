import { ChakraProps, chakra, ChakraComponent } from '@chakra-ui/react';

type IconSvgProps = ChakraProps & {
  icon: string;
  color?: string;
  size?: string | (string | null)[];
};

const CustomSvg = chakra('svg');

export function IconSvg({
  icon,
  color = 'currentColor',
  size = '1.5rem',
  ...props
}: Readonly<IconSvgProps>) {
  return (
    <CustomSvg color={color} width={size} aspectRatio={'1/1'} {...props}>
      <use xlinkHref={`/icons/icons.svg?v2#${icon}`} />
    </CustomSvg>
  );
}
