import { Box, Heading, SimpleGrid, chakra } from '@chakra-ui/react';
import {
  CodeComponent,
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import { IconSvg } from './IconSvg';

interface ItemsGridProps
  extends KustomPageComponentViewProps<TextCarouselComponentData> {
  disableHeading?: boolean;
}

const ItemsGrid: React.FC<ItemsGridProps> = ({
  page,
  component,
  disableHeading,
}) => {
  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textTitle`,
  );

  return (
    <Box
      bgColor="white"
      boxShadow="0 0 1rem rgba(0, 0, 0, 0.05)"
      position="relative"
      p="9"
      borderRadius={'lg'}
    >
      {!disableHeading && (
        <Heading as="h3" variant={'h3'} mb={[8, null, 12]}>
          <TranslatedString {...titleBind} />
        </Heading>
      )}
      <SimpleGrid
        gridTemplateColumns={['repeat(1, 1fr);', null, 'repeat(2, 1fr);']}
        gap={['clamp(1rem, 2vw, 2.25rem)']}
      >
        {component.data.slides.map(({ id }, index) => (
          <ItemsGridItem
            key={id}
            page={page}
            component={component}
            slideIndex={index}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

interface ItemsGridItemProps
  extends KustomPageComponentViewProps<TextCarouselComponentData> {
  slideIndex: number;
}

const ItemsGridItem: React.FC<ItemsGridItemProps> = ({
  page,
  component,
  slideIndex,
}) => {
  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.title`,
  );

  const code = component.data.slides[slideIndex]?.atomicComponents.find(
    (cpm) => cpm.type === 'CODE',
  ) as CodeComponent;
  const icon = code?.code;

  return (
    <Box display={'flex'} gap="1.5rem" alignItems={'center'}>
      {icon && (
        <IconSvg
          icon={`${icon}`}
          size={['2.25rem', null, null, '2.5rem']}
          color="brand.500"
          flexShrink={0}
        />
      )}
      <RichText {...textBind} />
    </Box>
  );
};

export default ItemsGrid;
