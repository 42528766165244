import { ChakraProps, Container } from '@chakra-ui/react';

type ContainerSectionProp = ChakraProps & {
  children: React.ReactNode;
};

const ContainerSection = ({ children, ...props }: ContainerSectionProp) => {
  return (
    <Container
      maxW="container.3xl"
      px={'clamp(1rem, 6vw, 7rem)'}
      py={['16', null, null, '24', '32', '44']}
      position={'relative'}
      overflow={'visible'}
      {...props}
    >
      {children}
    </Container>
  );
};

export default ContainerSection;
