import { createContext, useState } from 'react';

interface ModalContextType {
  modalRoute: string;
  setModalRoute: (modalRoute: string) => void;
}

const ModalContext = createContext<ModalContextType>({
  modalRoute: '',
  setModalRoute: () => {},
});

interface ModalContextProviderProps {
  children: React.ReactNode;
}

export const ModalContextProvider = ({
  children,
}: ModalContextProviderProps) => {
  const [modalRoute, setModalRoute] = useState<string>('');

  return (
    <ModalContext.Provider value={{ modalRoute, setModalRoute }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
