import { BoxProps, Heading } from '@chakra-ui/react';
import {
  CodeComponent,
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import ContainerSection from './ContainerSection';
import Section from './Section';
import AnimatePresence from './atomics/AnimatePresence';
import { ShapeComponent } from './ShapeComponent';

export interface TitleProps
  extends KustomPageComponentViewProps<TextComponentData> {
  containerProps?: BoxProps;
}

const Title: React.FC<TitleProps> = ({ component, page, containerProps }) => {
  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const code = component.data.atomicComponents.find(
    (cmp) => cmp.type === 'CODE',
  ) as CodeComponent;

  return (
    <Section className={'title'}>
      <ContainerSection pb="0 !important" {...containerProps}>
        {code && <ShapeComponent code={code.code} />}
        <AnimatePresence opacity>
          <Heading as="h2" position="relative" zIndex="2">
            <RichText {...textBind} />
          </Heading>
        </AnimatePresence>
      </ContainerSection>
    </Section>
  );
};

export default Title;
