import {
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  TextImageComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Heading } from '@chakra-ui/react';

import AnimatePresence from '../atomics/AnimatePresence';
import { Parallax } from 'react-scroll-parallax';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import ContainerSection from '../ContainerSection';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import Section from '../Section';
import { SvgShape } from '../SvgShape';
import { ParallaxEl } from '../ParallaxEl';
import { useRef } from 'react';
import useIsMounted from '@/hooks/useIsMounted';
import { clamp, normalize } from '@/helpers/math';

const Section4 = ({
  component,
  page,
}: KustomPageComponentViewProps<TextImageComponentData>) => {
  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const textCpmId = component.data.atomicComponents?.findIndex(
    (cmp) => cmp.type === 'TEXT',
  );

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${textCpmId}.text`,
  );

  const mediaBind = useKustomData<KustomResponsiveMedia>(
    page,
    component,
    `data.media`,
  );

  const slide = {
    id: component.id,
    title: titleBind,
    text: textBind,
    media: mediaBind.value,
  };

  const device = useResponsiveMediasDevice();

  const isMounted = useIsMounted();

  const backgroundImgEl = useRef<HTMLDivElement>(null);
  function fadeBackgroundImg(progress: number) {
    let opacity = 0;
    if (progress < 0.5) {
      opacity = clamp(normalize(progress, 0, 0.3), 0, 1);
    } else {
      opacity = clamp(normalize(0.5 - progress + 0.5, 0, 0.4), 0, 1);
    }
    backgroundImgEl.current?.style.setProperty('opacity', String(opacity));
  }

  return (
    <>
      <Box
        className="section-4-bg"
        ref={backgroundImgEl}
        backgroundColor={'brand.500'}
        position={'fixed'}
        zIndex={-1}
        left={0}
        top={0}
        width={'100vw'}
        height={'100vh'}
        opacity={0}
        transition={'opacity 0.5s ease'}
      ></Box>
      <Section color={'brand.100'} className={'section-4'}>
        {isMounted && (
          <Parallax
            onChange={(e) => fadeBackgroundImg(e.progress)}
            style={{
              width: '0.5rem',
              position: 'absolute',
              zIndex: 10000,
              left: 0,
              top: '15%',
              bottom: '25%',
            }}
          >
            <Box w="100%" h="100%" background="black" opacity={0}></Box>
          </Parallax>
        )}
        {slide.media && (
          <ResponsiveMedias
            value={slide.media}
            currentDevice={device}
            style={{
              position: 'absolute',
              zIndex: 2,
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              background: 'var(--chakra-colors-brand-200)',
            }}
            fill
            width={undefined}
            height={undefined}
            skeletonColor={{
              base: '#efd9c9',
              highlight: '#F4E7DC',
            }}
          />
        )}
        <ContainerSection>
          {/* <Box
            width={['65%', null, null, '55%', '52%']}
            left="0"
            bottom="0"
            position="absolute"
            pointerEvents={'none'}
          >
            <ParallaxEl speed={2}>
              <Box
                transform={[
                  'rotate(-15deg) translate(-20%, -40%)',
                  null,
                  null,
                  'rotate(-15deg) translate(-17%, -15%)',
                ]}
              >
                <SvgShape shape={5} />
              </Box>
            </ParallaxEl>
          </Box> */}
          <Box
            width={['45%', null, null, '45%', '37%']}
            right="0"
            top="0"
            position="absolute"
            pointerEvents={'none'}
          >
            <ParallaxEl speed={5}>
              <Box
                transform={[
                  'rotate(180deg) translate(-30%, -50%)',
                  null,
                  null,
                  // 'rotate(-1deg) translate(15%, 80%)',
                  'rotate(-1deg) translate(15%, 100%)',
                ]}
              >
                <SvgShape shape={4} />
              </Box>
            </ParallaxEl>
          </Box>
          {/* <Box
            width={['55%', null, null, '56%', '52%']}
            right="0"
            bottom="0"
            position="absolute"
            pointerEvents={'none'}
          >
            <ParallaxEl speed={-5}>
              <SvgShape
                shape={3}
                style={{
                  transform: 'rotate(211deg) translate(-26%, -14%)',
                }}
              />
            </ParallaxEl>
          </Box> */}
          <Box
            position="relative"
            zIndex={2}
            pl={['0', null, null, null, '16%']}
            pt={['16', null, null, '14']}
            // mb={['55%', null, null, null, '30%']}
            mb={['20%', null, null, null, '10%']}
          >
            <AnimatePresence opacity>
              <Heading as="h2" color="brand.100">
                <RichText {...slide.title} />
              </Heading>
              <RichText {...slide.text} w={['100%', null, null, '41%']} />
            </AnimatePresence>
          </Box>
        </ContainerSection>
      </Section>
    </>
  );
};

export default Section4;
