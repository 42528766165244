import { Box, BoxProps, ResponsiveValue, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { KustomResponsiveMedia } from '@/lib/kustomcms-sdk/lib/types';
import MediasViewerContext from '@/contexts/MediasViewerContext';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import { Slide } from 'react-slideshow-image';
import dynamic from 'next/dynamic';
import getCSSAdminWysiwygItem from '@/lib/kustomcms-sdk/lib/admin/helpers/getCSSAdminWysiwygItem';
import { useKustomSelector } from '@/lib/kustomcms-sdk/lib/admin/hooks/useKustomSelector';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';

const DynamicEditMediasModal = dynamic(
  () => import('@/lib/kustomcms-sdk/lib/admin/components/EditMediasModal'),
);

interface SlideshowProps {
  value?: KustomResponsiveMedia[];
  duration?: number;
  height?: ResponsiveValue<number | string>;
  delay?: number;
  transitionDuration?: number;
  hideIndicators?: boolean;
  hideArrows?: boolean;
  hideCounter?: boolean;
  canSwipe?: boolean;
  onClickItem?: (e: React.MouseEvent, index: number) => void;
  style?: React.CSSProperties;
  usingMediaViewer?: boolean;
  containerStyle?: BoxProps;
  isHovered?: boolean;
  useOverlay?: boolean;
  sizes?: string;
  onChange?: (medias: KustomResponsiveMedia[]) => void;
  handleIsWysiwygEditOpen?: (isOpen: boolean) => void;
}

const Slideshow: React.FC<SlideshowProps> = ({
  value: medias,
  duration: _duration = 5000,
  height = 400,
  transitionDuration = 300,
  delay,
  hideIndicators = false,
  hideArrows = false,
  hideCounter = false,
  canSwipe = true,
  style,
  onClickItem,
  usingMediaViewer,
  containerStyle,
  isHovered,
  sizes,
  useOverlay = false,
  onChange,
  handleIsWysiwygEditOpen,
}) => {
  const currentDevice = useResponsiveMediasDevice();
  const [currentIndex, setCurrentIndex] = useState(0);

  const [duration, setDuration] = useState(
    delay ? delay + _duration : _duration,
  );

  const { user } = useKustomSelector((state) => state.app);

  const hasWysiwygEdit = !!(user && onChange);

  const [isWysiwygEditOpen, setIsWysiwygEditOpen] = useState(false);

  const closeWysiwygEdit = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setIsWysiwygEditOpen(false);
    if (handleIsWysiwygEditOpen) handleIsWysiwygEditOpen(false);
  };

  useEffect(() => {
    if (delay) {
      const timeout = setTimeout(() => {
        setDuration(_duration);
      }, delay + _duration);
      return () => clearTimeout(timeout);
    }
  }, [delay, _duration]);

  const mediasViewer = useContext(MediasViewerContext);

  const cursor = useRef<{
    start: number;
    isDown: boolean;
    delta: number;
  }>({
    start: 0,
    isDown: false,
    delta: 0,
  });

  function handleDown(e: React.MouseEvent<HTMLDivElement>) {
    cursor.current = {
      start: e.clientX,
      isDown: true,
      delta: 0,
    };
  }

  function handleUp() {
    cursor.current.isDown = false;
  }

  function handleMove(e: React.MouseEvent<HTMLDivElement>) {
    if (!cursor.current.isDown) return;
    cursor.current.delta = cursor.current.start - e.clientX;
  }

  const openMediaViewer = () => {
    if (Math.abs(cursor.current.delta) > 10) return;
    if (usingMediaViewer && medias) {
      mediasViewer.setMedias(medias);
      mediasViewer.setIndex(currentIndex);
    }
  };

  if (!medias) return null;

  const disableSlide = medias.length <= 1;

  return (
    <Box
      position="relative"
      w={containerStyle?.w || containerStyle?.width || '100%'}
      h={containerStyle?.h || containerStyle?.height || '100%'}
      role={hasWysiwygEdit ? 'group' : undefined}
    >
      <Box
        position="relative"
        className="SlideshowComponent"
        height={'100%'}
        overflow={hasWysiwygEdit ? undefined : 'clip'}
        cursor={onClickItem || usingMediaViewer ? 'pointer' : 'default'}
        onClick={(e) => {
          if (onClickItem) onClickItem(e, currentIndex);
        }}
        onMouseDown={handleDown}
        onMouseUp={handleUp}
        onMouseLeave={handleUp}
        onMouseMove={handleMove}
        role="group"
        {...getCSSAdminWysiwygItem({
          hasWysiwygEdit,
          isWysiwygEditOpen,
          // setIsOpen: setIsWysiwygEditOpen,
          setIsOpen: (isOpen) => {
            setIsWysiwygEditOpen(isOpen);
            if (handleIsWysiwygEditOpen) handleIsWysiwygEditOpen(!!isOpen);
          },
        })}
        {...containerStyle}
      >
        <Box
          {...(hasWysiwygEdit && {
            pointerEvents: 'none',
          })}
          height={'100%'}
        >
          <Slide
            key={medias.map((m) => m.id).join('')}
            duration={duration}
            transitionDuration={transitionDuration}
            easing="ease"
            infinite={!disableSlide && canSwipe}
            canSwipe={!disableSlide && canSwipe}
            pauseOnHover={false}
            autoplay={!disableSlide && !isHovered}
            onChange={(oldIndex, newIndex) => setCurrentIndex(newIndex)}
            indicators={
              !hideIndicators &&
              !disableSlide &&
              ((index) => (
                <Box
                  position="relative"
                  bottom="0"
                  className="slideshow-indicator indicator"
                  py={4}
                  mr={'0.2rem'}
                >
                  <Box
                    position={'relative'}
                    h={'1.5'}
                    borderRadius="base"
                    overflow={'clip'}
                    // bgColor="rgba(255, 255, 255, 0.6)"
                    // bgColor="brand.500"
                    _before={{
                      content: '""',
                      position: 'absolute',
                      zIndex: 1,
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      // bgColor: 'brand.500',
                      bgColor: '#fff',
                      opacity: 0.25,
                    }}
                  >
                    <Box
                      position="relative"
                      zIndex="3"
                      className="progress-bar"
                      // bgColor="brand.500"
                      bgColor="#fff"
                      h="100%"
                      style={
                        {
                          '--slideshow-duration': duration * 0.97 + 'ms',
                        } as React.CSSProperties
                      }
                    ></Box>
                  </Box>
                </Box>
              ))
            }
            prevArrow={!hideArrows ? <Box>prev</Box> : <></>}
            nextArrow={!hideArrows ? <Box>prev</Box> : <></>}
          >
            {medias
              // .map((m) => getResponsiveMedia(m, currentDevice))
              .map((media, index) => {
                // const isVideo =
                // media?.filename && getMediaType(media?.filename) === MediaType.VIDEO;

                return (
                  <Box
                    key={media.id}
                    // height={height}
                    w="100%"
                    overflow="clip"
                    position="relative"
                    onClick={openMediaViewer}
                    style={style}
                    zIndex={index === currentIndex ? 2 : 0}
                    role="group"
                    height="100%"
                  >
                    <ResponsiveMedias
                      key={index}
                      value={media}
                      currentDevice={currentDevice}
                      fill
                      width={undefined}
                      height={undefined}
                      sizes={sizes}
                      style={{
                        objectFit: 'cover',
                        background: 'var(--chakra-colors-brand-200)',
                      }}
                      skeletonColor={{
                        base: '#efd9c9',
                        highlight: '#F4E7DC',
                      }}
                    />
                    {useOverlay && (
                      <Box
                        position="absolute"
                        top="0"
                        left="0"
                        bottom="0"
                        right="0"
                        bgColor="rgba(0, 0, 0)"
                        opacity={0.1}
                        transition="opacity 0.3s"
                        _groupHover={{
                          opacity: 0,
                        }}
                      ></Box>
                    )}
                  </Box>
                );
              })}
          </Slide>
        </Box>

        <Box
          display={hideCounter ? 'none' : 'block'}
          position="absolute"
          right={2}
          bottom={2}
          bgColor="rgba(0, 0, 0, 0.25)"
          borderRadius="base"
          px={2.5}
          py={1}
        >
          <Text
            fontFamily="commutersSansLight"
            color="white"
            fontSize="12px"
            fontWeight={700}
          >
            {currentIndex + 1} / {medias.length}
          </Text>
        </Box>
      </Box>
      {hasWysiwygEdit && (
        <DynamicEditMediasModal
          isOpen={isWysiwygEditOpen}
          onClose={closeWysiwygEdit}
          value={medias}
          onChange={onChange}
          triggerProps={{
            cursor: 'pointer',
            onClick: () => {
              setIsWysiwygEditOpen((io) => {
                if (handleIsWysiwygEditOpen) handleIsWysiwygEditOpen(!io);
                return !io;
              });
            },
          }}
        />
      )}
    </Box>
  );
};

export default Slideshow;
