import theme from '../theme';

const breakpoints = Object.values(theme.breakpointsMax);

const chakraResponsiveToImageSizes = (
  responsiveSizes: (string | number | null)[],
) => {
  const getIndex = (index: number): number | null => {
    if (index >= breakpoints.length - 1) {
      return null;
    }
    if (index + 1 >= breakpoints.length) {
      return index;
    }
    if (
      responsiveSizes[index + 1] === null ||
      responsiveSizes[index + 1] === undefined
    ) {
      return getIndex(index + 1);
    } else {
      return index + 1;
    }
  };

  const sizes = responsiveSizes.reduce((acc: string, size, index) => {
    const s = typeof size === 'number' ? `${size}px` : size;
    if (
      s === null ||
      index > responsiveSizes.length - 1 ||
      index > breakpoints.length - 1
    )
      return acc;

    const breakpointsIndex = getIndex(index);

    return (
      acc +
      (acc.length ? ',' : '') +
      (index === responsiveSizes.length - 1
        ? `${s}`
        : breakpointsIndex
        ? `(max-width: ${breakpoints[breakpointsIndex]}) ${s}`
        : `${s}`)
    );
  }, '');

  return sizes;
};

// const chakraResponsiveToImageSizes = (
//   responsiveSizes: (string | number | null)[],
// ) => {
//   const sizes = responsiveSizes.reduce((acc: string, size, index) => {
//     const s = typeof size === 'number' ? `${size}px` : size;

//     if (s === null) return acc;

//     return (
//       acc +
//       (acc.length ? ',' : '') +
//       (index === responsiveSizes.length - 1
//         ? `${s}`
//         : `(min-width: ${breakpoints[index]}) ${s}`)
//     );
//   }, '');

//   return sizes;
// };

export default chakraResponsiveToImageSizes;
