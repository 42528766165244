import { Box, useBreakpointValue } from '@chakra-ui/react';
import { Parallax } from 'react-scroll-parallax';

type ParallaxElProps = {
  children: React.ReactNode;
  speed?: number;
};

export function ParallaxEl({ children, speed = 0 }: Readonly<ParallaxElProps>) {
  const speedFactor = useBreakpointValue({
    base: 0.5,
    md: 0.7,
    lg: 1,
  });
  return (
    <Parallax
      speed={speed * (speedFactor || 1)}
      style={{ width: '100%', height: '100%' }}
    >
      {children}
    </Parallax>
  );
}
