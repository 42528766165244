import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Box,
} from '@chakra-ui/react';
import {
  AccordionComponentData,
  KustomPageComponentViewProps,
  KustomTranslatedString,
} from '@/lib/kustomcms-sdk/lib/types';

import React, { useRef } from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import { IconSvg } from './IconSvg';
import useDisablePageScroll from '@/hooks/useDisablePageScroll';

interface AccordionComponentProps
  extends KustomPageComponentViewProps<AccordionComponentData> {
  containerProps?: AccordionProps;
}

const AccordionComponent: React.FC<AccordionComponentProps> = ({
  page,
  component,
  containerProps,
}) => {
  const slides = component.data.slides;

  // const [isOpenning, setIsOpenning] = React.useState(false);
  // useDisablePageScroll(isOpenning);
  // const timer = useRef<NodeJS.Timeout | null>(null);

  return (
    <Accordion
      allowToggle
      /* allowMultiple */
      {...containerProps}
      defaultIndex={[0]}
    >
      {slides.map((slide, index) => (
        <AccordionComponentItem
          key={slide.id}
          page={page}
          component={component}
          isLastSlide={index === slides.length - 1}
          slideIndex={index}
        />
      ))}
    </Accordion>
  );
};

interface AccordionComponentItemProps
  extends KustomPageComponentViewProps<AccordionComponentData> {
  slideIndex: number;
  isLastSlide?: boolean;
}

const AccordionComponentItem: React.FC<AccordionComponentItemProps> = ({
  page,
  component,
  slideIndex,
  isLastSlide,
}) => {
  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.text`,
  );

  const hiddenTextBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.hiddenText`,
  );

  const itemRef = useRef<HTMLDivElement>(null);

  return (
    <AccordionItem
      borderColor="gray.400"
      borderTop={slideIndex === 0 ? 'none' : undefined}
      borderBottom={isLastSlide ? 'none' : undefined}
      // onClick={(e) => {
      // e.target.scrollIntoView();
      // }}
      ref={itemRef}
    >
      <h2>
        <AccordionButton
          _hover={{
            bg: 'transparent',
          }}
          sx={{ '--rotation': '90deg' }}
          _active={{
            bg: 'transparent',
          }}
          _expanded={{
            bg: 'transparent',
            '--rotation': '-90deg',
          }}
          px="0"
          pt="clamp(1rem, 2vw, 2.25rem)"
        >
          <Box
            as="span"
            flex="1"
            textAlign="left"
            py=".75rem"
            display="flex"
            justifyContent={'space-between'}
            gap={'1rem'}
          >
            <TranslatedString fontFamily="commutersSansBold" {...textBind} />
            <Box
              color={'brand.500'}
              display={'flex'}
              flex={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <IconSvg
                icon="right"
                size="1.25rem"
                transform={'rotate(var(--rotation, 90deg))'}
              />
            </Box>
          </Box>
          <AccordionIcon color="brand.500" />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4} px={0}>
        <RichText {...hiddenTextBind} />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default AccordionComponent;
