import { Box, Heading } from '@chakra-ui/react';
import {
  AccordionComponentData,
  ActionButtonComponent,
  CarouselComponentData,
  CodeComponent,
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React from 'react';

import AnimatePresence from '../atomics/AnimatePresence';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useKustomData, {
  useKustomTranslation,
} from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import ContainerSection from '../ContainerSection';
import ActionButton from '@/components/atomics/ActionButton';
import { IconSvg } from '../IconSvg';
import Section from '../Section';
import { SvgShape } from '../SvgShape';
import { ParallaxEl } from '../ParallaxEl';
import AccordionComponent from '../AccordionComponent';

const FAQ: React.FC<KustomPageComponentViewProps<TextComponentData>> = ({
  component,
  page,
}) => {
  const carrouselFAQCmp = usePageComponent<AccordionComponentData>(
    page,
    null,
    '16849333171951256',
  );

  const carrouselInfoCmp = usePageComponent<CarouselComponentData>(
    page,
    null,
    '16921823951555462',
  );

  const buttonIndex = component.data.atomicComponents.findIndex(
    (cmp) => cmp.type === 'ACTION_BUTTON',
  );

  const buttonBind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${buttonIndex}`,
  );

  const slides2 = carrouselInfoCmp?.data.slides || [];

  const titleBind = useKustomTranslation('faq_practical_information');

  return (
    <Section className={'section-faq'}>
      <ContainerSection>
        <Heading as="h2" textAlign={'center'} position={'relative'} zIndex={2}>
          <TranslatedString {...titleBind} />
        </Heading>
        <Box
          display="flex"
          width={'100%'}
          flexDir={['column', null, null, 'row']}
          justifyContent="space-between"
          alignItems="start"
          position={'relative'}
        >
          <Box
            width={['50%', null, null, '38%']}
            left="50%"
            top={'0'}
            position="absolute"
            pointerEvents={'none'}
          >
            <ParallaxEl speed={-6}>
              <Box
                transform={[
                  'translate(20%, -10%) rotate(210deg)',
                  null,
                  null,
                  'translate(-10%, -56%) rotate(210deg)',
                ]}
              >
                <SvgShape shape={4} />
              </Box>
            </ParallaxEl>
          </Box>
          <Box
            w={[null, null, null, '50%']}
            mt={['clamp(2rem, 2.5vw, 3rem)', null, null]}
            position={'relative'}
            zIndex={2}
            width={'100%'}
          >
            <AnimatePresence opacity>
              {carrouselFAQCmp && (
                <AccordionComponent
                  component={carrouselFAQCmp}
                  page={page}
                  index={0}
                />
              )}
              {buttonBind && (
                <Box mt="clamp(2rem, 2.5vw, 3rem)">
                  <ActionButton variant="link" {...buttonBind} />
                </Box>
              )}
            </AnimatePresence>
          </Box>
          {carrouselInfoCmp && (
            <Box
              w={[null, null, null, '41%']}
              display={'flex'}
              flexDirection={'column'}
              gap={'0.75rem'}
              order={['-1', null, null, '2']}
              pt={['clamp(1rem, 2vw, 2.25rem)', null, null]}
              position={'relative'}
              zIndex={2}
            >
              {slides2?.map((item, index) => (
                <FaqPanelSlide
                  key={item.id}
                  page={page}
                  component={carrouselInfoCmp}
                  slideIndex={index}
                />
              ))}
            </Box>
          )}
        </Box>
      </ContainerSection>
    </Section>
  );
};

interface FaqPanelSlideProps
  extends KustomPageComponentViewProps<CarouselComponentData> {
  slideIndex: number;
}

const FaqPanelSlide: React.FC<FaqPanelSlideProps> = ({
  page,
  component,
  slideIndex,
}) => {
  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.title`,
  );

  const subtitleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.subtitle`,
  );

  const icon = component.data.slides[slideIndex]?.atomicComponents.find(
    (cmp) => cmp.type === 'CODE',
  ) as CodeComponent | undefined;

  return (
    <Box
      bg="white"
      p="9"
      boxShadow={'0 0 10px 0 rgba(0,0,0,.05)'}
      borderRadius={'lg'}
    >
      {icon?.code && (
        <IconSvg icon={icon?.code} size="2.5rem" color={'mint.700'} />
      )}
      <Heading as="h3" variant={'h4'} mb="2" mt="4">
        <TranslatedString {...titleBind} color="gray.500" />
      </Heading>

      <TranslatedString {...subtitleBind} />
    </Box>
  );
};

export default FAQ;
