import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import { useKustomTranslation } from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import { Box, ChakraProps, Text, chakra } from '@chakra-ui/react';
import { useContext } from 'react';

type ContactDiadaoProps = ChakraProps & {};

export function ContactDiadao({ ...props }: Readonly<ContactDiadaoProps>) {
  const { settings } = useContext(SettingsContext);

  const phoneNumber = settings?.informations?.phone;
  const mail = settings?.informations?.mainEmail;

  const titleBind = useKustomTranslation('contact_a_question_a_problem');
  const orBind = useKustomTranslation('contact_or');

  return (
    <Box
      fontFamily="commutersSansLight"
      fontSize="0.7rem"
      textAlign="center"
      {...props}
    >
      <TranslatedString color="gray.500" fontWeight="bold" {...titleBind} />
      <Box mt={0.5}>
        <Text color="gray.500">
          {phoneNumber && (
            <chakra.a
              href={'tel:' + phoneNumber}
              color="brand.500"
              textDecoration="none !important"
            >
              {phoneNumber}
            </chakra.a>
          )}
          {phoneNumber && mail && (
            <>
              {' '}
              <TranslatedString as="span" display="inline" {...orBind} />{' '}
            </>
          )}
          {mail && (
            <chakra.a
              href={
                'mailto:' +
                mail +
                '?subject=%E2%9C%85 Demande d’informations (site officiel)'
              }
              color="brand.500"
              whiteSpace="nowrap"
              textDecoration="none !important"
            >
              {mail}
            </chakra.a>
          )}
        </Text>
      </Box>
    </Box>
  );
}
