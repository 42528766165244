import {
  ActionButtonComponent,
  CarouselComponentSlide,
  CodeComponent,
  KustomPage,
  KustomPageComponent,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, BoxProps, Heading } from '@chakra-ui/react';

import ActionButton, { extractValues } from '@/components/atomics/ActionButton';
import React, { useContext } from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import Slideshow from './atomics/Slideshow';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import checkTranslatedStringValues from '@/lib/kustomcms-sdk/lib/helpers/checkTranslatedStringValues';
import kustomDataMediasToSlidesTransformOptions from '@/lib/kustomcms-sdk/lib/helpers/kustomDataMediasToSlidesTransformOptions';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import ContainerSection from './ContainerSection';
import Section from './Section';
import AnimatePresence from './atomics/AnimatePresence';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import { ParallaxEl } from './ParallaxEl';
import { ShapeComponent } from './ShapeComponent';

type ExtraElement = React.ComponentType<{
  page: KustomPage;
  component: KustomPageComponent<TextCarouselComponentData>;
}>;

export interface TextMediasSectionProps
  extends KustomPageComponentViewProps<TextCarouselComponentData> {
  CentralExtraElement?: ExtraElement;
  SideExtraElement?: ExtraElement;
  containerProps?: BoxProps;
  containerTextProps?: BoxProps;
}

const TextMediasSection: React.FC<TextMediasSectionProps> = ({
  component,
  page,
  containerProps,
  containerTextProps,
  CentralExtraElement,
  SideExtraElement,
}) => {
  const { currentLang } = useContext(LanguageContext);

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textTitle`,
  );

  const subtitleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textSubtitle`,
  );

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const hasText =
    [titleBind.value, subtitleBind.value, textBind.value].filter(
      (t) => t?.fr || t?.en || t?.es,
    ).length > 0;

  const mediasBind = useKustomData<
    CarouselComponentSlide[],
    KustomResponsiveMedia[]
  >(page, component, `data.slides`, kustomDataMediasToSlidesTransformOptions);

  const buttonsIndex = component.data.atomicComponents
    .map((cmp, index) =>
      cmp.type === 'ACTION_BUTTON' ? { index, id: cmp.id } : undefined,
    )
    .filter((i) => i !== undefined) as { id: string; index: number }[];

  const code = component.data.atomicComponents.find(
    (cmp) => cmp.type === 'CODE',
  ) as CodeComponent;

  const align = code?.code?.includes('text-right') ? 'right' : 'left';

  return (
    <Section className={'section-text-medias'}>
      <ContainerSection {...containerProps}>
        {code && <ShapeComponent code={code.code} />}
        <Box
          display={['block', null, null, 'flex']}
          justifyContent="space-between"
          // alignItems="start"
          alignItems="center"
        >
          {hasText && (
            <Box
              position="relative"
              zIndex={2}
              width={['100%', null, null, '37%']}
              order={align === 'left' ? 0 : 1}
              // pt={['0', null, null, '12', '16']}
              {...containerTextProps}
            >
              <AnimatePresence opacity>
                <Heading
                  as="h2"
                  marginBottom={
                    subtitleBind?.value?.[currentLang] !== ''
                      ? '0.5rem'
                      : undefined
                  }
                >
                  <TranslatedString {...titleBind} color="brand.500" />
                </Heading>
                {subtitleBind?.value?.[currentLang] !== '' ? (
                  <Heading as="h3" variant="h5">
                    <RichText {...subtitleBind} color="brand.500" />
                  </Heading>
                ) : null}
                {checkTranslatedStringValues(textBind.value) && (
                  <RichText {...textBind} />
                )}
                {!!buttonsIndex.length && (
                  <Box
                    mt="clamp(2rem, 2.5vw, 3rem)"
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                    gap={'4'}
                  >
                    {buttonsIndex.map(({ index: buttonIndex, id }, index) => (
                      <TextMediasSectionButton
                        component={component}
                        page={page}
                        key={id}
                        buttonIndex={buttonIndex}
                      />
                    ))}
                  </Box>
                )}
                {CentralExtraElement && (
                  <CentralExtraElement page={page} component={component} />
                )}
              </AnimatePresence>
            </Box>
          )}

          {SideExtraElement && (
            <Box
              width={['100%', null, null, '57%']}
              mt={['3rem', null, null, '0']}
            >
              <SideExtraElement page={page} component={component} />
            </Box>
          )}

          {!SideExtraElement && mediasBind.value?.length ? (
            <Box
              width={['100%', null, null, '57%']}
              mt={['3rem', null, null, '0']}
              aspectRatio={4 / 3}
            >
              <ParallaxEl speed={5}>
                <Slideshow
                  usingMediaViewer
                  {...mediasBind}
                  hideArrows
                  hideCounter
                  canSwipe={true}
                  height={'100%'}
                  containerStyle={{
                    borderRadius: 'base',
                  }}
                  // sizes="(max-width: 959px) 95vw, (max-width: 1919px) 45vw, 920px"
                  sizes={chakraResponsiveToImageSizes([
                    '95vw',
                    null,
                    null,
                    '45vw',
                    null,
                    '920px',
                  ])}
                />
                {!!buttonsIndex.length && (
                  <Box
                    mt="0.5rem"
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                    gap={'4'}
                  >
                    {buttonsIndex.map(({ index: buttonIndex, id }, index) => (
                      <TextMediasSectionButton
                        component={component}
                        page={page}
                        key={id}
                        buttonIndex={buttonIndex}
                        container="right"
                      />
                    ))}
                  </Box>
                )}
              </ParallaxEl>
            </Box>
          ) : null}
        </Box>
      </ContainerSection>
    </Section>
  );
};

interface TextMediasSectionButtonProps
  extends KustomPageComponentViewProps<TextCarouselComponentData> {
  buttonIndex: number;
  container?: 'left' | 'right';
}

const TextMediasSectionButton: React.FC<TextMediasSectionButtonProps> = ({
  component,
  page,
  buttonIndex,
  container = 'left',
}) => {
  const buttonBind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${buttonIndex}`,
  );

  const { currentLang } = useContext(LanguageContext);
  const labelData = buttonBind?.value?.label?.[currentLang];

  if (!labelData) return null;

  const extractedValues = extractValues(labelData, ['position']);
  const position = extractedValues.position || 'left';

  if (position !== container) return null;

  return <ActionButton variant="button" {...buttonBind} />;
};

export default TextMediasSection;
