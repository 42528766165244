import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Basteleur';
        font-style: normal;
        font-weight: 300;
        src: url('/assets/fonts/basteleur/basteleur-moonlight.woff2') format('woff2');
      }
      @font-face {
        font-family: 'CommutersSansLight';
        font-style: normal;
        font-weight: 300;
        src: url('/assets/fonts/commuters/commuters-sans-light.woff2') format('woff2');
      }
      @font-face {
        font-family: 'CommutersSansBold';
        font-style: normal;
        font-weight: 600;
        src: url('/assets/fonts/commuters/commuters-sans-semibold.woff2') format('woff2');
      }
      `}
  />
);

export default Fonts;
