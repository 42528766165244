import { Box, Fade, Grid, GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import {
  KustomMedia,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  MediasComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useState } from 'react';

import MediasViewerContext from '@/contexts/MediasViewerContext';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import Slideshow from '../atomics/Slideshow';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import dynamic from 'next/dynamic';
import getCSSAdminWysiwygItem from '@/lib/kustomcms-sdk/lib/admin/helpers/getCSSAdminWysiwygItem';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import { useKustomSelector } from '@/lib/kustomcms-sdk/lib/admin/hooks/useKustomSelector';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import ContainerSection from '../ContainerSection';

const DynamicEditMediasModal = dynamic(
  () => import('@/lib/kustomcms-sdk/lib/admin/components/EditMediasModal'),
);

const N_ITEMS = 3;

const RoomMedias: React.FC<
  KustomPageComponentViewProps<MediasComponentData>
> = ({ component, page }) => {
  const mediasViewerContext = useContext(MediasViewerContext);

  const { value: medias, onChange } = useKustomData<KustomResponsiveMedia[]>(
    page,
    component,
    'data.medias',
  );

  const deviceName = useResponsiveMediasDevice();

  const openMediaViewer = (index: number = 0) => {
    if (!medias) return;
    mediasViewerContext.setMedias(medias);
    mediasViewerContext.setIndex(index);
  };

  const bpIndex = useBreakpointIndex();

  const { user } = useKustomSelector((state) => state.app);

  const hasWysiwygEdit = !!(user && onChange);

  const [isWysiwygEditOpen, setIsWysiwygEditOpen] = useState(false);

  const closeWysiwygEdit = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setIsWysiwygEditOpen(false);
  };

  return (
    <Box
      display="flex"
      boxSizing="content-box"
      position="relative"
      aspectRatio={['16 / 9', null, null, '2 / 1']}
      {...getCSSAdminWysiwygItem({
        hasWysiwygEdit,
        isWysiwygEditOpen,
        setIsOpen: setIsWysiwygEditOpen,
      })}
    >
      <Box
        display="flex"
        w="100%"
        {...(hasWysiwygEdit && {
          pointerEvents: 'none',
        })}
      >
        {bpIndex < 3 ? (
          <SimpleGrid w="100%" height="100%" columns={1} position="relative">
            <Slideshow
              usingMediaViewer
              hideCounter
              value={medias}
              hideArrows
              height="100%"
              delay={700}
              sizes="50vw"
              style={{
                transition: 'transform 0.3s ease',
                // transform: isHovered ? 'scale(1.2)' : 'scale(1)',
              }}
            />
          </SimpleGrid>
        ) : (
          <Box display="grid" gridTemplateColumns="2fr 1fr" w="100%">
            <Box
              flex={1}
              position="relative"
              mr={2}
              onClick={() => openMediaViewer(0)}
              cursor="pointer"
            >
              {medias?.[0] && (
                <ResponsiveMedias
                  value={medias?.[0]}
                  currentDevice={deviceName}
                  style={{ borderRadius: '4px', objectFit: 'cover' }}
                  fill
                  width={undefined}
                  height={undefined}
                  skeletonColor={{
                    base: '#efd9c9',
                    highlight: '#F4E7DC',
                  }}
                />
              )}
            </Box>

            <Grid
              templateRows="repeat(2, 1fr)"
              templateColumns="repeat(1, 1fr)"
              gap="2"
            >
              {medias?.slice(1, N_ITEMS).map((media, index) => (
                <GridItem
                  key={media.id}
                  colSpan={[1, 1][index]}
                  rowSpan={[1, 1][index]}
                  position="relative"
                  cursor="pointer"
                  onClick={() => openMediaViewer(index + 1)}
                >
                  <ResponsiveMedias
                    value={media}
                    skeleton={false}
                    currentDevice={deviceName}
                    style={{ borderRadius: '4px', objectFit: 'cover' }}
                    fill
                    // sizes={chakraResponsiveToImageSizes([
                    //   null,
                    //   null,
                    //   '240px',
                    //   null,
                    //   '300px',
                    // ])}
                    width={undefined}
                    height={undefined}
                    skeletonColor={{
                      base: '#efd9c9',
                      highlight: '#F4E7DC',
                    }}
                  />
                  {index === N_ITEMS - 2 && medias?.length > N_ITEMS && (
                    <Box
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      bottom={0}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="base"
                      overflow="clip"
                    >
                      <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        bgColor="brand.500"
                        opacity={0.53}
                      ></Box>
                      <Text
                        zIndex={1}
                        color="white"
                        fontFamily="basteleur"
                        fontSize={[null, null, '1.5rem', '2rem', '2.5rem']}
                      >
                        +{medias?.length - N_ITEMS + 1}
                      </Text>
                    </Box>
                  )}
                </GridItem>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
      {hasWysiwygEdit && (
        <DynamicEditMediasModal
          isOpen={isWysiwygEditOpen}
          onClose={closeWysiwygEdit}
          value={medias || []}
          onChange={onChange}
          triggerProps={{
            cursor: 'pointer',
            onClick: (e) => {
              e.stopPropagation();
              setIsWysiwygEditOpen((io) => !io);
            },
          }}
        />
      )}
    </Box>
  );
};

export default RoomMedias;
