import { Box, ChakraStyledOptions } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { ParallaxEl } from './ParallaxEl';
import { SvgShape } from './SvgShape';
import { CodeComponent } from '@/lib/kustomcms-sdk/lib/types';

type ShapeComponentProps = {
  code: string;
};

export function ShapeComponent({ code }: Readonly<ShapeComponentProps>) {
  const shapes: { [key: string]: React.FC } = {
    'shape-right-1': ParallaxShapeRight1,
    'shape-right-2': ParallaxShapeRight2,
    'shape-right-3': ParallaxShapeRight3,
    'shape-left-1': ParallaxShapeLeft1,
    'shape-left-2': ParallaxShapeLeft2,
    'shape-left-3': ParallaxShapeLeft3,
  };

  const shape = code.match(/shape-[^ ]+/)?.[0];

  if (!shape || !shapes[shape]) return null;

  const ShapeCpm: React.FC = shapes[shape]!;

  return <ShapeCpm />;
}

const ParallaxShapeRight1: React.FC = () => {
  return (
    <Box
      width={['70%', null, null, '45%']}
      right="0"
      bottom="0"
      position="absolute"
      // zIndex="-1"
      pointerEvents={'none'}
    >
      <ParallaxEl speed={-6}>
        <SvgShape
          shape={3}
          style={{
            transform: 'translate(20%, 0)',
          }}
        />
      </ParallaxEl>
    </Box>
  );
};

const ParallaxShapeRight2: React.FC = () => {
  return (
    <Box
      width={['22%', null, null, '16%']}
      right="0"
      bottom={'0'}
      position="absolute"
      zIndex="2"
      pointerEvents={'none'}
    >
      <ParallaxEl speed={10}>
        <SvgShape
          shape={2}
          style={{
            transform: 'translate(20%, -150%) rotate(130deg)',
          }}
        />
      </ParallaxEl>
    </Box>
  );
};

const ParallaxShapeRight3: React.FC = () => {
  return (
    <Box
      width={['45%', null, null, '40%']}
      right="0"
      top="0"
      position="absolute"
      // zIndex="-1"
      pointerEvents={'none'}
    >
      <ParallaxEl speed={-6}>
        <SvgShape
          shape={4}
          style={{
            transform: 'translate(15%, 20%)',
          }}
        />
      </ParallaxEl>
    </Box>
  );
};

const ParallaxShapeLeft1: React.FC = () => {
  return (
    <Box
      width={['90%', null, null, '55%']}
      left="0"
      bottom="0"
      position="absolute"
      // zIndex="-1"
      pointerEvents={'none'}
    >
      <ParallaxEl speed={-5}>
        <SvgShape
          shape={1}
          style={{
            transform: 'translate(-22%, -15%)',
          }}
        />
      </ParallaxEl>
    </Box>
  );
};

const ParallaxShapeLeft2: React.FC = () => {
  return (
    <Box
      width={['30%', null, null, '23%']}
      left="0"
      bottom="0"
      position="absolute"
      // zIndex="-1"
      pointerEvents={'none'}
    >
      <ParallaxEl speed={-5}>
        <SvgShape
          shape={2}
          style={{
            transform: 'translate(-50%,-10%) rotate(19deg)',
          }}
        />
      </ParallaxEl>
    </Box>
  );
};

const ParallaxShapeLeft3: React.FC = () => {
  return (
    <Box
      width={['80%', null, null, '45%']}
      left="0"
      bottom="0"
      position="absolute"
      // zIndex="-1"
      pointerEvents={'none'}
    >
      <ParallaxEl speed={-5}>
        <SvgShape
          shape={5}
          style={{
            transform: 'translate(-16%, 0) rotate(345deg)',
          }}
        />
      </ParallaxEl>
    </Box>
  );
};
