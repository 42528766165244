import { Box, Container, Heading } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import React, { useContext, useRef } from 'react';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import Section from './Section';
import { Parallax } from 'react-scroll-parallax';
import { clamp, normalize } from '@/helpers/math';
import useIsMounted from '@/hooks/useIsMounted';
import ContainerSection from './ContainerSection';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import { ParallaxEl } from './ParallaxEl';
import { SvgShape } from './SvgShape';
import AnimatePresence from './atomics/AnimatePresence';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';

const TextMediaBackground: React.FC<
  KustomPageComponentViewProps<TextCarouselComponentData>
> = ({ component, page }) => {
  const { currentLang } = useContext(LanguageContext);

  const media = component.data?.slides[0]?.medias;

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textTitle`,
  );

  const subtitleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textSubtitle`,
  );

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const currentDevice = useResponsiveMediasDevice();

  const isMounted = useIsMounted();

  const backgroundImgEl = useRef<HTMLDivElement>(null);
  const shapeEl1 = useRef<HTMLDivElement>(null);
  const shapeEl2 = useRef<HTMLDivElement>(null);
  function fadeBackgroundImg(progress: number) {
    let opacity = 0;
    if (progress < 0.5) {
      opacity = clamp(normalize(progress, 0, 0.3), 0, 1);
    } else {
      opacity = clamp(normalize(0.5 - progress + 0.5, 0, 0.4), 0, 1);
    }
    if (progress > 0.99 || progress < 0.01) {
      backgroundImgEl.current?.style.setProperty('pointer-events', 'none');
    } else {
      backgroundImgEl.current?.style.setProperty('pointer-events', 'auto');
    }
    backgroundImgEl.current?.style.setProperty('opacity', String(opacity));
    shapeEl1.current?.style.setProperty('opacity', String(opacity));
    shapeEl2.current?.style.setProperty('opacity', String(opacity));
  }

  return (
    <Section color="brand.100">
      {isMounted && (
        <Parallax
          onChange={(e) => fadeBackgroundImg(e.progress)}
          style={{
            width: '0.5rem',
            position: 'absolute',
            zIndex: 10000,
            left: 0,
            top: '30%',
            bottom: '30%',
          }}
        >
          <Box w="100%" h="100%" background="black" opacity={0}></Box>
        </Parallax>
      )}
      <ContainerSection>
        <Box
          ref={backgroundImgEl}
          position="fixed"
          zIndex="0"
          left="0"
          top="0"
          width="100%"
          height="100%"
          opacity={0}
          transition={'opacity 0.25s linear'}
          className="section-6-bg"
          backgroundColor="mint.900"
          pointerEvents={'none'}
        >
          <Box
            w="100%"
            h="100%"
            maxH="100svh"
            position="absolute"
            zIndex={2}
            left={0}
            top={0}
            background={'rgba(0,0,0,0.3)'}
            pointerEvents={'none'}
          />
          {media && (
            <ResponsiveMedias
              value={media}
              disableCSSAdminWysiwyg
              currentDevice={currentDevice}
              quality={50}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                background: 'var(--chakra-colors-mint-900)',
              }}
              fill
              width={undefined}
              height={undefined}
              sizes={chakraResponsiveToImageSizes([
                '300vw',
                null,
                '200vw',
                '100vw',
              ])}
              skeletonColor={{
                base: '#efd9c9',
                highlight: '#F4E7DC',
              }}
            />
          )}
        </Box>
        <Box
          ref={shapeEl1}
          top="0"
          right="0"
          position="absolute"
          zIndex="2"
          w={['90%', '70%', null, '50%']}
          pointerEvents={'none'}
        >
          <ParallaxEl speed={15}>
            <SvgShape
              shape={3}
              dark
              style={{ transform: ' translate(45%, 10%) rotate(290deg)' }}
            />
          </ParallaxEl>
        </Box>
        <Box
          ref={shapeEl2}
          bottom="0"
          left="0"
          position="absolute"
          zIndex="2"
          w={['90%', '70%', null, '50%']}
          pointerEvents={'none'}
        >
          <ParallaxEl speed={18}>
            <SvgShape
              shape={3}
              dark
              style={{
                transform: 'translate(-60%, -10%) rotate(222deg)',
              }}
            />
          </ParallaxEl>
        </Box>

        <Box
          position="relative"
          zIndex={3}
          ml={['0', null, null, null, 'var(--grid-s-5)']}
          mt={['16', null, null, '14', '24']}
          mb={['60%', '40%', null, '25%', '25%']}
        >
          <AnimatePresence opacity>
            <Heading
              as="h2"
              color="brand.100"
              marginBottom={
                subtitleBind?.value?.[currentLang] !== ''
                  ? '0.75rem'
                  : undefined
              }
            >
              <RichText {...titleBind} />
            </Heading>
            {subtitleBind?.value?.[currentLang] !== '' ? (
              <Heading as="h3" variant="h5">
                <RichText {...subtitleBind} color="brand.100" />
              </Heading>
            ) : null}
            <RichText {...textBind} w={['100%', null, null, '75%']} />
          </AnimatePresence>
        </Box>

        {/* <Container maxW="container.lg" position="relative" color="white" px={10}>
        <TranslatedString
          fontFamily="heading"
          color="white"
          fontSize={['38px', null, '96px', null, null, '120px']}
          {...titleBind}
        />
        <TranslatedString
          mb={[6, null, 10]}
          mt={[4, null, 0]}
          ml={6}
          fontFamily="commutersSansBold"
          fontSize={['18px', null, '22px', null, null, '28px']}
          color="white"
          {...subtitleBind}
        />
        <RichText
          fontFamily="commutersSansLight"
          fontSize={['14px', null, '19px', null, null, '24px']}
          {...textBind}
        />
      </Container> */}
      </ContainerSection>
    </Section>
  );
};

export default TextMediaBackground;
