import { createContext, useState } from 'react';

import { KustomResponsiveMedia } from '@/lib/kustomcms-sdk/lib/types';

interface MediasViewerContextType {
  medias: KustomResponsiveMedia[];
  index: number;
  setMedias: (medias: KustomResponsiveMedia[]) => void;
  setIndex: (index: number) => void;
}

const MediasViewerContext = createContext<MediasViewerContextType>({
  medias: [],
  index: 0,
  setMedias: () => {},
  setIndex: () => {},
});

interface MediasViewerContextProviderProps {
  children: React.ReactNode;
}

export const MediasViewerContextProvider = ({
  children,
}: MediasViewerContextProviderProps) => {
  const [medias, setMedias] = useState<KustomResponsiveMedia[]>([]);
  const [index, setIndex] = useState<number>(0);

  return (
    <MediasViewerContext.Provider
      value={{ medias, setMedias, index, setIndex }}
    >
      {children}
    </MediasViewerContext.Provider>
  );
};

export default MediasViewerContext;
