interface WrapIfProps {
  condition: boolean;
  children: React.ReactNode;
  wrapper: (children: React.ReactNode) => React.ReactNode;
}

const WrapIf = ({ condition, children, wrapper }: WrapIfProps) => {
  if (condition) {
    return <>{wrapper(children)}</>;
  }

  return <>{children}</>;
};

export default WrapIf;
